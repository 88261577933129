import { useEffect, useState } from "react";
import "../styles/components/Input.scss";

import * as EmailValidator from "email-validator";
import { isset } from "../functions/functions";

/**
 * 
 * @param props 
 * @param placeholder Is the text over our input, that describe the function of input
 * @param hasBorder Describe if the component input inside has a border or only border-bottom if not set this prop
 * @param type Describe the type of input
 * @param id The id used to input
 * @param name Name is used inside a form
 * @param className ClassName to input only
 * @param innerRef Ref to used on input
 * @param onFocus Function to called when input are focused
 * @param onInput Function to called when user add a input
 * @param onChange Function to called when user change something
 * @param onBlur Function to called when focus are losed
 * @param onClick Function to called when user made a click over component
 * @param isAValidEmail Funcion called anytime when user made a input and returns (actualValue: string, isValidEmail: boolean)
 * @returns Component and render a input
 */

let Input = (props: {
    placeholder: any,
    hasBorder?: boolean,
    type?: "text" | "password" | "number" | "email" | "date" | "textarea" | "file" | "textarea" | "select" | "search",
    id?: string,
    name?: string,
    className?: string,
    styleClass?: string,
    style?: React.CSSProperties,
    ref?: any,
    innerRef?: any,
    innerPlaceholder?: string,
    title?: string,
    required?: boolean,
    accept?: string,
    defaultValue?: any,
    disabled?: boolean,
    icon?: any,
    iconSlot?: "end" | "start",
    onFocus?: (ev: any) => any,
    onInput?: (ev: any) => any,
    onChange?: (ev: any) => any,
    onBlur?: (ev: any) => any,
    onClick?: (ev: any) => any,
    onKeyUp?: (ev: any) => any,
    isAValidEmail?: (email: string, valid: boolean) => any,
    children?: any,
}) => {
    const [visibleValue, setVisibleValue] = useState(false);
    const [inputError, setInputError] = useState(false);


    useEffect(() => {

    }, [inputError])

    return <div
        className={"input-component " + props.styleClass}
        ref={props.ref}
        style={props.style ?? {}}
    >
        <span>
            <small>{props.placeholder}{isset(props.required) ? <small className='text-danger'> *</small> : ""}</small>

            {!isset(props.title) ? (props.type === "email" && inputError ?
                <small className="text-danger"> · Correo no válido </small>
                :
                <> <small className="text-danger"></small></>)
                :
                <small className="text-danger">{props.title}</small>
            }
        </span>

        <div>

            {isset(props.icon) && isset(props.iconSlot) ?
                props.iconSlot === "start" ?
                    props.icon
                    :
                    <></>
                :
                <></>
            }

            {isset(props.type) ?
                (props.type === "textarea" ?
                    <textarea
                        disabled={props.disabled ? props.disabled : false}
                        rows={3}
                        id={props.id}
                        className={(props.className ? props.className : "").concat(props.hasBorder ? (props.hasBorder ? " border-input" : "") : "") + " text-area-component"}
                        name={props.name ? props.name : ""}
                        onFocus={props.onFocus}
                        onBlur={props.onBlur}
                        onClick={props.onClick}
                        ref={props.innerRef}
                        onInput={(ev: any) => {
                            if (props.onInput) {
                                props.onInput(ev);
                            }
                        }}
                        placeholder={props.innerPlaceholder}
                        defaultValue={props.defaultValue}
                        onChange={props.onChange}
                        onKeyUp={props.onKeyUp}
                    />
                    :
                    (props.type === "select" ?
                        <select
                            disabled={props.disabled ? props.disabled : false}
                            onChange={props.onChange}
                            id={props.id}
                            className={(props.className ? props.className : "").concat(props.hasBorder ? (props.hasBorder ? " border-input" : "") : "") + " select-component"}
                            name={props.name ? props.name : ""}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            onClick={props.onClick}
                            ref={props.innerRef}
                            defaultValue={props.defaultValue}
                        >
                            {props.children}
                        </select>
                        :
                        <input
                            disabled={props.disabled ? props.disabled : false}
                            type={props.type ? (props.type === "password" ? (visibleValue ? "text" : "password") : props.type) : props.type}
                            id={props.id}
                            accept={props.type ? (props.type === "file" ? props.accept : undefined) : undefined}
                            className={(props.className ? props.className : "").concat(props.hasBorder ? (props.hasBorder ? " border-input" : "") : "")}
                            name={props.name ? props.name : ""}
                            onFocus={props.onFocus}
                            onBlur={props.onBlur}
                            onClick={props.onClick}
                            ref={props.innerRef}
                            defaultValue={props.defaultValue}
                            placeholder={props.innerPlaceholder}
                            onInput={(ev: any) => {
                                if (props.onInput) {
                                    props.onInput(ev);
                                }

                                if (props.type === "email") {
                                    if (EmailValidator.validate(ev.target.value)) {
                                        setInputError(false);
                                        if (props.isAValidEmail) {
                                            props.isAValidEmail(ev.target.value, true);
                                        }
                                    } else {
                                        setInputError(true);
                                        if (props.isAValidEmail) {
                                            props.isAValidEmail(ev.target.value, false);
                                        }
                                    }
                                }
                            }}
                            onChange={props.onChange}
                            onKeyUp={props.onKeyUp}
                        />
                    )
                )
                :
                <input
                    disabled={props.disabled ? props.disabled : false}
                    type={props.type ? (props.type === "password" ? (visibleValue ? "text" : "password") : props.type) : props.type}
                    id={props.id}
                    className={(props.className ? props.className : "").concat(props.hasBorder ? (props.hasBorder ? " border-input" : "") : "")}
                    name={props.name ? props.name : ""}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    onClick={props.onClick}
                    ref={props.innerRef}
                    defaultValue={props.defaultValue}
                    placeholder={props.innerPlaceholder}
                    onInput={(ev: any) => {
                        if (props.onInput) {
                            props.onInput(ev);
                        }

                        if (props.type === "email") {
                            if (EmailValidator.validate(ev.target.value)) {
                                setInputError(false);
                                if (props.isAValidEmail) {
                                    props.isAValidEmail(ev.target.value, true);
                                }
                            } else {
                                setInputError(true);
                                if (props.isAValidEmail) {
                                    props.isAValidEmail(ev.target.value, false);
                                }
                            }
                        }
                    }}
                    onChange={props.onChange}
                    onKeyUp={props.onKeyUp}
                />
            }

            {props.type === "password" ?
                visibleValue ?
                    <i className="bi bi-eye-slash material-symbols-outlined pointer" onClick={() => {
                        setVisibleValue(false);
                    }} ></i>
                    :
                    <i className="bi bi-eye material-symbols-outlined pointer" onClick={() => {
                        setVisibleValue(true);
                    }} ></i>
                :
                <></>
            }

            {isset(props.icon) && isset(props.iconSlot) ?
                props.iconSlot === "end" ?
                    props.icon
                    :
                    <></>
                :
                <></>
            }

        </div>

    </div>
}
export default Input;