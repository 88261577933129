import { MutableRefObject, useState } from "react";
import { Collapse } from "reactstrap";
import { LectureLesson, VideoLesson } from "../../functions/types";
import { pdfStreamURL } from "../../functions/variables";

import "../../styles/pages/Lesson.scss";

const LessonPDF = (props: {
    actualLesson: {
        type: "video" | "lecture" | "quizz";
        lesson: LectureLesson & VideoLesson;
        isLoaded: boolean;
    },
    contentRef: MutableRefObject<HTMLDivElement>
}) => {

    const [viewPDF, setviewPDF] = useState(false);

    const downloadLink = pdfStreamURL + "module=" + props.actualLesson.lesson.module_code + "&pdf_id=" + props.actualLesson.lesson.lecture_lesson_code + "&pdf_name=" + props.actualLesson.lesson.title;

    return <div className="lesson-pdf">
        <>
            <h6>{props.actualLesson.lesson.title}</h6>
            <div className="pdf-viewer-lesson" >
                <p>{props.actualLesson.lesson.title}.pdf</p>

                <div className="" style={{display: "flex", flexFlow: "wrap row", justifyContent: "flex-end", gap: ".5rem"}}>
                    <i className="bi bi-eye" onClick={()=>{
                        setviewPDF(!viewPDF);
                    }}> {viewPDF? "Ocultar" : "Ver"}</i>
                    <a style={{display: "flex", flexFlow: "nowrap row", alignItems: "center"}} href={downloadLink}>
                        <i className="bi bi-download" style={{margin: "0"}}> </i>
                        <span>Descargar</span>
                    </a>
                </div>
            </div>

            <Collapse isOpen={viewPDF} style={{width: "100%"}}>
                <iframe src={downloadLink} frameBorder="0" style={{width: "100%", maxHeight: "60vh", height: "60vh"}}></iframe>
            </Collapse>

            {/*<p>{actualLesson.lesson.content}</p> */}
            <div ref={props.contentRef} className="content-lecture-lesson" dangerouslySetInnerHTML={{ __html: props.actualLesson.lesson.content }}></div>
        </>
    </div>
}
export default LessonPDF;