import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import { generateUrlModule, getLastLessonCached, isset } from "../functions/functions";
import { madePost } from "../functions/post";

import { Course as CourseType, Instructor, Module, Organization, Person, ResponseGeneral } from "../functions/types";
import { isUserLogged } from "../functions/user";
import { errorMessagePost, homePublic } from "../functions/variables";

import "../styles/pages/Course.scss";

const Course = () => {
    const location: {
        state: { course: CourseType },
        pathname: string
    } = useLocation() as any;

    let user: (Person & Organization) | null = isUserLogged() as (Person & Organization) | null;

    const [course, setCourse] = useState({ value: {} as CourseType, isLoaded: false });
    const [instructors, setInstructors] = useState({ values: [] as Instructor[], isLoaded: false });
    const [modules, setModules] = useState({ values: [] as Module[], isLoaded: false });
    const [isSubscribed, setIsSubscribed] = useState(false);

    const [isOpenLogin, setIsOpenLogin] = useState(false);


    const navigate = useNavigate();

    useEffect(() => {
        if (location.state === null) {
            let locationPathnameSplitted = location.pathname.split("/");
            //let organizationCodeSplitted = locationPathnameSplitted[locationPathnameSplitted.length - 2].split("-");
            //let organizationCode = organizationCodeSplitted[organizationCodeSplitted.length - 1];

            let courseCodeSplitted = locationPathnameSplitted[locationPathnameSplitted.length - 1].split("-");
            let courseCode = courseCodeSplitted[courseCodeSplitted.length - 1];

            madePost(
                {
                    key: "get-course-by-code",
                    course_key: courseCode
                },
                "courses.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    setCourse({ value: res.VALUE.COURSE, isLoaded: true });
                    setInstructors({ values: res.VALUE.INSTRUCTORS, isLoaded: true })
                } else {
                    toast.error(res.EXCEPTION_MESSAGE);
                }
            }).catch(err => {
                toast.error(errorMessagePost);
            })
        } else {
            setCourse({ value: location.state.course, isLoaded: true });
            madePost(
                {
                    key: "get-instructors-by-course",
                    instructors: JSON.parse(location.state.course.instructors.toString())
                },
                "courses.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    setCourse({ value: location.state.course, isLoaded: true });
                    setInstructors({ values: res.VALUE, isLoaded: true })
                } else {
                    toast.error(res.EXCEPTION_MESSAGE);
                }
            }).catch(err => {
                toast.error(errorMessagePost);
            })
        }
    }, [])

    useEffect(() => {
        //Obtain Modules 

        if (!modules.isLoaded && course.isLoaded) {
            madePost(
                {
                    key: "get-courses-modules",
                    modules: course.value.modules
                },
                "courses.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    setModules({ values: res.VALUE, isLoaded: true })
                } else {
                    toast.error(res.EXCEPTION_MESSAGE);
                }
            }).catch(err => {
                toast.error(errorMessagePost);
            })
        }

        if (course.isLoaded) {
            let students: any = course.value.students as any;

            try {
                students = JSON.parse(students) as any[];
            } catch (err) { };

            if (isset(user?.organization_code)) {
                //Es organizacion
                //Can visualize but does not obtain certificate
                setIsSubscribed(true);
            } else {
                try {
                    let userCode: number = user!.user_code;
                    try {
                        userCode = parseInt((userCode).toString());
                    } catch (err) { }
                    if ((students as number[]).includes(userCode)) {
                        setIsSubscribed(true)
                    } else {
                        setIsSubscribed(false);
                    }
                } catch (err) {

                }
            }
        }
    }, [course, modules])

    const subscribeCourse = () => {
        user = isUserLogged() as (Person & Organization) | null;
        if (modules.isLoaded && course.isLoaded && user) {
            if (modules.values.length > 0) {
                if (isSubscribed) {
                    let last = getLastLessonCached(course.value.course_code!);

                    let url = generateUrlModule(last, course.value, modules.values[0]);

                    if (url) {
                        navigate(url);
                    } else {
                        toast.error("Este curso todavía no tiene ninguna lección disponible")
                    }
                } else {
                    //Subscribe
                    let last = getLastLessonCached(course.value.course_code!);

                    madePost(
                        {
                            key: "subscribe-to-course",
                            course_code: course.value.course_code,
                            user_code: user?.user_code
                        },
                        "courses.php"
                    ).then(r => {
                        let res = r as ResponseGeneral;

                        if (res.RESULT) {
                            toast.success("Te suscribiste correctamente a este curso");

                            let url = generateUrlModule(last, course.value, modules.values[0]);

                            if (url) {
                                navigate(url);
                            } else {
                                toast.error("Este curso todavía no tiene ninguna lección disponible");
                            }
                        } else {
                            toast.error(res.EXCEPTION_MESSAGE)
                        }
                    }).catch(err => {
                        toast.error(errorMessagePost);
                    })

                }
            } else {
                toast.error("Este curso no tiene lecciones disponibles todavía");
            }
        } else if (user === null) {
            setIsOpenLogin(true);
            toast.error("Para visualizar el contenido curso debes iniciar sesión e incribirte");

        } else {
            toast.error("Espera a que los módulos se cargen completamente");
        }
    }

    const restoreInstructorPathname = (course_image_path: string, instructor_name: string, instructor_lastname: string) => {
        let splitted = course_image_path.split("/courses/")[0].split("/");
        return homePublic + splitted[splitted.length - 2] + "/" + splitted[splitted.length - 1] + "/instructors/" + instructor_name.concat("-" + instructor_lastname).replaceAll(" ", "-").toLowerCase() + ".jpeg";
    }

    const SupCourseContent = () => {
        let parsedModules = course.value.modules;

        if (typeof parsedModules === "string") {
            try {
                parsedModules = JSON.parse(parsedModules);
            } catch (err) { }
        }

        return <div className="sup-content-course">
            <div className="img-subscribe">
                <img src={course.value.cover_image} alt={"Curso de " + course.value.title} className="fit-cover" />

                <Button border onClick={subscribeCourse}>
                    {
                        isSubscribed ?
                            "CONTINUAR"
                            :
                            "INSCRIBIRSE"
                    }
                </Button>
            </div>

            <div className="course-content">
                <h5>{course.value.title}</h5>
                <span>{parsedModules!.length + " Módulos"}</span>
                <p>{course.value.description}</p>
            </div>
        </div>
    }

    const PublicTarget = () => {
        return <div className="public-target-course">
            <span className="title-regular">A QUIEN VA DIRIGIDO</span>
            <p>{course.value.target_audience}</p>
        </div>
    }

    const Instructors = () => {
        return <div className="instructors-course">
            <>
                <span className="title-regular">INSTRUCTOR/A</span>

                {instructors.values.map((ins: Instructor, index: number) => {
                    return <div className="instructor-card-course" key={"instructor-" + index}>
                        <img src={restoreInstructorPathname(course.value.cover_image, ins.name, ins.last_name)} alt={"Instructor " + ins.name + " " + ins.last_name} />
                        <div>
                            <span>{ins.name} {" "} {ins.last_name}</span>
                            <p>{ins.profession}</p>
                        </div>
                    </div>
                })}
            </>

        </div>
    }

    const OrganizationsAuthor = () => {
        let organization = {} as Organization;

        if (typeof course.value.organization === "string") {
            try {
                organization = JSON.parse(course.value.organization);
            } catch (err) {
                organization = course.value.organization as Organization;
            }
        } else {
            organization = course.value.organization as Organization;
        }

        return <div className="organization-author-course">
            <span className="title-regular">OFRECIDO POR</span>

            <div>
                <img src={organization.logo} alt={organization.name + " Logotipo"} />
                <div>
                    <span>{organization.name}</span>
                    <p>{organization.principal_activity}</p>
                </div>
            </div>
        </div>
    }

    const ProgramModules = () => {
        return <div className="modules-program-course">
            <span className="title-regular">PROGRAMA</span>

            {
                modules.isLoaded ?
                    modules.values.length > 0 ?
                        modules.values.map((module: Module, index: number) => {

                            let t = module.total_time? module.total_time.split("-") : ["00", "1", "00"];

                            let time = (t[0] == "00" || t[0] == "0" ? "" : t[0] + " horas, ") + (t[1] == "00" || t[1] == "0" ? "" : t[1] + " minutos")

                            let videosLessons = module.lesson_videos;
                            let lectureLessons = module.lesson_lectures;

                            if (typeof videosLessons == "string") {
                                try {
                                    videosLessons = JSON.parse(videosLessons);
                                } catch (err) { }
                            }

                            if (typeof lectureLessons == "string") {
                                try {
                                    lectureLessons = JSON.parse(lectureLessons);
                                } catch (err) { }
                            }

                            return <div className="module-section pt-1" key={"module-" + index}>
                                <div>
                                    <span className="title-regular">{"MÓDULO " + (index + 1) + " : " + module.module_title}</span>
                                    <div>
                                        <i className="bi bi-clock"></i>
                                        <p>{time}</p>
                                    </div>
                                </div>

                                <p>{module.module_description}</p>

                                <div className="lesson-describers">
                                    <i className="bi bi-play-circle"></i>
                                    {videosLessons!.length + " Videos"}
                                </div>
                                <div className="lesson-describers">
                                    <i className="bi bi-book"></i>
                                    {lectureLessons!.length + " Lecturas"}
                                </div>
                            </div>
                        })
                        :
                        <div className="module-section" key={"module-noexists"}>
                            <div>
                                <span className="title-regular">Este curso no tiene módulos todavía</span>

                            </div>
                        </div>
                    :
                    <Loader />
            }
        </div>
    }

    return <div className="course-page">
        <Header isOpen={isOpenLogin} onClose={() => setIsOpenLogin(false)} />
        {
            course.isLoaded ?
                <section>
                    <Helmet>
                        <title>Curso {course.value.title} | Fundación Creamos Juntos</title>
                        <meta charSet="utf-8" />
                        <link rel="canonical" href={window.location.href} />
                        <meta name="description" content={"Nuestros cursos en Fundación Creamos Juntos Bolivia | " + course.value.title}></meta>
                    </Helmet>
                    <SupCourseContent />
                    <PublicTarget />
                    {
                        instructors.isLoaded ?

                            <>
                                <div className="divided-course-info">
                                    <Instructors />
                                    <OrganizationsAuthor />
                                </div>
                                <ProgramModules />
                            </>
                            :
                            <Loader className="loader-tall" text="Cargando información del curso" />
                    }


                    <Button className="mt-3" border style={{ display: "block", fontWeight: 600, marginLeft: "auto", borderRadius: "7px" }}
                        onClick={subscribeCourse}
                    >
                        {
                            isSubscribed ?
                                "CONTINUAR"
                                :
                                "INSCRIBIRSE"
                        }
                    </Button>
                </section>
                :
                <Loader className="loader-tall" text="Cargando curso" />
        }

        <Footer />
    </div>


}

export default Course;