import "../styles/components/CustomSwiper.scss";

import "swiper/scss";
import { CSSProperties, useEffect, useLayoutEffect, useState } from "react";
import Swiper from "swiper";
import { isset } from "../functions/functions";

const CustomSwiper = (props: {
    id: string,
    title: string,
    elements: any[],
    style?: CSSProperties,
    className?: string,
    seeMoreElement?: JSX.Element,
    onSeeMore?: () => any
}) => {

    const [swiper, setSwiper] = useState({} as Swiper);

    useLayoutEffect(() => {
        setSwiper(new Swiper("#swiper-".concat(props.id), {
            rewind: true,
            navigation: {
                nextEl: "swiper-next-button-".concat(props.id),
                prevEl: "swiper-prev-button-".concat(props.id)
            },
            slidesPerView: 1,
            initialSlide: 0,
            spaceBetween: 10,
            breakpoints: {
                //Taablet
                768: {
                    slidesPerView: 3
                },
                400: {
                    slidesPerView: 2
                }
            }
        }))
    }, [])

    useEffect(()=>{
        setSwiper(new Swiper("#swiper-".concat(props.id), {
            rewind: true,
            navigation: {
                nextEl: "swiper-next-button-".concat(props.id),
                prevEl: "swiper-prev-button-".concat(props.id)
            },
            slidesPerView: 1,
            initialSlide: 0,
            spaceBetween: 10,
            breakpoints: {
                //Taablet
                768: {
                    slidesPerView: 3
                },
                400: {
                    slidesPerView: 2
                }
            }
        }))
    }, [props.elements])

    return <div className={"custom-swiper-component " + props.className} style={props.style}>
        <div className="custom-swiper-controls">
            <h6 className="title-subsection">{props.title}</h6>
            <div className="controls-swiper">

                <i className="bi bi-chevron-left"
                    id={"swiper-prev-button-".concat(props.id)}
                    onClick={() => {
                        swiper.slidePrev();
                    }}></i>
                <i className="bi bi-chevron-right"
                    id={"swiper-next-button-".concat(props.id)}
                    onClick={() => {
                        swiper.slideNext();
                    }}></i>
            </div>
        </div>

        <div
            id={"swiper-".concat(props.id)}
            style={{ maxWidth: "100vw" }}
            className="swiper-component"
        >
            <div className="swiper-wrapper">
                {props.elements.map((element, index) => {
                    return <div className="swiper-slide" key={"swiper-slide-".concat(props.id).concat("-" + index)}>
                        {element}
                    </div>
                })}
                {
                    isset(props.seeMoreElement) ?
                        <div className="swiper-slide see-more-card-swiper" key={"swiper-slide-seemore"} onClick={props.onSeeMore}>
                            {props.seeMoreElement}
                        </div>
                        :
                        <></>
                }

            </div>
        </div>
    </div>
}

export default CustomSwiper;