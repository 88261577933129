import CopyToClipboard from "react-copy-to-clipboard";
import Input from "./Input"
import { useEffect, useState } from "react";
import { Course, Person } from "../functions/types";
import Button from "./Button";

export const GenerateURL = ({ courses, person }: { courses: Course[], person: Person }) => {
    const [copied, setCopied] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState<Course | undefined>(undefined);
    const [linkToCopy, setLinkToCopy] = useState(`https://creamosjuntos.org/api/quicklogin.php?u=${person.email}&p=${person.password}&oid=75&cid=19`);
    const [cell, setCell] = useState<null | string>('');

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000); // Reset copied state after 2 seconds
    };

    useEffect(() => {
        setSelectedCourse(courses[0])
    }, [courses])

    useEffect(() => {
        //console.log(selectedCourse)
        setLinkToCopy(`https://creamosjuntos.org/api/quicklogin.php?u=${person.email}&p=${person.password}&oid=${selectedCourse?.organization_code}&cid=${selectedCourse?.course_code}`)
    }, [selectedCourse])

    useEffect(() => {
        if (person.cellphone) {
            setCell('591'+person.cellphone);
        }
    }, [person])

    return <>
        <small>Generar enlace de inicio de sesión rápido para el curso:</small>
        <Input style={{ marginBottom: '1rem' }} type="select" placeholder={``} onChange={(ev: any) => {
            setSelectedCourse(courses.find((c: Course) => { return c.course_code == ev.target.value }))
        }}>
            {
                courses.map((c: Course) => {
                    return <option value={c.course_code} key={`course-listed-${c.course_code}`}>{c.title}</option>
                })
            }
        </Input>

        <div style={{ maxWidth: '100%', display: 'flex', flexFlow: 'nowrap column', gap: '.5rem' }}>
            <small>Copiar:</small>
            <CopyToClipboard text={linkToCopy} onCopy={handleCopy}>
                <Button border style={{ overflow: 'auto' }}>{copied ? 'Copiado' : <small style={{ overflow: 'auto' }}>{linkToCopy}</small>}</Button>
            </CopyToClipboard>
            <small style={{ width: '100%', textAlign: 'center', display: 'block' }}>ó</small>
            <div style={{ display: 'flex', flexFlow: 'wrap row', alignItems: 'center', justifyContent: 'center' }}>
                <a target="_blank" href={`https://wa.me/?phone=${cell ? cell.replace("/^\+591/", "").replace("/^591/", "") : null}&text=Puedes acceder más fácilmente a tu cuenta en *Creamos Juntos* con este enlace: ${encodeURIComponent(linkToCopy)}`} style={{ color: '#128c7e', margin: '0' }}><small>Enviar enlace por WhatsApp</small></a>
                <small style={{margin: '0 .5rem'}}>al</small>
                <div style={{display: 'flex', flexFlow: 'nowrap row', alignItems: 'center'}}>
                +<Input type="number" style={{ marginLeft: '-0.9ch' }} defaultValue={`${cell}`} placeholder={undefined} onChange={(ev: any) => {
                    console.log(cell, person.cellphone)
                    setCell('591'+ev.target.value)
                }}></Input>
                </div>
            </div>
        </div>
    </>
}