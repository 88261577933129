import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { madePost } from "../../functions/post";
import { routes } from "../../functions/routes";
import { Organization, Person, ResponseGeneral } from "../../functions/types";
import { isUserLogged } from "../../functions/user";
import { clear } from "../../functions/useStorage";

const AccountSecurityProfile = () => {
    const user: Person | Organization | null = isUserLogged();

    const prevPassRef = useRef({} as HTMLInputElement);
    const newPassRef = useRef({} as HTMLInputElement);
    const newRepPassRef = useRef({} as HTMLInputElement);

    const [isSamePassword, setIsSamePassword] = useState({ value: false, message: "" });

    const navigate = useNavigate();

    const analizeForm = () => {

        if (newPassRef.current.value.length > 5) {
            if (newPassRef.current.value === newRepPassRef.current.value) {
                setIsSamePassword({ value: true, message: "" });
            } else {
                setIsSamePassword({ value: false, message: "Las contraseñas no coinciden" });
            }

        } else {
            setIsSamePassword({ value: false, message: "Tu contraseña debe tener más de 5 caracteres" });
        }
    }

    const sendForm = () => {
        madePost(
            {
                key: "change-password",
                prev_password: prevPassRef.current.value,
                new_password: newPassRef.current.value,
                email: user?.email
            },
            "account.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast.success("Tu contraseña se ha actualizado correctamente");

                prevPassRef.current.value = "";
                newPassRef.current.value = "";
                newRepPassRef.current.value = "";
            } else {
                toast.error(res.EXCEPTION_MESSAGE);
            }
        }).catch(err => {
            toast.error("Ha ocurrido un error, intentalo nuevamente más tarde");
        })
    }

    useEffect(() => {
        
    }, [isSamePassword])

    return <section className="mini-spacing">
        <h5 className="title-section left mb-1">CAMBIA TU CONTRASEÑA</h5>
        <p className="description-section mb-4">Modifica tu contraseña constantemente para incrementar tu seguridad</p>

        <Input
            placeholder="Anterior contraseña"
            type="password"
            innerRef={prevPassRef}
            onInput={analizeForm}
            styleClass="mb-2"
            required
        />
        <Input
            placeholder="Nueva contraseña"
            type="password"
            innerRef={newPassRef}
            onInput={analizeForm}
            styleClass="mb-2"
            required
        />
        <Input
            placeholder="Repetir contraseña"
            type="password"
            title={isSamePassword.value ? "" : (" · " + isSamePassword.message)}
            innerRef={newRepPassRef}
            onInput={analizeForm}
            styleClass="mb-3"
            required
        />

        <Button
            solid
            disabled={!isSamePassword.value}
            style={{ marginLeft: "auto", display: "block" }}
            radiusMin
            onClick={sendForm}
            className="">
            Actualizar contraseña
        </Button>

        <div className="d-flex flex-md-row flex-column align-items-md-center align-items-start justify-content-between mt-4">
            <div className="mb-3 mb-md-0">
                <h5 className="title-section left mb-1">CERRAR SESIÓN</h5>
                <p className="description-section m-0">Puedes cerrar tu sesión actual</p>
            </div>

            <Button border style={{ display: "block", marginLeft: "auto" }} onClick={() => {
                clear();
                navigate(routes.home);
            }}>
                Cerrar sesión
            </Button>
        </div>

    </section>
}

export default AccountSecurityProfile;