/* eslint-disable array-callback-return */
import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { madePost } from "../../functions/post";
import { Certificate, Course, Organization, Person, Quizz, QuizzUser, ResponseGeneral } from "../../functions/types";
import { getLoginToken } from "../../functions/user";
import { mobile, onCatch } from "../../functions/variables";

import Plotly from "plotly.js-basic-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";

import "../../styles/pages/Statistics.scss";
import Input from "../../components/Input";
import Loader from "../../components/Loader";
import { cloneObject, excelDownloadUsersActivity } from "../../functions/functions";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Collapse, Modal } from "reactstrap";
import Button from "../../components/Button";

import * as EmailValidator from "email-validator";
import { CertificateIcon } from "../../components/Icons";
import { GenerateURL } from "../../components/Comps";

const Plot = createPlotlyComponent(Plotly);

type StatisticsType = {
    lesson_code: number,
    lesson_type: "video" | "lecture",
    organization_code: number,
    course_code: number,
    module_code: number,
    title: string,
    description: string,
    lesson_time: string,
    module_title: string,
    course_title: string,
    statistics: string | Object //{"yyyy-mm":number[]}
}

const Statistics = (props: {
    user: Organization
}) => {

    const [courses, setCourses] = useState({ values: [] as Course[], isLoaded: false });

    const [lessonsCourseStatistics, setLessonsCourseStatistics] = useState({ values: [] as StatisticsType[], isLoaded: false });

    const [selectedCourse, setSelectedCourse] = useState({ course_code: 0, isSelected: false });
    const [courseModules, setCourseModules] = useState([] as
        {
            module_code: number,
            module_name: string,
            lessons: StatisticsType[]
        }[]
    ); // [{..., lessons: [{lesson: statistics: {date: number  []  }}]}]
    const [courseModulesOnlySize, setCourseModulesOnlySize] = useState([] as
        {
            module_code: number,
            module_name: string,
            lessons: StatisticsType[]
        }[]
    ); // [{..., lessons: [{lesson: statistics: {date: number}}]}]

    //
    const [usersInfo, setUsersInfo] = useState({ values: [] as Person[], isLoaded: false });
    const [visibleUsersInfo, setVisibleUsersInfo] = useState({ values: [] as Person[], word: "" })

    const searchUserRef = useRef({} as HTMLInputElement)

    useEffect(() => {
        if (!courses.isLoaded) {
            madePost(
                {
                    key: "get-courses",
                    email: props.user.email,
                    token: getLoginToken()
                },
                "manage.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    let coursesToUser = [] as Course[];
                    res.VALUE.map((course: Course) => {
                        if (!coursesToUser.find((c: Course) => c.course_code == course.course_code)) {
                            coursesToUser.push(course)
                        }
                    })
                    setCourses({ values: coursesToUser, isLoaded: true })
                } else {
                    toast.error(res.EXCEPTION_MESSAGE)
                }

            }).catch(onCatch)
        }
    }, [props])

    useEffect(() => {
        if (courses.isLoaded) {
            setSelectedCourse({ course_code: courses.values[0] ? courses.values[0].course_code! : 0, isSelected: true })
        }
    }, [courses])

    useEffect(() => {
        getLessonsStatistics(selectedCourse.course_code)
    }, [selectedCourse])

    useEffect(() => {
        //console.log(lessonsCourseStatistics)
    }, [courseModulesOnlySize, courseModules, lessonsCourseStatistics])

    useEffect(() => {
        if (lessonsCourseStatistics.isLoaded) {
            getInfoUsers();
        }
    }, [lessonsCourseStatistics])

    const getLessonsStatistics = (course_code: number) => {

        setLessonsCourseStatistics({ values: [], isLoaded: false });

        madePost(
            {
                key: "get-statistics-lessons-course",
                organization_code: props.user.organization_code ?? props.user.admin_of,
                token: getLoginToken(),
                course_code: course_code
            },
            "courses.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                setLessonsCourseStatistics({ values: cloneObject(res.VALUE), isLoaded: true });

                let availableModules: { module_code: number, module_name: string }[] = [];

                cloneObject(res.VALUE).map((statistics: StatisticsType) => {
                    let find = availableModules.find((st: { module_code: number, module_name: string }) => { return (st.module_code == statistics.module_code) })
                    if (!find) {
                        availableModules.push({ module_code: statistics.module_code, module_name: statistics.module_title });
                    }
                })

                let separatedStatistics: {
                    module_code: number,
                    module_name: string,
                    lessons: StatisticsType[]
                }[] = [];

                availableModules.map((st: { module_code: number, module_name: string }) => {
                    let lessons = [] as StatisticsType[];

                    cloneObject(res.VALUE).map((statistics: StatisticsType) => {
                        if (statistics.module_code === st.module_code) {
                            lessons.push(statistics)
                        }
                    })

                    separatedStatistics.push({
                        module_code: st.module_code,
                        module_name: st.module_name,
                        lessons: lessons
                    })
                })

                setCourseModules(separatedStatistics);
                // [{module_name, code, lessons: [of module lessons]}] -> of module lessons "date": number

                let withoutUsers: {
                    module_code: number,
                    module_name: string,
                    lessons: StatisticsType[]
                }[] = []; //only size

                separatedStatistics.map((mod: { module_code: number, module_name: string, lessons: StatisticsType[] }) => {
                    let newLessons: StatisticsType[] = [];
                    mod.lessons.map((st: StatisticsType) => {
                        let copy = st;

                        try {
                            let parsed = JSON.parse(copy.statistics as string);  // {yyy-mm:number[]}

                            let dates = Object.keys(parsed); //Dates

                            dates.map((key: string) => {
                                parsed[key] = parsed[key].length //[1,2,2]
                            })

                            copy.statistics = parsed;

                            newLessons.push(copy);
                        } catch (e) { }
                    })

                    withoutUsers.push({ module_code: mod.module_code, module_name: mod.module_name, lessons: newLessons });
                })

                setCourseModulesOnlySize(withoutUsers)
                //

            } else {
                toast.error(res.EXCEPTION_MESSAGE)
            }
        }).catch(onCatch)
    }

    const getInfoUsers = () => {
        let allIds: number[] = [];

        lessonsCourseStatistics.values.map((el: StatisticsType) => {
            let stats: Object = {};

            try {
                stats = JSON.parse(el.statistics as string);
                Object.values(stats).map((id: number[]) => {
                    id.map((el: number) => {
                        if (!allIds.includes(el)) {
                            allIds.push(el);
                        }
                    })

                })
            } catch (e) {
                console.log(e, " : Exception statistics 219~")
            }
        })

        if (allIds.length > 0) {
            setUsersInfo({ values: [], isLoaded: false });
            madePost(
                {
                    key: "get-statistics-users-info",
                    organization_code: props.user.organization_code ?? props.user.admin_of,
                    token: getLoginToken(),
                    users: JSON.stringify(allIds)
                },
                "courses.php"
            ).then(r => {
                let res = r as ResponseGeneral;
                if (res.RESULT) {
                    //console.debug(res.VALUE, lessonsCourseStatistics)
                    let users: Person[] = [];

                    //Prepare slesson status
                    res.VALUE.map((pp: Person) => {
                        try {
                            //Add lessons to each person
                            let find: StatisticsType[] = lessonsCourseStatistics.values.filter((es: StatisticsType) => { return Object.values(JSON.parse(es.statistics as unknown as string)).find((e: any) => e.includes(parseInt(pp.user_code.toString()))) });

                            pp.lessons = [];
                            pp.lessonsPure = [];

                            find.map((es: StatisticsType) => {
                                pp.lessons!.push({ type: es.lesson_type, code: es.lesson_code, lesson_title: es.title });
                                pp.lessonsPure!.push(es);
                            })

                            users.push(pp);
                        } catch (err) {
                            console.log(err, " : Error on statistics 252")
                        }
                    })

                    //Prepare quizzes

                    setUsersInfo({ values: users, isLoaded: true });
                    setVisibleUsersInfo({ values: users, word: "" })
                } else {
                    toast.error(res.EXCEPTION_MESSAGE)
                }
            }).catch(onCatch)
        }
    }

    const generateInfoUsers = (): any => {
        if (usersInfo.isLoaded) {
            let data: any = [];

            let age15 = { q: 0, mSexQ: 0, fSexQ: 0 };//edad 0-15
            let age25 = { q: 0, mSexQ: 0, fSexQ: 0 };//eduad 15-25
            let age35 = { q: 0, mSexQ: 0, fSexQ: 0 }; //edad 25-35
            let age50 = { q: 0, mSexQ: 0, fSexQ: 0 }; //edadd 35-50
            let age100 = { q: 0, mSexQ: 0, fSexQ: 0 }; //edad 50-

            usersInfo.values.map((person: Person) => {
                let age = (new Date).getUTCFullYear() - parseInt((person.birthday as unknown as string).split("-")[0]);

                if (age < 16) {
                    age15.q = age15.q + 1;
                    if (person.sex === "M") {
                        age15.mSexQ = age15.mSexQ + 1;
                    } else {
                        age15.fSexQ = age15.fSexQ + 1;
                    }
                } else if (age > 15 && age < 26) {
                    age25.q = age25.q + 1;
                    if (person.sex === "M") {
                        age25.mSexQ = age25.mSexQ + 1;
                    } else {
                        age25.fSexQ = age25.fSexQ + 1;
                    }
                } else if (age > 25 && age < 36) {
                    age35.q = age35.q + 1;
                    if (person.sex === "M") {
                        age35.mSexQ = age35.mSexQ + 1;
                    } else {
                        age35.fSexQ = age35.fSexQ + 1;
                    }
                } else if (age > 35 && age < 51) {
                    age50.q = age50.q + 1;
                    if (person.sex === "M") {
                        age50.mSexQ = age50.mSexQ + 1;
                    } else {
                        age50.fSexQ = age50.fSexQ + 1;
                    }
                } else {
                    age100.q = age100.q + 1;
                    if (person.sex === "M") {
                        age100.mSexQ = age100.mSexQ + 1;
                    } else {
                        age100.fSexQ = age100.fSexQ + 1;
                    }
                }
            })

            data.push({
                x: ["<15", "15-25", "25-35", "35-50", ">50"],
                y: [age15.mSexQ,
                age25.mSexQ,
                age35.mSexQ,
                age50.mSexQ,
                age100.mSexQ,],
                name: "Hombres",
                type: "bar"
            });

            data.push({
                x: ["<15", "15-25", "25-35", "35-50", ">50"],
                y: [age15.fSexQ,
                age25.fSexQ,
                age35.fSexQ,
                age50.fSexQ,
                age100.fSexQ,],
                name: "Mujeres",
                type: "bar"
            });

            return data;
        } else {
            return [];
        }
    }

    const generateLessonViews = (only?: "quantity" | "data"): any => {
        let data: { q: number, name: string }[] = [];

        courseModulesOnlySize.map((st: { module_code: number, module_name: string, lessons: StatisticsType[] }) => {
            st.lessons.map((stats: StatisticsType) => {
                let lesson = {
                    q: Object.values(stats.statistics).reduce((accumulator, value) => {
                        return accumulator + value;
                    }, 0), name: stats.title
                };
                data.push(lesson)
            })
        })

        data.sort((a: { q: number, name: string }, b: { q: number, name: string }) => {
            if (a.q > b.q) {
                return 1;
            } else if (a.q < b.q) {
                return -1;
            } else {
                return 0;
            }
        })

        let x: string[] = [];
        let y: number[] = [];

        data.map((el: { q: number, name: string }) => {
            x.push(el.name);
            y.push(el.q)
        })

        if (only == "quantity") {
            try {
                return y.reduce((a, b) => {
                    return a + b;
                })
            } catch (e) {
                return [];
            }
        } else if (only === "data") {
            return data;
        } else {
            return [{
                x: x,
                y: y,
                name: 'Lecciones',
                marker: { color: '#086788' },
                type: 'bar'
            }]
        }
    }

    const generateDateModules = (): any => {
        let data: any = [];

        courseModulesOnlySize.map((st: { module_code: number, module_name: string, lessons: StatisticsType[] }) => {

            let Dates: any = {}

            st.lessons.map((stats: StatisticsType) => {
                Object.keys(stats.statistics).map((key: string) => {
                    if (!(Object.keys(Dates)).includes(key)) {
                        Dates[key] = 0;
                    }
                })
            })

            Object.keys(Dates).map((keyI: string) => {
                let sum = 0;
                st.lessons.map((stats: StatisticsType) => {
                    Object.keys(stats.statistics).map((key: string) => {
                        if (key === keyI) {
                            sum += ((stats.statistics as any)[keyI]);
                        }
                    })
                })

                Dates[keyI] = sum;
            })

            const ordered = Object.keys(Dates).sort((a: string, b: string) => {
                let aKey = a.split("-"); // [22, 09]
                let bKey = b.split("-"); // [21, 02]

                if (parseInt(aKey[0]) < parseInt(bKey[0])) {
                    return -1;
                } else if (parseInt(aKey[0]) > parseInt(bKey[0])) {
                    return 1;
                } else if (parseInt(aKey[0]) === parseInt(bKey[0])) {
                    if (parseInt(aKey[1]) < parseInt(bKey[1])) {
                        return -1;
                    } else if (parseInt(aKey[1]) > parseInt(bKey[1])) {
                        return 1;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            }).reduce(
                (obj: any, key) => {
                    obj[key] = Dates[key];
                    return obj;
                },
                {}
            );
            data.push(
                {
                    x: Object.keys(ordered),
                    y: Object.values(ordered),
                    mode: 'lines+markers',
                    name: st.module_name,
                    line: { shape: 'hvh' },
                    type: 'scatter',
                }
            )
        })

        //setModulesGraphData(data);

        return data;
    }

    const generateListCountries = (): any[] => { //[ [location, quantity] ] desc
        let top: any = {};

        if (usersInfo.isLoaded) {
            usersInfo.values.map((person: Person) => {
                if (Object.keys(top).includes(person.location)) {
                    try {
                        top[(person.location)] = top[(person.location)] + 1;
                    } catch (er) {
                        top[(person.location)] = 1;
                    }
                } else {
                    top[(person.location)] = 1;
                }
            })
        }

        let sortable = [];
        for (var location in top) {
            sortable.push([location, top[location]]);
        }

        sortable.sort(function (a, b) {
            return a[1] - b[1];
        });

        return sortable.reverse();
    }

    const UserStats = (p: { person: Person, index: number }) => {
        const [isShow, setIsShow] = useState(false);
        const [quizzes, setQuizzes] = useState({ values: [] as QuizzUser[], isLoaded: false, owner: 0 });
        const [certificates, setCertificates] = useState({ values: [] as Certificate[], isLoaded: false });
        const [isM1Open, setIsM1Open] = useState(false);
        const [selectedQuizz, setSelectedQuizz] = useState(0);

        const [comp, setComp] = useState([<></>] as any);
        useEffect(() => {
            if (quizzes.isLoaded) {
                let quizz = quizzes.values.find((e: QuizzUser) => { return e.quizz_id == selectedQuizz });

                if (quizz) {
                    let literallyResponses: { user: string[][], corrects: string[][] } = JSON.parse(quizz.responses_literal);

                    let el: any = [];
                    literallyResponses.user.map((e: string[], index: number) => {
                        let fin = literallyResponses.corrects[index];

                        el.push(<div>
                            <span><b>{index + 1}{") "}</b> {fin[0]}</span>
                            <small>Respuesta: <b>{e[1]}</b>
                                {e[1] == fin[1] ?
                                    <i color="green" className="bi bi-check-lg"></i>
                                    :
                                    <>
                                        <i color="red" className="bi bi-x-lg"></i>
                                    </>
                                }
                            </small>

                            {e[1] != fin[1] ? <small>Respuesta correcta: <b>{fin[1]}</b></small> : <></>}
                        </div>)

                    })

                    setComp(el)
                }

            }
        }, [quizzes, selectedQuizz, isM1Open])

        useEffect(() => {
            if (isShow && !quizzes.isLoaded && quizzes.owner !== p.person.user_code) {
                madePost(
                    {
                        key: "get-quizzes-from-user",
                        user_code: p.person.user_code,
                        course_code: selectedCourse.course_code
                    },
                    "courses.php"
                ).then(r => {
                    let res = r as ResponseGeneral;
                    if (res.RESULT) {
                        setQuizzes({ values: res.VALUE, isLoaded: true, owner: p.person.user_code })
                    } else {
                        toast.error("Ha ocurrido un error al obtener los cuestionarios, porfavor intenta nuevamente más tarde")
                    }
                }).catch(onCatch)
            }

            if (isShow && !certificates.isLoaded) {
                madePost(
                    {
                        key: "get-certificates-from-person",
                        ucode: p.person.user_code,
                        ccode: selectedCourse.course_code
                    },
                    "certificatemanage.php"
                ).then(r => {
                    let res = r as ResponseGeneral;
                    if (res.RESULT) {
                        setCertificates({ values: res.VALUE, isLoaded: true });
                    } else {
                        toast.error("Ha ocurrido un error al obtener los certificados, porfavor intenta nuevamente más tarde")
                    }
                }).catch(onCatch)
            }
        }, [p, isShow])

        return <>
            <div className="person-inscripted-to-course" color={(p.index % 2 !== 0 ? "" : "bg")}>
                <span>{p.person.name + " " + p.person.last_name}</span>
                {
                    window.screen.availWidth > 500 ?
                        <span>{p.person.email}</span>
                        :
                        <></>
                }

                <i className={"bi " + (isShow ? "bi-chevron-up" : "bi-chevron-down")} onClick={() => {
                    setIsShow(!isShow);
                }}></i>
            </div>
            <Collapse isOpen={isShow} className="collapse-user-lessons">
                <Tabs>
                    <TabList style={{ display: "block" }}>
                        <Tab>Lecciones completadas</Tab>
                        <Tab>Lecciones faltantes</Tab>
                        <Tab>Cuestionarios completados</Tab>
                        <Tab><CertificateIcon width="20px" height="20px" /></Tab>
                    </TabList>
                    <TabPanel>
                        {
                            lessonsCourseStatistics.isLoaded ?
                                p.person.lessons!.length > 0 ?
                                    p.person.lessons!.map((l: { code: number, type: any, lesson_title: string }) => {
                                        return <p>
                                            <small className="w-auto">
                                                <i className={"w-auto bi bi-" + (l.type == "video" ? "play" : "book")} style={{ marginRight: ".5rem" }} color="green"></i>
                                                {l.lesson_title}
                                            </small>
                                            <small color="green">Visto <i className="bi bi-check"></i></small></p>
                                    })
                                    :
                                    <small>Sin lecciones completadas todavía</small>
                                :
                                <Loader />
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            lessonsCourseStatistics.isLoaded ?
                                <>
                                    {
                                        (lessonsCourseStatistics.values.filter((le: StatisticsType) => {
                                            return !p.person.lessons!.find((l: { code: number, type: any }) => { return (l.code == le.lesson_code && l.type == le.lesson_type) })
                                        })).map((l: StatisticsType) => {
                                            return <p>
                                                <small className="w-auto">
                                                    <i className={"w-auto bi bi-" + (l.lesson_type == "video" ? "play" : "book")} style={{ marginRight: ".5rem" }} color="red"></i>
                                                    {l.title}
                                                </small>
                                                <small color="red">No visto <i className="bi bi-x"></i></small></p>
                                        })
                                    }
                                    {
                                        (lessonsCourseStatistics.values.filter((le: StatisticsType) => {
                                            return !p.person.lessons!.find((l: { code: number, type: any }) => { return (l.code == le.lesson_code && l.type == le.lesson_type) })
                                        })).length > 0 ? "" : <small>Todas las lecciones completadas</small>
                                    }
                                </>
                                :
                                <Loader />
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            quizzes.isLoaded ?
                                <>
                                    {quizzes.values.length > 0 ?
                                        (quizzes.values.map((l: QuizzUser) => {
                                            return <p>
                                                <small className="w-auto">
                                                    <i className={"w-auto bi bi-patch-question"} style={{ marginRight: ".5rem" }} color="blue"></i>
                                                    {l.title}
                                                </small>
                                                <div>
                                                    <small color="blue" style={{ marginRight: ".5rem" }}>{l.corrects}/{parseInt(l.corrects.toString()) + parseInt(l.incorrects.toString())}<i className="bi bi-check"></i></small>
                                                    <small style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => {
                                                        setSelectedQuizz(l.quizz_id);
                                                        setIsM1Open(true);
                                                    }}>Ver respuestas</small>
                                                </div>
                                            </p>
                                        }))
                                        :
                                        <small>No se tienen cuestionarios llenados</small>
                                    }
                                </>
                                :
                                <Loader />
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            certificates.isLoaded ?
                                certificates.values.length > 0 ?
                                    certificates.values.map((c: Certificate) => {
                                        return <p>
                                            <small className="w-auto">
                                                <CertificateIcon width="12px" height="12px" />
                                                <a style={{ marginLeft: ".25rem" }} href={`/api/certificate.php?id=${c.id}`} target="_blank">Ver certificado <b>{c.id}</b></a>
                                            </small>
                                            <small color="" style={{ display: "none" }}>Editar</small></p>
                                    })
                                    :
                                    <div className="w-100 " style={{ padding: "1rem 0", display: "flex", flexFlow: "nowrap column", }}>

                                        <label htmlFor="">El usuario todavía no tiene ningún certificado de este curso</label>

                                        <Button style={{ marginLeft: "auto", marginTop: "1rem" }} border onClick={() => {
                                            toast.loading('Se está creando el certificado');
                                            madePost(
                                                {
                                                    key: "create-certificate",
                                                    ucode: p.person.user_code,
                                                    name: p.person.name + " " + p.person.last_name,
                                                    ccode: selectedCourse.course_code,
                                                    email: p.person.email
                                                },
                                                "certificatemanage.php"
                                            ).then(r => {
                                                toast.dismiss()

                                                let res = r as ResponseGeneral;
                                                if (res.RESULT) {
                                                    setCertificates({
                                                        values: [...certificates.values, {
                                                            id: res.VALUE,
                                                            name: p.person.name,
                                                            ucode: p.person.user_code,
                                                            ccode: selectedCourse.course_code,
                                                            course: '',
                                                            email: p.person.email
                                                        }],
                                                        isLoaded: true
                                                    })

                                                    toast.success('El certificado se ha creado correctamente');
                                                } else {
                                                    toast.error("Ha ocurrido un error: " + res.EXCEPTION_MESSAGE)
                                                }
                                            }).catch(onCatch)
                                        }}>
                                            Emitir certificado
                                        </Button>
                                    </div>
                                :
                                <Loader />
                        }
                    </TabPanel>
                </Tabs>

                <Modal isOpen={isM1Open} centered className="modal-quizz-review">
                    <div className="results-quizz">
                        {
                            comp.map((el: any) => {
                                return el;
                            })
                        }
                    </div>
                    <Button border onClick={() => {
                        setIsM1Open(false);
                    }}>Cerrar</Button>
                </Modal>
            </Collapse>
        </>
    }

    const UsersActivity = () => {
        return courses.isLoaded ?
            <>
                <div className="form my-0" >
                    {
                        <div className="control">
                            <Input placeholder="Curso" type="select"
                                defaultValue={selectedCourse.course_code === 0 ? undefined : selectedCourse.course_code}
                                onChange={(ev: any) => {
                                    setSelectedCourse({ course_code: ev.target.value, isSelected: true });
                                }}>
                                {
                                    courses.values.map((course: Course, index: number) => {
                                        return <option key={"course-" + index} value={course.course_code}>{course.title}</option>
                                    })
                                }
                            </Input>
                        </div>
                    }

                    <hr className="w-100 mt-2 mb-3" />

                    <div className="form-header flex-row align-items-center justify-content-between flex-wrap">
                        <h5>Actividad por cada usuario</h5>
                        <Button solid onClick={() => {
                            excelDownloadUsersActivity(usersInfo.values, courses.values, selectedCourse.course_code);
                        }}>Descargar Excel</Button>
                    </div>
                    <Input
                        placeholder={"Buscar"}
                        innerPlaceholder="Buscar por nombre o correo"
                        type="search"
                        innerRef={searchUserRef}
                        defaultValue={visibleUsersInfo.word}
                        onInput={(ev: any) => {
                            if (ev.target.value.length > 0) {
                                setVisibleUsersInfo({
                                    values: usersInfo.values.filter((per: Person) => {
                                        return per.name.toLowerCase().includes(ev.target.value.toLowerCase()) ||
                                            per.last_name.toLowerCase().includes(ev.target.value.toLowerCase()) ||
                                            per.email.toLowerCase().includes(ev.target.value.toLowerCase()) ||
                                            per.location.toLowerCase().includes(ev.target.value.toLowerCase()) ||

                                            (per.name + " " + per.last_name).replaceAll(" ", "").toLowerCase().includes(ev.target.value.toLowerCase().replaceAll(" ", ""))
                                    }), word: ev.target.value
                                })
                            } else {
                                setVisibleUsersInfo({ values: usersInfo.values, word: "" })
                            }

                            setTimeout(() => {
                                searchUserRef.current.focus()
                            }, 20)
                        }}
                    />
                    {
                        usersInfo.isLoaded ?
                            <div className="users-finded">
                                <div className="table-header-users-finded">
                                    <span>Nombre</span>
                                    {
                                        window.screen.availWidth > 500 ?
                                            <span>Email</span>
                                            :
                                            <></>
                                    }
                                    <span><i className="bi bi-info"></i></span>
                                </div>

                                {
                                    visibleUsersInfo.values.map((person: Person, index: number) => {
                                        return <UserStats person={person} index={index} />
                                    })
                                }
                            </div>
                            :
                            <Loader />
                    }
                </div>
            </>
            :
            <Loader />

    }

    const StudentsAdmin = () => {
        type addedUserType = { email: string, pass: string, user_code?: number };

        const [addedUser, setAddedUser] = useState([] as addedUserType[]);
        const [selUser, setSelUser] = useState([] as addedUserType[]);
        const [usersInfoC, setUsersInfoC] = useState({ values: [] as Person[], isLoaded: false });

        const [errors, setErrors] = useState("");
        const [search, setSearch] = useState("");

        const mailRef = useRef({} as HTMLInputElement);
        const passRef = useRef({} as HTMLInputElement);

        const assignCreateUserRef = useRef({} as HTMLSelectElement);
        const assignCourseRef = useRef({} as HTMLSelectElement);
        const newpassRef = useRef({} as HTMLInputElement);

        const [enabledNewPass, setenabledNewPass] = useState(false);

        useEffect(() => {
            madePost(
                {
                    organization_code: props.user.organization_code ?? props.user.admin_of,
                    key: "getUsersFromOrganization"
                },
                "courses.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    let vlss: Person[] = [];
                    res.VALUE.map((e: Person) => {
                        if (vlss.find((evv: Person) => {
                            return evv.user_code == e.user_code;
                        }) == undefined) {
                            vlss.push(e);
                        }
                    });
                    setUsersInfoC({ values: vlss, isLoaded: true })
                } else {
                    toast.error("Un error ha ocurido, intenta nuevamente más tarde: " + res.EXCEPTION_MESSAGE)
                }
            }).catch(err => {
                toast.error("Un error ha ocurrido, intenta nuevamente más tarde: " + JSON.stringify(err))
            })
        }, [])

        const add = () => {
            let ml = mailRef.current.value;
            if (ml.length > 0 && EmailValidator.validate(ml)) {
                if (
                    addedUser.find((v: addedUserType) => { return v.email == ml }) ||
                    usersInfoC.values.find((v: Person) => { return v.email == ml })
                ) {
                    toast.error("Correo: " + ml + " ya existe")
                } else {
                    setAddedUser([...addedUser, { email: ml, pass: passRef.current.value }]);
                    setTimeout(() => {
                        mailRef.current.value = "";
                        mailRef.current.focus()
                    }, 100)
                }
            } else {
                toast.error("El correo debe ser válido")
            }
        }

        const createAndAssing = () => {
            madePost(
                {
                    users: JSON.stringify(addedUser),
                    course: assignCreateUserRef.current.value,
                    key: "createMultiplePersonAccountAndAssing"
                },
                "account.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    if (JSON.parse(JSON.stringify(res.VALUE)).length > 0) {
                        let newVals: Person[] = [...JSON.parse(JSON.stringify(usersInfoC.values)), ...JSON.parse(JSON.stringify(res.VALUE))];
                        setUsersInfoC({ values: newVals, isLoaded: usersInfoC.isLoaded });
                    }


                    //toast.error(res.EXCEPTION_MESSAGE);
                } else {
                    toast.error("Un error ha ocurrido: " + res.EXCEPTION_MESSAGE);
                }
                setAddedUser([]);
                setErrors(res.EXCEPTION_MESSAGE);
            }).catch(err => {
                toast.error("Un error ha ocurrido, intenta nuevamente más tarde: " + JSON.stringify(err))
            })
        }

        const assigneCourseTo = () => {
            madePost(
                {
                    users: JSON.stringify(selUser),
                    course: assignCourseRef.current.value,
                    key: "assignCourseTo"
                },
                "account.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    toast.success("Asignaste a los estudiantes correctamente")
                } else {
                    toast.error("Un error ha ocurrido: " + res.EXCEPTION_MESSAGE);
                }
            }).catch(err => {
                toast.error("Un error ha ocurrido, intenta nuevamente más tarde: " + JSON.stringify(err))
            })

            disableChecks();
        }

        const updatePass = () => {
            madePost(
                {
                    users: JSON.stringify(selUser),
                    newPass: newpassRef.current.value,
                    key: "restorePass"
                },
                "account.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    toast.success("La contraseña de los usuarios se han actualizado")
                } else {
                    toast.error("Un error ha ocurrido: " + res.EXCEPTION_MESSAGE);
                }
            }).catch(err => {
                toast.error("Un error ha ocurrido, intenta nuevamente más tarde: " + JSON.stringify(err))
            })

            disableChecks();
        }

        const disableChecks = () => {
            setSelUser([]);

            document.querySelectorAll('input[type="checkbox"]').forEach((el: any) => {
                el.checked = false;
            })
        }

        const toogleCollapse = (idx: number) => {
            var collapseContent = document.getElementById(`collapse-content-${idx}`);
            if (collapseContent) {
                collapseContent.classList.toggle("collapsed");
            }
        }

        return <div className="admin-users form">
            {
                courses.isLoaded ?
                    <div style={{ display: "flex", flexFlow: "wrap column", alignItems: "stretch", justifyContent: "flex-start", padding: "1rem 0", gap: "1rem" }}>
                        <div className="form-header">
                            <h5>Crear usuarios</h5>
                        </div>

                        <li className="d-flex flex-row pb-3" style={{ flexFlow: "nowrap row", width: "100%", alignItems: "flex-end", gap: "1rem" }} >
                            <div style={{ display: "grid", gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)", gap: "1rem", width: "100%" }}>
                                <Input innerRef={mailRef} placeholder={"Email"} hasBorder type="email" />
                                <Input innerRef={passRef} placeholder={"Contraseña"} hasBorder type="text" onKeyUp={(ev: any) => {
                                    if (ev.key == "Enter") {
                                        add();
                                    }
                                }} />
                            </div>
                            <Button solid onClick={() => {
                                add()
                            }}>
                                <i className="bi bi-plus"></i>
                            </Button>
                        </li>
                        <ul className="px-0 py-2" style={{ maxHeight: "20rem", overflow: "auto" }}>

                            {
                                addedUser.map((u: addedUserType) => {
                                    return <li className="d-flex flex-row py-2" style={{ flexFlow: "nowrap row", width: "100%", gap: "1rem", borderBottom: "1px solid rgba(0,0,0,0.05)" }} >
                                        <div style={{ display: "grid", gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)", gap: "1rem", width: "100%" }}>
                                            <span style={{ display: "block", margin: "auto 0" }}>{u.email}</span>
                                            <span style={{ display: "block", margin: "auto 0" }}>{u.pass}</span>
                                        </div>
                                        <Button solid onClick={() => {
                                            setAddedUser(JSON.parse(JSON.stringify(addedUser)).filter((us: addedUserType) => {
                                                return us.email != u.email;
                                            }))
                                        }}>
                                            <i className="bi bi-dash"></i>
                                        </Button>
                                    </li>
                                })
                            }
                        </ul>

                        {
                            addedUser.length > 0 ?
                                <div className="d-flex flex-row justify-content-end align-items-end" style={{ gap: "1rem" }}>
                                    <span>Asignar al curso</span>
                                    <Input innerRef={assignCreateUserRef} type="select" placeholder={"Cursos"}>
                                        {
                                            courses.values.map((c: Course) => {
                                                return <option value={c.course_code}>{c.title}</option>
                                            })
                                        }
                                    </Input>
                                    <Button solid onClick={() => {
                                        createAndAssing();
                                    }}>
                                        Crear y asignar {addedUser.length} usuarios
                                    </Button>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    :
                    <Loader />
            }

            {
                errors.length > 0 ?
                    <div className="pb-4 d-flex flex-column">
                        <span className="text-danger">Se han detectado los siguientes correos ya existentes: </span>

                        {
                            errors.split(" ; ").map((e: string) => {
                                return <span className="text-danger">- <b>{e}</b></span>
                            })
                        }


                    </div>
                    :
                    <></>
            }

            <div className="form-header" style={{ display: 'flex', flexFlow: 'wrap row', alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: '2rem', rowGap: '0.5rem' }}>
                <h5>Todos los usuarios </h5>

                <Input placeholder={'Buscar por email, nombre, etc'} type="search" style={{ marginLeft: 'auto' }} onInput={(ev) => {
                    try {
                        setSearch(ev.target.value)
                    } catch (err) { }
                }} />
            </div>
            <ul className="persons-admin-statistics px-0 pt-2" style={{ maxHeight: "20rem", overflow: "auto" }}>
                {
                    usersInfoC.isLoaded ?
                        <>
                            {
                                usersInfoC.values.map((v: Person, idx: number) => {
                                    try {
                                        if (search.length > 0 && (!v.email.includes(search) && !v.name.includes(search) && !v.last_name.includes(search))) {
                                            return <></>
                                        }
                                    } catch (err) { }

                                    return <li className="d-flex flex-col" key={`idx-${idx}`} style={{ padding: ".25rem 0", listStyle: "none", display: 'flex', flexFlow: 'nowrap column' }}>
                                        <div style={{ display: 'flex', flexFlow: "nowrap row", alignItems: "center", gap: "1rem", width: '100%' }}>
                                            <input type="checkbox" id={"select-checkbox-person-" + idx} onChange={(ev: any) => {
                                                if (ev.target.checked) {
                                                    setSelUser([...selUser, { email: v.email, pass: "", user_code: v.user_code }]);
                                                } else {
                                                    setSelUser(JSON.parse(JSON.stringify(selUser)).filter((vv: addedUserType) => {
                                                        return vv.email != v.email;
                                                    }));
                                                }
                                            }} />
                                            <label htmlFor={"select-checkbox-person-" + idx} className="d-flex flex-column align-items-start w-100" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.05)", flexFlow: "wrap row" }}>
                                                <small style={{ paddingRight: ".5rem" }}>{v.name + v.last_name}
                                                    :</small>
                                                <small><b>{v.email}</b></small>
                                            </label>

                                            <svg onClick={() => { toogleCollapse(idx) }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="black" className="w-6 h-6" style={{ width: '1rem', height: '1rem', marginRight: '.5rem', cursor: 'pointer' }}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
                                            </svg>
                                        </div>

                                        <div className="tool w-full collapse-content" id={`collapse-content-${idx}`} style={{ width: '100%', flexFlow: 'nowrap column', gap: '.25rem' }}>
                                            {
                                                courses.isLoaded ?
                                                    <GenerateURL courses={courses.values} person={v} />
                                                    :
                                                    <Loader />
                                            }
                                        </div>
                                    </li>
                                })
                            }
                        </>
                        :
                        <Loader />
                }
            </ul>

            {
                selUser.length > 0 ?
                    <div style={{ display: "flex", flexFlow: "nowrap column" }}>
                        <div className="d-flex flex-row justify-content-end align-items-end pb-4" style={{ gap: "1rem" }}>
                            <span>Agregar al curso</span>
                            <Input innerRef={assignCourseRef} type="select" placeholder={"Cursos"}>
                                {
                                    courses.values.map((c: Course) => {
                                        return <option value={c.course_code}>{c.title}</option>
                                    })
                                }
                            </Input>
                            <Button solid onClick={() => {
                                assigneCourseTo();
                            }}>
                                {selUser.length} usuarios
                            </Button>
                        </div>
                        <div className="d-flex flex-row justify-content-end align-items-end pb-4" style={{ gap: "1rem" }}>
                            <span>Restaurar contraseña</span>
                            <Input innerRef={newpassRef} type="text" placeholder={"Nueva contraseña"} innerPlaceholder="10 caracteres de longitud como mínimo" onInput={(ev: any) => {
                                if (ev.target.value.length > 10) {
                                    setenabledNewPass(true);
                                } else {
                                    setenabledNewPass(false);
                                }
                            }} />
                            <Button disabled={!enabledNewPass} solid onClick={() => {
                                updatePass();
                            }}>
                                {selUser.length} usuarios
                            </Button>
                        </div>
                    </div>
                    :
                    <></>
            }
        </div>
    }

    return <div className="statistics-page contrast">
        <div className="form statistics">
            <div className="form-header ">
                <h5>ESTADISTICAS</h5>
                <p>Aqui puedes visualizar el impacto de tus cursos y los intereses mas comunes en tu comunidad</p>
            </div>
        </div>

        <Tabs className={"tabs-components-statistics"}>
            <TabList style={{ display: "block", marginBottom: 0 }}>
                <Tab>Visualizaciones</Tab>
                <Tab>Lecciones más vistas</Tab>
                <Tab>Público alcanzado</Tab>
                <Tab>Actividad de usuarios</Tab>
                <Tab>Gestionar usuarios</Tab>
            </TabList>

            <TabPanel >
                {courses.isLoaded ?
                    <>
                        <div className="form my-0">
                            {
                                <div className="control">
                                    <Input placeholder="Curso" type="select"
                                        defaultValue={selectedCourse.course_code === 0 ? undefined : selectedCourse.course_code}
                                        onChange={(ev: any) => {
                                            setSelectedCourse({ course_code: ev.target.value, isSelected: true });
                                        }}>
                                        {
                                            courses.values.map((course: Course, index: number) => {
                                                return <option key={"course-" + index} value={course.course_code}>{course.title}</option>
                                            })
                                        }
                                    </Input>
                                </div>
                            }

                            <hr className="w-100 mt-2 mb-3" />

                            {
                                selectedCourse.isSelected ?
                                    <>
                                    </>
                                    :
                                    <label>Ningun curso seleccionado</label>
                            }

                            <div className="form-header mt-2">
                                <h5>Visualizaciones</h5>
                                <div className="plot-message">
                                    <small>Total de visualizaciones por mes: <b>{" " + generateLessonViews("quantity")}</b> </small>
                                    <small>Estimado de visualizaciones diarias: <b>{" " + Math.floor(generateLessonViews("quantity") / 30)}</b> </small>
                                </div>
                            </div>

                            {lessonsCourseStatistics.isLoaded ?
                                <>
                                    <Plot
                                        data={generateDateModules()}
                                        layout={{
                                            showlegend: true,
                                            legend: { orientation: "h" },
                                            autosize: true,
                                            height: 500,
                                            margin: {
                                                r: 0,
                                                l: 0,
                                            }
                                        }}
                                        useResizeHandler
                                        config={{ responsive: true, displaylogo: false }}
                                        divId="modules"
                                    />
                                </>
                                :
                                <></>
                            }
                        </div>
                    </>
                    :
                    <Loader />
                }
            </TabPanel>
            <TabPanel>
                {courses.isLoaded ?
                    <>
                        <div className="form my-0" >
                            {
                                <div className="control">
                                    <Input placeholder="Curso" type="select"
                                        defaultValue={selectedCourse.course_code === 0 ? undefined : selectedCourse.course_code}
                                        onChange={(ev: any) => {
                                            setSelectedCourse({ course_code: ev.target.value, isSelected: true });
                                        }}>
                                        {
                                            courses.values.map((course: Course, index: number) => {
                                                return <option key={"course-" + index} value={course.course_code}>{course.title}</option>
                                            })
                                        }
                                    </Input>
                                </div>
                            }

                            <hr className="w-100 mt-2 mb-3" />

                            <div className="form-header">
                                <h5>Lecciones más vistas</h5>
                            </div>

                            {lessonsCourseStatistics.isLoaded ?
                                <>
                                    <Plot
                                        data={generateLessonViews()}
                                        layout={{
                                            showlegend: true,
                                            legend: { orientation: "h" },
                                            autosize: true,
                                            height: 300,
                                            margin: {
                                                r: 0,
                                                l: 0,
                                            }
                                        }}
                                        useResizeHandler
                                        config={{ responsive: true, displaylogo: false }}
                                        divId="lessons"
                                    />
                                    <div className="lesson-ranking">
                                        {
                                            generateLessonViews("data").reverse().map((el: { q: number, name: string }) => {
                                                return <div className="lesson">
                                                    <span>{el.name}</span>
                                                    <p>{el.q} {window.screen.availWidth > mobile ? "visualizaciones" : <i className="bi bi-eye"></i>}</p>
                                                </div>
                                            })
                                        }
                                    </div>
                                </>
                                :
                                <></>
                            }
                        </div>
                    </>
                    :
                    <Loader />
                }
            </TabPanel>
            <TabPanel>
                {courses.isLoaded ?
                    <>
                        <div className="form my-0" >
                            {
                                <div className="control">
                                    <Input placeholder="Curso" type="select"
                                        defaultValue={selectedCourse.course_code === 0 ? undefined : selectedCourse.course_code}
                                        onChange={(ev: any) => {
                                            setSelectedCourse({ course_code: ev.target.value, isSelected: true });
                                        }}>
                                        {
                                            courses.values.map((course: Course, index: number) => {
                                                return <option key={"course-" + index} value={course.course_code}>{course.title}</option>
                                            })
                                        }
                                    </Input>
                                </div>
                            }

                            <hr className="w-100 mt-2 mb-3" />

                            <div className="form-header">
                                <h5>Público alcanzado</h5>
                            </div>

                            {
                                usersInfo.isLoaded ?
                                    <>
                                        <Plot
                                            data={generateInfoUsers()}
                                            layout={{
                                                showlegend: true,
                                                legend: { orientation: "h" },
                                                autosize: true,
                                                height: 300,
                                                margin: {
                                                    r: 0,
                                                    l: 0,
                                                }
                                            }}

                                            useResizeHandler
                                            config={{ responsive: true, displaylogo: false }}
                                            divId="target"
                                        />
                                    </>
                                    :
                                    <Loader />
                            }

                            <hr className="w-100 mt-2 mb-3" />

                            <div className="form-header">
                                <h5>Visualizaciones según ubicación</h5>
                            </div>

                            {
                                usersInfo.isLoaded ?
                                    <div className="sorted-by-location">
                                        <div className="titles-sorted-by-location">
                                            <span><b>Ubicación</b></span>
                                            <span><b><i className="bi bi-people-fill"></i></b></span>
                                        </div>
                                        {
                                            generateListCountries().map((lo: any[]) => {
                                                return <div>
                                                    <span>{lo[0]}</span>
                                                    <span>{lo[1]}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                    :
                                    <Loader />
                            }

                        </div>
                    </>
                    :
                    <Loader />
                }
            </TabPanel>
            <TabPanel>
                <UsersActivity />
            </TabPanel>
            <TabPanel>
                <StudentsAdmin />
            </TabPanel>
        </Tabs>


    </div>
}

export default Statistics;