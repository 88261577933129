import { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Input from "../components/Input";
import { madePost } from "../functions/post";
import Button from "../components/Button";
import { Certificate, ResponseGeneral } from "../functions/types";
import toast from "react-hot-toast";
import { onCatch } from "../functions/variables";
import Loader from "../components/Loader";

const Certificates = () => {
    const [certs, setCerts] = useState({ values: [], isLoaded: false, isSearching: false })
    const vlRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {

    }, [])

    return <>
        <Header />

        <section className="" style={{ paddingTop: "6rem", paddingBottom: "6rem" }}>
            <h5>Valida tu certificado</h5>
            <h6 className="title-subsection">Puedes revisar o validar la autenticidad de un certificado con su ID a continuación:</h6>

            <Input innerRef={vlRef} placeholder={'ID del certificado'} style={{ marginTop: "3rem" }} />

            <Button border style={{ marginTop: "1rem", marginLeft: "auto", marginBottom: '3rem' }} onClick={() => {
                setCerts({ values: [], isLoaded: false, isSearching: true });

                madePost(
                    {
                        key: 'get-certificate-byid-or-email',
                        id: vlRef.current!.value,
                    },
                    "certificatemanage.php"
                ).then((r) => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        setCerts({ values: res.VALUE, isLoaded: true, isSearching: false });
                    } else {
                        setCerts({ values: res.VALUE, isLoaded: false, isSearching: false });
                        toast.error("Un error ha ocurrido, reintenta nuevamente más tarde")
                    }
                }).catch(onCatch)
            }}>Buscar</Button>

            <hr style={{ width: "100%", display: 'block' }} />

            <div style={{marginTop: '3rem'}}>
                {
                    !certs.isSearching ?
                        certs.isLoaded && <ul style={{ display: 'grid', flexFlow: 'nowrap column', gap: '1rem', paddingLeft: '0', listStyle: 'none' }}>
                            <li><h5>Certificados</h5></li>
                            {
                                certs.values.map((c: Certificate) => {
                                    return <a
                                        href={`/api/certificate.php?id=${c.id}`}
                                        target="_blank"
                                        style={{ width: '100%', padding: '1rem 1.5rem', backgroundColor: 'rgba(0,0,0,0.05)', borderRadius: '.25rem', textDecoration: 'none', color: 'black', display: 'flex', flexFlow: 'nowrap column', gap: '.15rem' }}>
                                        <span>Curso: {c.course}</span>
                                        <small>ID: {c.id}</small>
                                        <small>Por: {c.email}</small>
                                        <small>Status: <small color="green">Completado</small></small>
                                    </a>
                                })
                            }
                        </ul>
                        :
                        <Loader />
                }
            </div>
        </section>

        <Footer />

    </>
}

export default Certificates;