/* eslint-disable no-restricted-globals */
import React from "react";
import { useEffect, useRef, useState } from "react";
import DraggableList from "react-draggable-list";
import toast from "react-hot-toast";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ReactTooltip from "react-tooltip";
import { Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from "reactstrap";
import Button from "../../components/Button";
import Input from "../../components/Input";
import InputRich from "../../components/InputRich";
import InstructorCard from "../../components/InstructorCard";
import Loader from "../../components/Loader";
import Quizz from "../../components/Quizz";
import { cloneObject } from "../../functions/functions";
import { madePost } from "../../functions/post";
import { Course, Instructor, LectureLesson, Module, Organization, ResponseCreateCourse, ResponseGeneral, VideoLesson, Quizz as QuizzType } from "../../functions/types";
import { getLoginToken, isUserLogged } from "../../functions/user";
import { onCatch } from "../../functions/variables";

import "../../styles/pages/CourseManager.scss";
import Instructors from "./Instructors";

import DurationPicker from 'react-duration-picker';

const CourseCard = (props: {
	course: Course,
	onSelect: (course: Course) => any,
	onEdit: (course: Course) => any,
	onAdmin: (course: Course) => any
}) => {

	const [isShowTooltip, setIsShowTooltip] = useState(true);

	const unableCourse = () => {
		let ree = confirm("¿Está seguro de deshabilitar el curso? Se dejará de ver en la página principal hasta que la actives nuevamente");

		if (ree) {
			madePost(
				{
					key: "unable-course",
					course: props.course.course_code
				},
				"manage.php"
			).then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					toast.success("El curso se ha inhabilitado");
				} else {
					toast.error(res.EXCEPTION_MESSAGE)
				}
			}).catch(onCatch)
		}
	}
	const enableCourse = () => {
		let ree = confirm("¿Está seguro de habilitar el curso? ");

		if (ree) {
			madePost(
				{
					key: "enable-course",
					course: props.course.course_code
				},
				"manage.php"
			).then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					toast.success("El curso se ha vuelto a habilitar");
				} else {
					toast.error(res.EXCEPTION_MESSAGE)
				}
			}).catch(onCatch)
		}
	}

	return <div className="course-card-component"
		onClick={(e: any) => {

			if (!((e.target as any).classList.contains("top-right")) && !(e.target as any).classList.contains("bi")) {
				props.onAdmin(props.course);
				props.onSelect(props.course)
			}

		}}

		onMouseLeave={() => {
			setIsShowTooltip(false);
			setTimeout(() => {
				setIsShowTooltip(true);
			}, 50)
		}}>
		{isShowTooltip && <ReactTooltip
			id={"tooltip-coursecard-" + props.course.course_code}
			className="tooltip-course-manager"
			place="right"
			type="dark"
			effect="solid"
		/>}
		<img src={props.course.cover_image} alt={props.course.title} />
		<div>
			<div className="top-right">
				<i className="bi bi-pencil"
					onMouseEnter={() => {
						setIsShowTooltip(true);
					}}
					onMouseLeave={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
					}}
					onClick={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
						props.onEdit(props.course);
						props.onSelect(props.course)
					}}
					data-for="tooltip-sidebar"
					data-tip={"Editar"}
				></i>

				<i className="bi bi-card-list"
					onMouseEnter={() => {
						setIsShowTooltip(true);
					}}
					onMouseLeave={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
					}}
					onClick={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
						props.onAdmin(props.course);
						props.onSelect(props.course)
					}}
					data-for="tooltip-sidebar"
					data-tip={"Gestionar"}
				></i>

				<i className={props.course.active == 1 ? "bi bi-x admin-courses" : "bi bi-plus admin-courses"}
					onMouseEnter={() => {
						setIsShowTooltip(true);
					}}
					onMouseLeave={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
					}}
					onClick={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
						if (props.course.active == 1) {
							unableCourse();
						} else {
							enableCourse();
						}
					}}
					data-for="tooltip-sidebar"
					data-tip={props.course.active == 1 ? "Deshabilitar" : "Habilitar"}
				></i>
			</div>
			<span>{props.course.title}{props.course.active == 0 ? <small style={{ color: "rgba(0,0,0,.5)" }}> (Deshabilitado)</small> : ""}</span>
			<p>{props.course.description}</p>

		</div>
	</div>
}

const ModuleCard = (props: {
	module: Module,
	onSelect: (module: Module) => any,
	onEdit: (module: Module) => any,
}) => {

	const [isShowTooltip, setIsShowTooltip] = useState(true);

	const unableModule = () => {
		let ree = confirm("¿Está seguro de deshabilitar el módulo? Se dejará de ver en la página principal hasta que la actives nuevamente");

		if (ree) {
			madePost(
				{
					key: "unable-module",
					module: props.module.module_code
				},
				"manage.php"
			).then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					toast.success("El módulo se ha inhabilitado");
				} else {
					toast.error(res.EXCEPTION_MESSAGE)
				}
			}).catch(onCatch)
		}
	}
	const enableModule = () => {
		let ree = confirm("¿Está seguro de habilitar el módulo? ");

		if (ree) {
			madePost(
				{
					key: "enable-module",
					module: props.module.module_code
				},
				"manage.php"
			).then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					toast.success("El módulo se ha vuelto a habilitar");
				} else {
					toast.error(res.EXCEPTION_MESSAGE)
				}
			}).catch(onCatch)
		}
	}

	return <div className="course-card-component"
		onClick={(e: any) => {

			if (!((e.target as any).classList.contains("top-right")) && !(e.target as any).classList.contains("bi")) {
				props.onSelect(props.module)
			}

		}}
		onMouseLeave={() => {
			setIsShowTooltip(false);
			setTimeout(() => {
				setIsShowTooltip(true);
			}, 50)
		}}>
		{isShowTooltip && <ReactTooltip
			id={"tooltip-coursecard-" + props.module.module_code}
			className="tooltip-course-manager"
			place="right"
			type="dark"
			effect="solid"
		/>}
		<div>
			<div className="top-right">
				<i className="bi bi-pencil"
					onMouseEnter={() => {
						setIsShowTooltip(true);
					}}
					onMouseLeave={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
					}}
					onClick={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
						props.onEdit(props.module);
					}}
					data-for="tooltip-sidebar"
					data-tip={"Editar"}
				></i>
				<i className="bi bi-card-list"
					onMouseEnter={() => {
						setIsShowTooltip(true);
					}}
					onMouseLeave={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
					}}
					onClick={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)

						props.onSelect(props.module)
					}}
					data-for="tooltip-sidebar"
					data-tip={"Gestionar"}
				></i>

				<i className={props.module.active == 1 ? "bi bi-x admin-courses" : "bi bi-plus admin-courses"}
					onMouseEnter={() => {
						setIsShowTooltip(true);
					}}
					onMouseLeave={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
					}}
					onClick={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)

						if (props.module.active == 1) {
							unableModule();
						} else {
							enableModule();
						}
					}}
					data-for="tooltip-sidebar"
					data-tip={props.module.active == 1 ? "Deshabilitar" : "Habilitar"}
				></i>
			</div>
			<div>
				<span>{props.module.module_title} {props.module.active == 0 ? <small style={{ color: "rgba(0,0,0,.5)" }}> (Deshabilitado)</small> : ""}</span>
				<p>{props.module.module_description}</p>

			</div>
		</div>
	</div>
}

const CourseForm = (props: {
	course?: Course,
	type: "edit" | "create",
	onSave: (course: Course) => any,
	onReturn: () => any
}) => {

	const user = isUserLogged() as Organization;

	const titleRef = useRef(new Object as HTMLInputElement);
	const desRef = useRef(new Object as HTMLInputElement);
	const typeRef = useRef(new Object as HTMLSelectElement);
	const catRef = useRef(new Object as HTMLSelectElement);
	const targetRef = useRef(new Object as HTMLInputElement);
	const fileRef = useRef(new Object as HTMLInputElement);

	const [isValidForm, setIsValidForm] = useState(false);
	const [availableInstructors, setAvailableInstructors] = useState({ instructors: [] as Instructor[], isLoaded: false });
	const [selectedInstructors, setSelectedInstructors] = useState([] as Instructor[]);
	const [tempFinded, setTempFinded] = useState({ isOpen: false, values: [] as Instructor[] });

	useEffect(() => {
		if (props.course?.instructors && availableInstructors.isLoaded) {
			try {
				let codes = (JSON.parse(props.course.instructors as unknown as string) as number[]);

				let inst = availableInstructors.instructors.filter((ins: Instructor) => {
					return codes.includes(ins.instructor_code!);
				})

				setSelectedInstructors(inst);

			} catch (err) {

			}
		}
	}, [props, availableInstructors])

	useEffect(() => {
		getInstructors()
	}, [])

	const getInstructors = () => {
		setAvailableInstructors({ instructors: availableInstructors.instructors, isLoaded: false });
		madePost(
			{
				key: "get-organization-instructors",
				user: user.email,
				token: getLoginToken()
			},
			"manage.php"
		).then(r => {
			let res = r as ResponseGeneral;

			if (res.RESULT) {
				setAvailableInstructors({ instructors: res.VALUE, isLoaded: true });
			} else {
				toast.error(res.EXCEPTION_MESSAGE)
			}
		}).catch(onCatch)
	}

	const analizeForm = () => {
		if (
			titleRef.current.value.length > 0 &&
			desRef.current.value.length > 0 &&
			typeRef.current.value.length > 0 &&
			catRef.current.value.length > 0 &&
			targetRef.current.value.length > 0 &&
			fileRef.current.files!.length > 0 &&
			selectedInstructors.length > 0
		) {
			setIsValidForm(true);
		} else {
			setIsValidForm(false);
		}
	}

	useEffect(() => {
		analizeForm()
	}, [selectedInstructors])

	const searchInstructor = (ev: any) => {
		if (ev.target.value.length > 0) {
			let finded = availableInstructors.instructors.filter((ins: Instructor) => {
				if (!selectedInstructors.find(instructor => instructor.instructor_code == ins.instructor_code) &&
					(
						ins.name.toLowerCase().includes(ev.target.value.toLowerCase()) ||
						ins.last_name.toLowerCase().includes(ev.target.value.toLowerCase()) ||
						ins.profession.toLowerCase().includes(ev.target.value.toLowerCase())
					)
				) {
					return true;
				} else {
					return false;
				}
			});

			setTempFinded({ isOpen: true, values: finded });
		} else {
			setTempFinded({ isOpen: false, values: [] });
		}
	}

	return <div className="form-course form">

		<div className="form-header">
			<h5 className="title-section left mb-1">{props.type === "create" ? "Creación de curso" : "Edición de curso"}</h5>
			<p className="description-section">{props.type === "create" ? "Crea y lanza tu curso inmediatamente a todo el mundo" : "Modifica la información de tu curso"}</p>
		</div>

		<div className="side-to-side">
			<div>
				<Input disabled={props.type === "edit"} placeholder="Titulo" defaultValue={props.type === "edit" ? props.course!.title : undefined} innerRef={titleRef} onInput={analizeForm} />
				<Input placeholder="Descripción" type="textarea" defaultValue={props.type === "edit" ? props.course!.description : undefined} innerRef={desRef} onInput={analizeForm} />
				<Input placeholder="Tipo" type="select" defaultValue={props.type === "edit" ? props.course!.type : undefined} innerRef={typeRef} onChange={analizeForm}>
					<option value="course">Curso</option>
				</Input>
				<Input placeholder="Imagen de portada" type="file" accept=".png, .jpg, .jpeg" innerRef={fileRef} onInput={analizeForm} />
				<Input placeholder="Categoria" type="select" defaultValue={props.type === "edit" ? props.course!.category : undefined} innerRef={catRef} onInput={analizeForm} >
					<option value="eco">Medio ambiente</option>
					<option value="tech">Tecnologia</option>
					<option value="admin">Administración</option>
				</Input>
				<Input placeholder="Público objetivo" type="textarea" defaultValue={props.type === "edit" ? props.course!.target_audience : undefined} innerRef={targetRef} onInput={analizeForm} />

			</div>

			<div className="instructors">
				<h5 className="title-section left mb-2">Instructores <i className="bi bi-arrow-clockwise" style={{ cursor: "pointer" }} onClick={getInstructors}></i></h5>

				<Input placeholder={<><>Buscar instructor</><span className="mx-2 badge" color="blue">{availableInstructors.isLoaded ? (availableInstructors.instructors.length - selectedInstructors.length) : "..."}</span></>} hasBorder className="mb-2" innerPlaceholder="Instructor" onInput={searchInstructor} />
				<div className="temp-find-ins">
					{
						tempFinded.isOpen ?
							<>

								{
									tempFinded.values.map((ins: Instructor, index: number) => {
										return <span key={"ins-" + index} onClick={() => {
											setSelectedInstructors([...selectedInstructors, ins]);
											setTempFinded({ isOpen: true, values: [] })
										}}>{ins.name + " " + ins.last_name} <small>{ins.profession}</small> </span>
									})
								}
							</>
							:
							availableInstructors.isLoaded ?
								<div className="all-instructors-showed">
									{
										availableInstructors.instructors.filter((ins: Instructor) => {
											return selectedInstructors.findIndex((ii: Instructor) => { return ii.instructor_code === ins.instructor_code }) === -1;
										}).map((ins: Instructor, index: number) => {
											return <span key={"ins-" + index} onClick={() => {
												setSelectedInstructors([...selectedInstructors, ins]);
												setTempFinded({ isOpen: true, values: [] })
											}}>{ins.name + " " + ins.last_name} <small>{ins.profession}</small> </span>
										})
									}
								</div>
								:
								<Loader />
					}
				</div>

				<h6 className="title-section left my-3">Seleccionados<span className="mx-2 badge" color="blue">{selectedInstructors.length}</span></h6>
				{
					availableInstructors.isLoaded ?
						selectedInstructors.map((ins: Instructor, index: number) => {
							return <InstructorCard instructor={ins} index={index} onAdd={(instructor: Instructor) => { }} onRemove={(instructor: Instructor) => {
								let cleaned = selectedInstructors.filter((ins) => ins.instructor_code !== instructor.instructor_code);

								setSelectedInstructors(cleaned)
							}} />
						})
						:
						<Loader></Loader>
				}
			</div>
		</div>

		<div className="form-footer">
			<Button border className="mx-1" onClick={props.onReturn}>Cancelar</Button>
			<Button solid
				disabled={!isValidForm}
				onClick={
					() => {
						let course = props.type === "create" ? {} as Course : props.course as Course;

						course.title = titleRef.current.value;
						course.description = desRef.current.value;
						course.type = typeRef.current.value as "course" | "train";
						course.category = catRef.current.value;
						course.target_audience = targetRef.current.value;
						course.cover_image = fileRef.current.files![0];

						let instructorCodes: number[] = [];
						selectedInstructors.map((ins: Instructor) => {
							instructorCodes.push(ins.instructor_code!);
						})
						course.instructors = (instructorCodes.length === 0 ? "[" + instructorCodes[0] + "]" : JSON.stringify(instructorCodes)) as any;

						props.onSave(course)

					}
				}>{props.type === "edit" ? "Editar" : "Crear"}</Button>
		</div>
	</div>
}

const LessonCard = (props: {
	type: "video" | "lecture",
	lesson: LectureLesson & VideoLesson,
	onEdit: (lesson: LectureLesson & VideoLesson) => any
}) => {
	const [isShowTooltip, setIsShowTooltip] = useState(true);

	const unableLesson = () => {
		let ree = confirm("¿Está seguro de deshabilitar la lección? Se dejará de ver en la página principal hasta que la actives nuevamente");

		if (ree) {
			madePost(
				{
					key: "unable-lesson",
					type: props.type,
					lesson: props.type === "video" ? props.lesson.video_lesson_code : props.lesson.lecture_lesson_code
				},
				"manage.php"
			).then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					toast.success("La lección se ha inhabilitado");
				} else {
					toast.error(res.EXCEPTION_MESSAGE)
				}
			}).catch(onCatch)
		}
	}
	const enableLesson = () => {
		let ree = confirm("¿Está seguro de habilitar la lección? ");

		if (ree) {
			madePost(
				{
					key: "enable-lesson",
					type: props.type,
					lesson: props.type === "video" ? props.lesson.video_lesson_code : props.lesson.lecture_lesson_code
				},
				"manage.php"
			).then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					toast.success("La lección se ha vuelto a habilitar");
				} else {
					toast.error(res.EXCEPTION_MESSAGE)
				}
			}).catch(onCatch)
		}
	}

	return <div className="course-card-component"
		onClick={(e: any) => {

			if (!((e.target as any).classList.contains("top-right")) && !(e.target as any).classList.contains("bi")) {
				props.onEdit(props.lesson)
			}

		}}
		onMouseLeave={() => {
			setIsShowTooltip(false);
			setTimeout(() => {
				setIsShowTooltip(true);
			}, 50)
		}}>
		{isShowTooltip && <ReactTooltip
			id={"tooltip-coursecard-" + props.type + (props.type == "lecture" ? props.lesson.lecture_lesson_code : props.lesson.video_lesson_code)}
			className="tooltip-course-manager"
			place="right"
			type="dark"
			effect="solid"
		/>}
		<div>
			<div className="top-right">
				<i className="bi bi-pencil"
					onMouseEnter={() => {
						setIsShowTooltip(true);
					}}
					onMouseLeave={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
					}}
					onClick={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
						props.onEdit(props.lesson);
					}}
					data-for="tooltip-sidebar"
					data-tip={"Editar"}
				></i>

				<i className={props.lesson.active == 1 ? "bi bi-x admin-courses" : "bi bi-plus admin-courses"}
					onMouseEnter={() => {
						setIsShowTooltip(true);
					}}
					onMouseLeave={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
					}}
					onClick={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
						if (props.lesson.active == 1) {
							unableLesson();
						} else {
							enableLesson();
						}
					}}
					data-for="tooltip-sidebar"
					data-tip={props.lesson.active == 1 ? "Deshabilitar" : "Habilitar"}
				></i>

			</div>
			<div>
				<i className={"bi bi-" + (props.type == "lecture" ? "book" : "play-fill")}></i>
				<span>{props.lesson.title}{props.lesson.active == 0 ? <small style={{ color: "rgba(0,0,0,.5)" }}> (Deshabilitado)</small> : ""}</span>
				{/*<p dangerouslySetInnerHTML={{__html: props.lesson.description.slice(0, 50) }}></p> */}

			</div>
		</div>
	</div>
}

const QuizzCard = (props: {
	type: "quizz",
	lesson: QuizzType,
	onEdit: (lesson: QuizzType) => any
}) => {
	const [isShowTooltip, setIsShowTooltip] = useState(true);

	const unableLesson = () => {
		let ree = confirm("¿Está seguro de deshabilitar el cuestionario? Se dejará de ver en la página principal hasta que la actives nuevamente");

		if (ree) {
			madePost(
				{
					key: "unable-lesson",
					type: props.type,
					lesson: props.lesson.quizz_id
				},
				"manage.php"
			).then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					toast.success("El cuestionario se ha inhabilitado");
				} else {
					toast.error(res.EXCEPTION_MESSAGE)
				}
			}).catch(onCatch)
		}
	}
	const enableLesson = () => {
		let ree = confirm("¿Está seguro de habilitar el cuestionario? ");

		if (ree) {
			madePost(
				{
					key: "enable-lesson",
					type: props.type,
					lesson: props.lesson.quizz_id
				},
				"manage.php"
			).then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					toast.success("El cuestionario se ha vuelto a habilitar");
				} else {
					toast.error(res.EXCEPTION_MESSAGE)
				}
			}).catch(onCatch)
		}
	}

	return <div className="course-card-component"
		onClick={(e: any) => {

			if (!((e.target as any).classList.contains("top-right")) && !(e.target as any).classList.contains("bi")) {
				props.onEdit(props.lesson);
			}

		}}
		onMouseLeave={() => {
			setIsShowTooltip(false);
			setTimeout(() => {
				setIsShowTooltip(true);
			}, 50)
		}}>
		{isShowTooltip && <ReactTooltip
			id={"tooltip-coursecard-" + props.lesson.quizz_id}
			className="tooltip-course-manager"
			place="right"
			type="dark"
			effect="solid"
		/>}
		<div>
			<div className="top-right">
				<i className="bi bi-pencil"
					onMouseEnter={() => {
						setIsShowTooltip(true);
					}}
					onMouseLeave={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
					}}
					onClick={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
						props.onEdit(props.lesson);
					}}
					data-for="tooltip-sidebar"
					data-tip={"Editar"}
				></i>

				<i className={props.lesson.active == 1 ? "bi bi-x admin-courses" : "bi bi-plus admin-courses"}
					onMouseEnter={() => {
						setIsShowTooltip(true);
					}}
					onMouseLeave={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
					}}
					onClick={() => {
						setIsShowTooltip(false);
						setTimeout(() => {
							setIsShowTooltip(true);
						}, 50)
						if (props.lesson.active == 1) {
							unableLesson();
						} else {
							enableLesson();
						}
					}}
					data-for="tooltip-sidebar"
					data-tip={props.lesson.active == 1 ? "Deshabilitar" : "Habilitar"}
				></i>

			</div>
			<div>
				<i className="bi bi-patch-question"></i>
				<span>{props.lesson.title}{props.lesson.active == 0 ? <small style={{ color: "rgba(0,0,0,.5)" }}> (Deshabilitado)</small> : ""}</span>
				{/*<p dangerouslySetInnerHTML={{__html: props.lesson.description.slice(0, 50) }}></p> */}

			</div>
		</div>
	</div>
}

const AdminModule = (props: {
	isOpen: boolean,
	module: Module,
	course: Course,
	onReturn: () => any,
}) => {

	const user: Organization = isUserLogged() as Organization;

	const [lectures, setLectures] = useState({ lessons: [] as LectureLesson[], isLoaded: false });
	const [videos, setVideos] = useState({ lessons: [] as VideoLesson[], isLoaded: false })
	const [quizzes, setQuizzes] = useState({ lessons: [] as QuizzType[], isLoaded: false })

	const [action, setAction] = useState({ isOpen: false, action: "lessons" as "create-video" | "edit-video" | "create-lecture" | "edit-lecture" | "create-quizz" | "edit-quizz" | "lessons", lesson: {} as LectureLesson & VideoLesson });
	const [isValidForm, setIsValidForm] = useState(false);
	const [usePrev, setUsePrev] = useState(true);

	const [quizzValues, setQuizzValues] = useState({ form: {} as any, solved: {} as any, isEdit: false, quizzId: null as number | null, inUse: false, valid: false });

	const [isDropDwn1Open, setIsDropDwn1Open] = useState(false);

	const titleRef = useRef({} as HTMLInputElement);
	const desRef = useRef({} as HTMLInputElement);
	const fileRef = useRef({} as HTMLInputElement);

	const [timeD, setTimeD] = useState({} as { hours: number, minutes: number, seconds: number });
	const [timeDef, setTimeDef] = useState({} as { hours: number, minutes: number, seconds: number });

	const [Mlessons, setMLessons] = useState(props.module.lessons ?
		(Object.keys(props.module.lessons).length > 0 ?
			JSON.parse(props.module.lessons as unknown as any).map((el: string) => { try { return JSON.parse(el) } catch (err) { return el; } })
			:
			[])
		:
		[]);

	const quizzAnalizeform = () => {
		try {
			if (usePrev) {
				if (
					titleRef.current.value.length > 0 &&
					desRef.current.value.length > 0
				) {
					setIsValidForm(true);
				} else {
					setIsValidForm(false);
				}
			} else {
				if (
					titleRef.current.value.length > 0 &&
					desRef.current.value.length > 0 &&
					quizzValues.valid
				) {
					setIsValidForm(true);
				} else {
					setIsValidForm(false);
				}
			}

		} catch (err) {
			console.debug(err)

			setIsValidForm(false);
		}
	}

	const analizeForm = () => {
		try {
			if (usePrev) {
				if (
					titleRef.current.value.length > 0 &&
					desRef.current.value.length > 0 
				) {
					setIsValidForm(true);
				} else {
					setIsValidForm(false);
				}
			} else {
				if (fileRef.current.files!.length > 0) {
					if (fileRef.current.files![0].size >= (action.action.includes("lecture") ? 41943040 : 103914560)) {

						toast.error("El archivo que se ha de subir no puede pesar más de ".concat(action.action.includes("lecture") ? "40MB" : "100MB"));

						fileRef.current.value = "";
					}
				}
				if (
					titleRef.current.value.length > 0 &&
					desRef.current.value.length > 0 &&
					fileRef.current.files!.length > 0
				) {
					setIsValidForm(true);
				} else {
					setIsValidForm(false);
				}
			}
		} catch (err) {
			setIsValidForm(false)
		}
	}

	const lectureAdmin = () => {
		//Send

		setIsValidForm(false);
		setLectures({ lessons: lectures.lessons, isLoaded: false })

		toast.loading((action.action === "create-lecture" ? "Se está creando" : "Se está editando").concat(" la lección de lectura"))

		madePost({
			key: "admin-lesson",
			type: "lecture",
			action: action.action === "create-lecture" ? "create" : "edit",
			titulo: titleRef.current.value,
			descripcion: desRef.current.value,
			pdf: action.action === "create-lecture" ? fileRef.current.files![0] : (usePrev ? "" : fileRef.current.files![0]),
			time: timeD.hours + "-" + timeD.minutes + "-" + timeD.seconds,

			lesson_code: action.action === "edit-lecture" ? action.lesson.lecture_lesson_code : null,
			course_title: props.course.title,
			module_title: props.module.module_title,
			organization_name: user.name,

			course_code: props.course.course_code,
			module_code: props.module.module_code,
			organization_code: user.organization_code,

			usePrev: usePrev,
		}
			, "manage.php"
		).then(r => {
			let res = r as ResponseGeneral;

			if (res.RESULT) {
				toast.dismiss();

				toast.success("La lección se ha" + (action.action === "create-lecture" ? " creado correctamente" : "editado correctamente"));

				let newVideo: LectureLesson = new Object as LectureLesson;

				newVideo.course_code = props.course.course_code!;
				newVideo.module_code = props.module.module_code!;
				newVideo.content = desRef.current.value;
				newVideo.active = 1;

				try {
					newVideo.lesson_time = timeD.hours + "-" + timeD.minutes + "-" + timeD.seconds;
				} catch (err) {
					newVideo.lesson_time = "00-10-00";
				}

				newVideo.organization_code = user.organization_code;
				newVideo.title = titleRef.current.value;
				newVideo.lecture_lesson_code = res.VALUE;

				if (action.action === "create-lecture") {
					setLectures({ lessons: [...lectures.lessons, newVideo], isLoaded: true })
				} else {
					let index = lectures.lessons.findIndex((less: LectureLesson) => {
						return less.lecture_lesson_code == newVideo.lecture_lesson_code;
					})

					let cloned = cloneObject(lectures.lessons);
					cloned[index] = newVideo;

					setLectures({ lessons: cloned, isLoaded: true })
				}
				setAction({ isOpen: false, action: "lessons", lesson: action.lesson });
			} else {
				toast.dismiss();

				toast.error(res.EXCEPTION_MESSAGE)
			}
		}).catch(onCatch)
	}

	const videoAdmin = () => {
		//Send

		setIsValidForm(false);
		setVideos({ lessons: videos.lessons, isLoaded: false });

		toast.loading((action.action === "create-video" ? "Se está creando" : "Se está editando").concat(" la lección de video"))

		madePost({
			key: "admin-lesson",
			type: "video",
			action: action.action === "create-video" ? "create" : "edit",
			titulo: titleRef.current.value,
			descripcion: desRef.current.value,
			video: action.action === "create-video" ? fileRef.current.files![0] : (usePrev ? "" : fileRef.current.files![0]),
			time: timeD.hours + "-" + timeD.minutes + "-" + timeD.seconds,

			lesson_code: action.action === "edit-video" ? action.lesson.video_lesson_code : null,
			course_title: props.course.title,
			module_title: props.module.module_title,
			organization_name: user.name,

			course_code: props.course.course_code,
			module_code: props.module.module_code,
			organization_code: user.organization_code,

			usePrev: usePrev
		}
			, "manage.php"
		).then(r => {
			let res = r as ResponseGeneral;

			toast.dismiss();

			if (res.RESULT) {
				toast.success("La leccion se ha " + (action.action === "create-video" ? " creado correctamente" : "editado correctamente"));

				let newVideo: VideoLesson = new Object as VideoLesson;

				newVideo.course_code = props.course.course_code!;
				newVideo.module_code = props.module.module_code!;
				newVideo.description = desRef.current.value;
				newVideo.active = 1;

				try {
					newVideo.lesson_time = timeD.hours + "-" + timeD.minutes + "-" + timeD.seconds;
				} catch (err) {
					newVideo.lesson_time = "00-10-00";
				}

				newVideo.organization_code = user.organization_code;
				newVideo.title = titleRef.current.value;
				newVideo.video_lesson_code = res.VALUE;

				if (action.action === "create-video") {
					setVideos({ lessons: [...videos.lessons, newVideo], isLoaded: true })
				} else {
					let index = videos.lessons.findIndex((less: VideoLesson) => {
						return less.video_lesson_code == newVideo.video_lesson_code;
					})

					let cloned = cloneObject(videos.lessons);
					cloned[index] = newVideo;

					setVideos({ lessons: cloned, isLoaded: true });
				}
				setAction({ isOpen: false, action: "lessons", lesson: action.lesson });
				//getVideos();

			} else {
				toast.error(res.EXCEPTION_MESSAGE)
			}
		}).catch(onCatch)
	}

	const quizzAdmin = () => {
		madePost(
			{
				key: "admin-quizz",
				isEdit: quizzValues.quizzId ? quizzValues.isEdit : false,
				quizz_id: (quizzValues.quizzId),
				form: JSON.stringify(quizzValues.form),
				solvedValues: JSON.stringify(quizzValues.solved),

				titulo: titleRef.current.value,
				descripcion: desRef.current.value,
				time: timeD.hours + "-" + timeD.minutes + "-" + timeD.seconds,
				course_code: props.course.course_code,
				module_code: props.module.module_code,
				organization_code: user.organization_code,
				usePrev: usePrev
			},
			"manage.php"
		).then((r) => {
			let res = r as ResponseGeneral;

			if (res.RESULT) {
				toast.dismiss();

				toast.success("El cuestionario se ha " + (quizzValues.quizzId ? " editado correctamente" : " creado correctamente"));

				let newVideo: QuizzType = {} as QuizzType;

				newVideo.course_code = props.course.course_code!;
				newVideo.module_code = props.module.module_code!;
				newVideo.quizz = quizzValues.form;
				newVideo.quizz_solved = quizzValues.solved;
				newVideo.active = 1;

				try {
					newVideo.lesson_time = timeD.hours + "-" + timeD.minutes + "-" + timeD.seconds;
				} catch (err) {
					newVideo.lesson_time = "00-10-00";
				}

				newVideo.organization_code = user.organization_code;
				newVideo.title = titleRef.current.value;
				newVideo.quizz_id = res.VALUE;

				if (action.action === "create-quizz") {
					setQuizzes({ lessons: [...quizzes.lessons, newVideo], isLoaded: true })
				} else {
					let index = quizzes.lessons.findIndex((less: QuizzType) => {
						return less.quizz_id == newVideo.quizz_id;
					})

					let cloned = cloneObject(quizzes.lessons);
					cloned[index] = newVideo;

					setQuizzes({ lessons: cloned, isLoaded: true })
				}
				setAction({ isOpen: false, action: "lessons", lesson: action.lesson });
			} else {
				toast.dismiss();

				toast.error(res.EXCEPTION_MESSAGE)
			}
		}).catch(onCatch);
	}

	useEffect(() => {
		try {
			let t = action.lesson.lesson_time.toString().split("-");
			setTimeDef({ hours: parseInt(t[0] ? t[0] : "0"), minutes: parseInt(t[1] ? t[1] : "0"), seconds: parseInt(t[2] ? t[2] : "0") });
			setTimeD({ hours: parseInt(t[0] ? t[0] : "0"), minutes: parseInt(t[1] ? t[1] : "0"), seconds: parseInt(t[2] ? t[2] : "0") });
		} catch (err) {
			setTimeDef({ hours: 0, minutes: 10, seconds: 0 });
			setTimeD({ hours: 0, minutes: 10, seconds: 0 });
		}
	}, [action])

	useEffect(() => {
		if (!action.action.includes("edit")) {
			setUsePrev(false);
		} else {
			setUsePrev(true);
		}
	}, [action])

	useEffect(() => {
		analizeForm()
	}, [usePrev])

	useEffect(() => {
		quizzAnalizeform();
	}, [usePrev, quizzValues])

	useEffect(() => {
		getVideos();
		getLectures();
		getQuizzes();

		try {
			setMLessons(props.module.lessons ?
				(Object.keys(props.module.lessons).length > 0 ?
					JSON.parse(props.module.lessons as unknown as any).map((el: string) => { try { return JSON.parse(el) } catch (err) { return el; } })
					:
					[])
				:
				[]);
		} catch (err) { }
	}, [props])

	useEffect(() => {
		if (!props.isOpen) {
			setLectures({ lessons: lectures.lessons, isLoaded: false });
			setVideos({ lessons: videos.lessons, isLoaded: false });
		}
	}, [props])

	const getVideos = () => {

		if (props.isOpen) {
			madePost({
				key: "get-videos-from",
				videos: props.module.lesson_videos
			}, "manage.php").then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					setVideos({ lessons: res.VALUE, isLoaded: true });
				} else {
					toast.error(res.EXCEPTION_MESSAGE)
				}
			}).catch(onCatch)
		}

	}

	const getLectures = () => {

		if (props.isOpen) {
			madePost({
				key: "get-lectures-from",
				lectures: props.module.lesson_lectures
			}, "manage.php").then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					setLectures({ lessons: res.VALUE, isLoaded: true });
				} else {
					toast.error(res.EXCEPTION_MESSAGE)
				}
			}).catch(onCatch)
		}
	}

	const getQuizzes = () => {

		if (props.isOpen) {
			madePost({
				key: "get-quizzes-from",
				lectures: props.module.lesson_quizzes
			}, "manage.php").then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					setQuizzes({ lessons: res.VALUE, isLoaded: true });
				} else {
					toast.error(res.EXCEPTION_MESSAGE)
				}
			}).catch(onCatch)
		}
	}

	const AllLessonsDraggable = () => {
		const [defaultList, setDefaultList] = useState({ values: [], isSet: false, isMoved: false });

		const clasify = () => {
			let res = Mlessons.map((e: { code: number, type: "lecture" | "video" | "quizz", lesson: any, lesson_name: string, lesson_keyid: string }) => {
				switch (e.type) {
					case "lecture":
						e["lesson"] = lectures.lessons.find((l: any) => { return l.lecture_lesson_code == e.code });
						e["lesson_name"] = e["lesson"] ? e["lesson"].title : "No encontrado";
						e["lesson_keyid"] = e["type"] + "-" + e["code"];
						break;

					case "video":
						e["lesson"] = videos.lessons.find((l: any) => { return l.video_lesson_code == e.code });
						e["lesson_name"] = e["lesson"] ? e["lesson"].title : "No encontrado";
						e["lesson_keyid"] = e["type"] + "-" + e["code"];
						break;

					case "quizz":
						e["lesson"] = quizzes.lessons.find((l: any) => { return l.quizz_id == e.code });
						e["lesson_name"] = e["lesson"] ? e["lesson"].title : "No encontrado";
						e["lesson_keyid"] = e["type"] + "-" + e["code"];
						break;
				}

				return e;
			})

			if (!defaultList.isSet) {
				setDefaultList({ values: res, isSet: true, isMoved: false });
			}

			return res;
		}

		//quizzes.isLoaded && videos.isLoaded && lectures.isLoaded 
		const [list, setList] = useState(clasify() as { code: number, type: "lecture" | "video" | "quizz", lesson: any, lesson_name: string, lesson_keyid: string }[]);

		const containerRef = useRef({} as HTMLDivElement);

		const _onListChange = (newList: any) => {
			setList(newList);
			setDefaultList({ values: defaultList.values, isSet: defaultList.isSet, isMoved: true })
		};
		const unableLesson = (type: "lecture" | "video" | "quizz", id: any) => {
			let ree = confirm("¿Está seguro de deshabilitar el cuestionario? Se dejará de ver en la página principal hasta que la actives nuevamente");

			if (ree) {
				madePost(
					{
						key: "unable-lesson",
						type: type,
						lesson: id
					},
					"manage.php"
				).then(r => {
					let res = r as ResponseGeneral;

					if (res.RESULT) {
						toast.success("El cuestionario se ha inhabilitado");
					} else {
						toast.error(res.EXCEPTION_MESSAGE)
					}
				}).catch(onCatch)
			}
		}
		const enableLesson = (type: "lecture" | "video" | "quizz", id: any) => {
			let ree = confirm("¿Está seguro de habilitar el cuestionario? ");

			if (ree) {
				madePost(
					{
						key: "enable-lesson",
						type: type,
						lesson: id
					},
					"manage.php"
				).then(r => {
					let res = r as ResponseGeneral;

					if (res.RESULT) {
						toast.success("El cuestionario se ha vuelto a habilitar");
					} else {
						toast.error(res.EXCEPTION_MESSAGE)
					}
				}).catch(onCatch)
			}
		}

		const updateOrder = () => {
			let newOrder = list.map((el: { code: number, type: "lecture" | "video" | "quizz", lesson: any, lesson_name: string, lesson_keyid: string }) => {
				return { code: el.code, type: el.type };
			});

			toast.loading("Actualizando módulo");

			madePost(
				{
					key: "update-module-order",
					module_code: props.module.module_code,
					course_code: props.course.course_code,
					new_order: JSON.stringify(newOrder)
				},
				"manage.php"
			).then(r => {
				toast.dismiss()
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					toast.success("El módulo se ha actualizado correctamente");
				} else {
					toast.error("No se ha podido actualizar el orden de los módulos");
				}
			}).catch((err) => {
				toast.dismiss();

				onCatch(err);
			})
		}

		class Item extends React.Component<{ item: { code: number, type: "lecture" | "video" | "quizz", lesson: any, lesson_name: string, lesson_keyid: string }, itemSelected: any, dragHandleProps: any }, {}>{
			render(): React.ReactNode {
				const { onMouseDown, onTouchStart } = this.props.dragHandleProps;

				return (
					<div
						className="disable-select list-item course-card-component"

					>
						<div className="top-right-container">
							<div className="top-right">
								<i className="bi bi-pencil"

									onClick={() => {
										setAction({ action: ("edit-" + this.props.item.type) as any, isOpen: true, lesson: this.props.item.lesson })
									}}
									data-for="tooltip-sidebar"
									data-tip={"Editar"}
								></i>

								<i className={this.props.item.lesson.active == 1 ? "bi bi-x admin-courses" : "bi bi-plus admin-courses"}

									onClick={() => {
										if (this.props.item.lesson.active == 1) {
											unableLesson(this.props.item.type, this.props.item.code);
										} else {
											enableLesson(this.props.item.type, this.props.item.code);
										}
									}}
									data-for="tooltip-sidebar"
									data-tip={this.props.item.lesson.active == 1 ? "Deshabilitar" : "Habilitar"}
								></i>

							</div>
						</div>

						<i
							className="disable-select dragHandle bi bi-grip-vertical"
							style={{
								fontWeight: "600",
								width: "1.5rem",
								height: "1.5rem",
							}}
							onTouchStart={(e: any) => {
								e.preventDefault();
								onTouchStart(e);
							}}
							onMouseDown={(e: any) => {
								onMouseDown(e);
							}}
							onTouchEnd={(e: any) => {
							}}
							onMouseUp={() => {
							}}
						></i>

						{
							this.props.item.type == "video" ?
								<i className="bi bi-play-fill"></i>
								:
								(
									this.props.item.type == "lecture" ?
										<i className="bi bi-book"></i>
										:
										<i className="bi bi-patch-question"></i>
								)
						}

						<span>{this.props.item.lesson_name} <small style={{ color: "rgba(0, 0, 0, 0.5)" }}>{this.props.item.lesson.active == 0 ? "(Deshabilitado)" : ""}</small></span>
					</div>
				);
			}
		}

		return <div className="" ref={containerRef} style={{ touchAction: "pan-y", background: "beige" }}>

			<DraggableList
				itemKey={"lesson_keyid"}
				template={Item}
				list={list}
				onMoveEnd={(newList: any) => _onListChange(newList)}
				container={() => containerRef.current}
			/>

			{
				defaultList.isMoved ?
					<div className="mt-4 controls-btns">
						<Button border onClick={() => {
							setList(defaultList.values);
						}}>Cancelar</Button>
						<Button solid onClick={() => {
							updateOrder()
						}}>Guardar</Button>
					</div>
					:
					<></>
			}

		</div>
	}

	return <div className="admin-module form mt-0">
		<div className="form-header">
			<div className="title-with-button">
				<div>
					<h5 className="title-section left mb-1">
						Lecciones disponibles del módulo: {props.module.module_title}
					</h5>
					<p className="description-section mb-0">
						Gestiona, las lecciones de este módulo
					</p>
				</div>
				<div className="form-toolbar">
					<Button border onClick={() => props.onReturn()}>
						<i className="bi bi-chevron-left"></i>
					</Button>

					<Dropdown isOpen={isDropDwn1Open} toggle={(st: any) => {
						console.debug(st)
						setIsDropDwn1Open(!isDropDwn1Open);
					}}
						direction="down"
					>
						<DropdownToggle caret>Crear</DropdownToggle>
						<DropdownMenu >
							<DropdownItem header>Acciones</DropdownItem>
							<DropdownItem onClick={() => {
								setAction({ isOpen: true, action: "create-video", lesson: {} as LectureLesson & VideoLesson });

								setIsDropDwn1Open(false);
							}}>Video</DropdownItem>
							<DropdownItem onClick={() => {
								setAction({ isOpen: true, action: "create-lecture", lesson: {} as LectureLesson & VideoLesson });

								setIsDropDwn1Open(false);
							}}>Lectura</DropdownItem>
							<DropdownItem onClick={() => {
								setAction({ isOpen: true, action: "create-quizz", lesson: {} as LectureLesson & VideoLesson });

								setIsDropDwn1Open(false);
							}}>Quizz</DropdownItem>
						</DropdownMenu>
					</Dropdown>

					{
						action.isOpen && action.action !== "lessons" ?
							<Button solid onClick={() => {
								setAction({ isOpen: true, action: "lessons", lesson: {} as LectureLesson & VideoLesson });
							}}>
								Ver lecciones
							</Button>
							:
							<></>
					}

				</div>
			</div>
		</div>
		{
			action.action === "lessons" ?
				<Tabs>
					<TabList style={{ display: "block" }}>
						<Tab>Videos</Tab>
						<Tab>Lecturas</Tab>
						<Tab>Cuestionarios</Tab>
						<Tab>Todo</Tab>
					</TabList>

					<TabPanel>
						{
							videos.isLoaded ?
								videos.lessons.map((lesson: VideoLesson, index: number) => {
									return <LessonCard
										key={"card-lesson-video-" + index}
										lesson={lesson as VideoLesson & LectureLesson}
										type={"video"}
										onEdit={(lesson: VideoLesson) => {
											setAction({ action: "edit-video", isOpen: true, lesson: lesson as VideoLesson & LectureLesson })
										}}
									/>
								})
								:
								<Loader />
						}
					</TabPanel>
					<TabPanel>
						{
							lectures.isLoaded ?
								lectures.lessons.map((lesson: LectureLesson, index: number) => {
									return <LessonCard
										key={"card-lesson-lecture-" + index}
										lesson={lesson as VideoLesson & LectureLesson}
										type={"lecture"}
										onEdit={(lesson: VideoLesson) => {
											setAction({ action: "edit-lecture", isOpen: true, lesson: lesson as VideoLesson & LectureLesson })
										}}
									/>
								})
								:
								<Loader />
						}
					</TabPanel>

					<TabPanel>
						{
							quizzes.isLoaded ?
								quizzes.lessons.map((lesson: QuizzType, index: number) => {
									return <QuizzCard
										key={"card-quizz-" + index}
										lesson={lesson as QuizzType}
										type={"quizz"}
										onEdit={(lesson: QuizzType) => {
											setAction({ action: "edit-quizz", isOpen: true, lesson: lesson as VideoLesson & LectureLesson | any })
										}}
									/>
								})
								:
								<Loader />
						}
					</TabPanel>

					<TabPanel>
						{
							quizzes.isLoaded && videos.isLoaded && lectures.isLoaded ?
								<AllLessonsDraggable />
								:
								<Loader />
						}
					</TabPanel>
				</Tabs>
				:
				(
					action.action === "create-lecture" || action.action === "edit-lecture" ?
						<div className="form p-0">
							<div className="form-header">

							</div>

							<Input placeholder="Titulo" defaultValue={action.action.includes("edit") ? action.lesson.title : ""} innerRef={titleRef} onInput={analizeForm} />

							<InputRich placeholder="Contenido" defaultValue={action.action.includes("edit") ? action.lesson.content : ""} innerRef={desRef} onInput={analizeForm} />

							{
								action.action === "create-lecture" ?
									<Input placeholder="PDF o contenido extra (40MB Máx)" type="file" accept=".pdf" innerRef={fileRef} onChange={analizeForm} />
									:

									usePrev ?
										<div className="content-added">
											<span>PDF <small>(Contenido previo)</small></span>
											<span color="blue" className="badge pointer" onClick={() => { setUsePrev(false) }}><i className="bi bi-x"></i></span>
										</div>
										:
										<Input placeholder="PDF o contenido extra (40MB Máx)" type="file" accept=".pdf" innerRef={fileRef} onChange={analizeForm} />
							}

							<small>Tiempo de la lección</small>
							<DurationPicker
								onChange={(ev: any) => {
									setTimeD(ev);
								}}
								initialDuration={action.action.includes("edit") ? timeDef : { hours: 0, minutes: 10, seconds: 0 }}
							/>

							<div className="form-footer">
								<Button border className="mx-1" onClick={() => {
									setAction({ action: "lessons", isOpen: false, lesson: action.lesson });
								}}>Cancelar</Button>
								<Button solid disabled={!isValidForm} onClick={lectureAdmin}>
									{action.action === "create-lecture" ? "Crear" : "Editar"}
								</Button>
							</div>
						</div>
						:
						(
							action.action === "create-video" || action.action === "edit-video" ?
								<div className="form p-0">
									<div className="form-header">

									</div>

									<Input placeholder="Titulo" defaultValue={action.action.includes("edit") ? action.lesson.title : ""} innerRef={titleRef} onInput={analizeForm} />

									<InputRich placeholder="Descripcion" defaultValue={action.action.includes("edit") ? action.lesson.description : ""} innerRef={desRef} onInput={analizeForm} />

									{
										action.action === "create-video" ?
											<Input placeholder="Video (100MB Máx)" type="file" accept=".mp4" innerRef={fileRef} onChange={analizeForm} />
											:

											usePrev ?
												<div className="content-added">
													<span>Video <small>(Contenido previo)</small></span>
													<span color="blue" className="badge pointer" onClick={() => { setUsePrev(false) }}><i className="bi bi-x"></i></span>
												</div>
												:
												<Input placeholder="Video (100MB Máx)" type="file" accept=".mp4" innerRef={fileRef} onChange={analizeForm} />
									}

									<small>Tiempo de la lección</small>
									<DurationPicker
										onChange={(ev: any) => {
											setTimeD(ev);
										}}
										initialDuration={action.action.includes("edit") ? timeDef : { hours: 0, minutes: 10, seconds: 0 }}
									/>

									<div className="form-footer">
										<Button border className="mx-1" onClick={() => {
											setAction({ action: "lessons", isOpen: false, lesson: action.lesson });
										}}>Cancelar</Button>
										<Button solid disabled={!isValidForm} onClick={videoAdmin}>
											{action.action === "create-video" ? "Crear" : "Editar"}
										</Button>
									</div>
								</div>
								:
								<div className="form p-0">
									<div className="form-header">

									</div>

									<Input placeholder="Titulo" defaultValue={action.action.includes("edit") ? action.lesson.title : ""} innerRef={titleRef} onInput={quizzAnalizeform} />

									<InputRich placeholder="Descripcion" defaultValue={action.action.includes("edit") ? action.lesson.description : ""} innerRef={desRef} onInput={quizzAnalizeform} />

									{
										action.action === "create-quizz" ?
											<Quizz
												quizzId={null}
												quizz={null}
												adminQuizz={(form: any, solved: any, isEdit: boolean, quizzId: number | null, valid: boolean) => {
													setQuizzValues({ form: form, solved: solved, isEdit: isEdit, quizzId: quizzId, inUse: true, valid: valid });
												}} />
											:
											usePrev ?
												<div className="content-added">
													<span>Formulario <small>(Contenido previo)</small></span>
													<span color="blue" className="badge pointer" onClick={() => { setUsePrev(false) }}><i className="bi bi-x"></i></span>
												</div>
												:
												<Quizz
													quizzId={action.lesson.quizz_id}
													quizz={action.lesson as unknown as QuizzType}
													adminQuizz={(form: any, solved: any, isEdit: boolean, quizzId: number | null, valid: boolean) => {
														setQuizzValues({ form: form, solved: solved, isEdit: isEdit, quizzId: quizzId, inUse: true, valid: valid });
													}}

												/>
									}

									<small>Tiempo del cuestionario</small>
									<DurationPicker
										onChange={(ev: any) => {
											setTimeD(ev);
										}}
										initialDuration={action.action.includes("edit") ? timeDef : { hours: 0, minutes: 10, seconds: 0 }}
									/>
									<div className="form-footer">
										<Button border className="mx-1" onClick={() => {
											setAction({ action: "lessons", isOpen: false, lesson: action.lesson });
										}}>Cancelar</Button>
										<Button solid disabled={!isValidForm} onClick={quizzAdmin}>
											{action.action === "create-quizz" ? "Crear" : "Editar"}
										</Button>
									</div>
								</div>
						)
				)
		}
	</div>

}

const ModuleForm = (props: {
	type: "create" | "edit",
	isOpen: boolean,
	module: Module,
	course: Course,
	index: number,
	onReturn: () => any,
	onSave: (index: number, module: Module, type: "create" | "edit") => any
}) => {
	const user = isUserLogged();

	const [isValidForm, setIsValidForm] = useState(false);

	const titleRef = useRef({} as HTMLInputElement);
	const descriptionRef = useRef({} as HTMLInputElement);

	const [timeD, setTimeD] = useState({} as { hours: number, minutes: number, seconds: number });
	const [timeDef, setTimeDef] = useState({} as { hours: number, minutes: number, seconds: number });

	useEffect(() => {
	}, [isValidForm])

	useEffect(() => {
		try {
			let t = props.module.total_time.toString().split("-");
			setTimeDef({ hours: parseInt(t[0] ? t[0] : "0"), minutes: parseInt(t[1] ? t[1] : "0"), seconds: parseInt(t[2] ? t[2] : "0") });
			setTimeD({ hours: parseInt(t[0] ? t[0] : "0"), minutes: parseInt(t[1] ? t[1] : "0"), seconds: parseInt(t[2] ? t[2] : "0") });
		} catch (err) {
			setTimeDef({ hours: 0, minutes: 10, seconds: 0 });
			setTimeD({ hours: 0, minutes: 10, seconds: 0 });
		}
		console.debug(props.module.total_time)
	}, [props])

	const analizeForm = () => {
		if (
			titleRef.current.value.length > 0 &&
			descriptionRef.current.value.length > 0
		) {
			setIsValidForm(true);
		} else {
			setIsValidForm(false);
		}
	}

	const onSaveModule = () => {
		toast.loading((props.type === "create" ? "Se está creando" : "Se está editando").concat(" el módulo ").concat(titleRef.current.value))
		madePost(
			{
				key: props.type === "create" ? "add-module" : "edit-module",
				user: user?.email,
				token: getLoginToken(),
				course_code: props.course.course_code,
				course_name: props.course.title,
				title: titleRef.current.value,
				description: descriptionRef.current.value,
				total_time: timeD.hours + "-" + timeD.minutes + "-" + timeD.seconds,
				module_nro: props.type === "create" ? props.course.modules?.length : props.module.module_nro,
				module_code: props.type === "create" ? -1 : props.module.module_code
			},
			"manage.php"
		).then(r => {
			let res = r as ResponseGeneral;

			toast.dismiss();
			if (res.RESULT) {
				let mod = props.module;

				mod.module_title = titleRef.current.value;
				mod.module_description = descriptionRef.current.value;
				mod.total_time = timeD.hours + "-" + timeD.minutes + "-" + timeD.seconds;
				mod.module_nro = props.type === "create" ? props.course.modules?.length! : props.module.module_nro;
				mod.module_code = props.type === "create" ? res.NEW_MODULE_CODE : props.module.module_code;

				toast.success("El módulo se ha " + (props.type === "edit" ? "editado" : "creado") + " correctamente");
				props.onSave(props.index, mod, props.type)
			} else {
				toast.error(res.EXCEPTION_MESSAGE)
			}
		}).catch(onCatch)
	}

	console.debug(timeDef)

	return <div className="module-form form mt-0">
		<div className="form-header">

		</div>

		<Input placeholder="Titulo" disabled={props.type === "edit"} defaultValue={props.type === "edit" ? props.module.module_title : ""} innerRef={titleRef} onInput={analizeForm} />
		<Input placeholder="Descripcion" type="textarea" defaultValue={props.type === "edit" ? props.module.module_description : ""} innerRef={descriptionRef} onInput={analizeForm} />

		<small>Duración del módulo</small>
		<DurationPicker
			onChange={(ev: any) => {
				setTimeD(ev);
			}}
			initialDuration={props.type === "edit" ? timeDef : { hours: 0, minutes: 10, seconds: 0 }}
		/>

		<div className="form-footer">
			<Button border onClick={() => props.onReturn()} className="mx-1">Cancelar</Button>
			<Button solid disabled={!isValidForm} onClick={onSaveModule}>
				{props.type === "edit" ? "Editar" : "Crear"}
			</Button>
		</div>
	</div>
}

const ModulesFrom = (props: {
	isOpen: boolean,
	course: Course,
	onReturn: () => any
}) => {

	const [modules, setModules] = useState({ values: [] as Module[], isLoaded: false, modulesFrom: -1 });
	const [visibleModules, setVisibleModules] = useState({ modules: [] as Module[] });
	const [action, setAction] = useState({ isOpen: false, action: "edit" as "edit" | "create" | "admin", module: {} as Module, index: -1 });

	useEffect(() => {
		if (props.course.course_code !== modules.modulesFrom && modules.isLoaded) {
			setModules({ values: [] as Module[], isLoaded: false, modulesFrom: -1 });
		}

		if (!modules.isLoaded && props.isOpen) {
			madePost({
				key: "get-modules-from",
				course_code: props.course.course_code
			}, "manage.php"
			).then(r => {
				let res = r as ResponseGeneral;

				if (res.RESULT) {
					setModules({ values: res.VALUE, isLoaded: true, modulesFrom: props.course.course_code! });
				} else {
					toast.error(res.EXCEPTION_MESSAGE);
				}
			}).catch(onCatch)
		} else {
			//setModules({ values: [] as Module[], isLoaded: false });
		}
	}, [props, modules])

	useEffect(() => {
		setVisibleModules({ modules: modules.values });

	}, [modules])

	return <>
		<div className="modules-from-course form">
			<div className="form-header">

				<div className="title-with-button">
					<div>
						<h5 className="title-section left mb-1">
							Módulos disponibles del curso: {props.course.title}
						</h5>
						<p className="description-section mb-0">
							Gestiona, los módulos de este curso
						</p>
					</div>
					<div className="form-toolbar">
						<Button border onClick={() => {
							setTimeout(() => {
								setAction({ isOpen: false, action: "edit" as "edit" | "create" | "admin", module: {} as Module, index: -1 });
							}, 1000)
							props.onReturn()
						}}>
							<i className="bi bi-chevron-left"></i>
						</Button>

						<Button solid onClick={() => setAction({ isOpen: true, action: "create", module: {} as Module, index: modules.values.length })} >
							Crear módulo
						</Button>
					</div>
				</div>
			</div>
			{
				modules.isLoaded ?
					visibleModules.modules.map((module: Module, index: number) => {
						return <ModuleCard module={module}
							onEdit={(module: Module) => {
								setAction({ isOpen: true, action: "edit", module: module, index: index });
							}}
							onSelect={(module: Module) => {
								setVisibleModules({ modules: [module] });
								setAction({ isOpen: true, action: "admin", module: module, index: index })
							}} />
					})
					:
					<Loader />
			}
		</div>
		<Collapse isOpen={action.isOpen}>
			{
				action.action === "admin" ?
					<AdminModule
						module={action.module}
						isOpen={action.isOpen}
						course={props.course}
						onReturn={() => {
							setAction({ isOpen: false, action: action.action, module: {} as Module, index: action.index })
							setVisibleModules({ modules: modules.values });
						}}
					/>
					:
					<ModuleForm type={action.action} isOpen={action.isOpen}
						course={props.course}
						module={action.action === "create" ? {} as Module : action.module}
						onReturn={() => {
							setAction({ isOpen: false, action: "admin", module: action.module, index: action.index });
						}}
						index={action.index}
						onSave={function (index: number, module: Module, type: "create" | "edit") {

							if (type == "create") {
								setModules({ values: [...modules.values, module], isLoaded: modules.isLoaded, modulesFrom: modules.modulesFrom })
								setAction({ isOpen: false, action: action.action, index: action.index, module: action.module })
							} else {
								let cloned = cloneObject(modules.values);

								cloned[index] = module;
								setModules({ isLoaded: modules.isLoaded, modulesFrom: modules.modulesFrom, values: cloned });
								setAction({ isOpen: false, action: action.action, index: action.index, module: action.module })
							}
						}} />
			}
		</Collapse>
	</>
}

const CourseManagment = (props: {

}) => {

	const user: Organization = isUserLogged() as Organization;

	const [courses, setCourses] = useState({ values: [] as Course[], isLoaded: false });
	const [visibleCourses, setVisibleCourses] = useState([] as Course[]);

	const [courseAction, setCourseAction] = useState({ action: "" as "edit" | "create", isOpen: false, value: {} as Course });
	const [isCourseModules, setIsCourseModules] = useState({ isOpen: false, course: {} as Course });
	const [isEditingInstructors, setIsEditingInstructors] = useState({ isOpen: false, create: false });

	const getUserCourses = () => {
		setCourses({ values: [] as Course[], isLoaded: false });
		setCourseAction({ isOpen: false, action: courseAction.action, value: {} as Course });
		setIsCourseModules({ isOpen: false, course: {} as Course });
		madePost(
			{
				key: "get-courses",
				email: user.email,
				token: getLoginToken()
			},
			"manage.php"
		).then(r => {
			let res = r as ResponseGeneral;

			if (res.RESULT) {
				let coursesToUser = [] as Course[];
				res.VALUE.map((course: Course) => {
					if (!coursesToUser.find((c: Course) => c.course_code === course.course_code)) {
						coursesToUser.push(course)
					}
				})
				setCourses({ values: coursesToUser, isLoaded: true })
			} else {
				toast.error(res.EXCEPTION_MESSAGE)
			}

		}).catch(onCatch)
	}

	const onSelect = (course: Course) => {
		setVisibleCourses([course]);

	}
	const onEdit = (course: Course) => {
		setCourseAction({ action: "edit", isOpen: true, value: course });
		setIsCourseModules({ course: course, isOpen: false });
	}
	const onAdmin = (course: Course) => {
		setCourseAction({ action: courseAction.action, isOpen: false, value: course })
		setIsCourseModules({ course: course, isOpen: true });
	}

	const onEdited = (course: Course) => {
		toast.loading("Editando el curso '" + course.title + "'");
		madePost(
			{
				key: "edit-course",
				user: user.email,
				token: getLoginToken(),
				course_code: course.course_code,
				title: course.title,
				description: course.description,
				type: course.type,
				cover_image: course.cover_image,
				category: course.category,
				target_audience: course.target_audience,
				instructors: course.instructors
			},
			"manage.php"
		).then(r => {
			let res = r as ResponseCreateCourse;

			if (res.RESULT) {
				getUserCourses();
				toast.dismiss();

				toast.success("El módulo se ha editado correctamente");
			} else {
				toast.dismiss()

				toast.error(res.EXCEPTION_MESSAGE)
			}
		}).catch(onCatch)
	}
	const onCreated = (course: Course) => {
		toast.loading(("Creando el curso '" + course.title + "'"));
		madePost(
			{
				key: "add-course",
				user: user.email,
				token: getLoginToken(),
				title: course.title,
				description: course.description,
				type: course.type,
				cover_image: course.cover_image,
				category: course.category,
				target_audience: course.target_audience,
				instructors: course.instructors
			},
			"manage.php"
		).then(r => {
			let res = r as ResponseCreateCourse;

			if (res.RESULT) {
				toast.dismiss();

				getUserCourses();

				toast.success("El curso se ha creado correctamente");
			} else {
				toast.dismiss();

				toast.error(res.EXCEPTION_MESSAGE)
			}
		}).catch(onCatch)
	}

	useEffect(() => {
		if (isEditingInstructors.isOpen) {
			setVisibleCourses([] as Course[]);
		} else {
			if (courses.isLoaded) {
				setVisibleCourses(courses.values)
			}
		}
	}, [isEditingInstructors])

	useEffect(() => {
		getUserCourses()
	}, [props]);

	useEffect(() => {
		if (courses.isLoaded) {
			setVisibleCourses(courses.values);
		}
	}, [courses])

	return <div className="courses-manager contrast">

		<div className="form p-0" style={{ border: "none", backgroundColor: "transparent" }}>
			<div className="form-header">
				<div className="title-with-button">
					<div>
						<h5 className="title-section left mb-1">Gestiona tus cursos</h5>
						<p className="description-section"></p>
					</div>

					<div className="form-toolbar">
						<Button solid onClick={() => {
							setCourseAction({ isOpen: true, action: "create", value: {} as Course });
							setIsCourseModules({ isOpen: false, course: isCourseModules.course });
							setIsEditingInstructors({ isOpen: false, create: false });
						}}>
							<i className="bi bi-plus"></i>
							Crear un curso
						</Button>

						<Button border
							onClick={() => {
								setIsEditingInstructors({ isOpen: !isEditingInstructors.isOpen, create: false });
							}}>
							{isEditingInstructors.isOpen ? "Volver a los cursos" : "Gestionar tus instructores"}
						</Button>

						{
							isEditingInstructors.isOpen ?
								<Button border onClick={() => setIsEditingInstructors({ isOpen: true, create: true })}>
									Crear instructor
								</Button>
								:
								<></>
						}

						<Button border onClick={() => {
							getUserCourses();
						}}>
							<i className="bi bi-arrow-clockwise"></i>
							Recargar
						</Button>
					</div>
				</div>
			</div>
		</div>
		{
			courses.isLoaded ?
				courses.values.length > 0 ?
					visibleCourses.map((course: Course, index: number) => {
						return <CourseCard course={course} key={"course-" + index}
							onSelect={onSelect}
							onEdit={onEdit}
							onAdmin={onAdmin} />
					})
					:
					<Label>No tienes ningún curso creado todavía</Label>
				:
				<Loader />
		}

		<Collapse isOpen={courseAction.isOpen && !isEditingInstructors.isOpen}>
			<CourseForm
				type={courseAction.action}
				course={courseAction.value}
				onSave={(course: Course) => {
					if (courseAction.action === "edit") {
						onEdited(course)
					} else {
						onCreated(course);
					}

					setCourseAction({ action: courseAction.action, isOpen: false, value: {} as Course })
				}}
				onReturn={() => {
					setCourseAction({ action: courseAction.action, isOpen: false, value: {} as Course })
					setVisibleCourses(courses.values)
				}}
			/>
		</Collapse>

		<Collapse isOpen={isCourseModules.isOpen}>
			<ModulesFrom
				course={isCourseModules.course}
				onReturn={() => {
					setIsCourseModules({ isOpen: false, course: isCourseModules.course })
					setVisibleCourses(courses.values)
				}}
				isOpen={isCourseModules.isOpen} />
		</Collapse>

		<Collapse isOpen={isEditingInstructors.isOpen}>
			<Instructors create={isEditingInstructors.create} />
		</Collapse>

	</div>
}

export default CourseManagment;