import { useRef, useState } from "react";
import { Collapse } from "reactstrap";
import { Instructor } from "../functions/types";
import { restorePublicPath } from "../functions/user";
import Button from "./Button";
import Input from "./Input";

const InstructorCard = (props: {
	index: number,
	instructor: {
		organization_code: number,
		name?: string,
		last_name?: string,
		profession?: string,
		img?: any
	},
	noControls?: boolean,
	onAdd: (instructor: Instructor) => any,
	onRemove: (instructor: Instructor) => any,
	onEdit?: (instructor: Instructor) => any
}) => {
	const [isEdit, setIsEdit] = useState(false);
	const [isValidForm, setIsValidForm] = useState(false);

	const nameRef = useRef({} as HTMLInputElement);
	const lastNameRef = useRef({} as HTMLInputElement);
	const professionRef = useRef({} as HTMLInputElement);
	const imageRef = useRef({} as HTMLInputElement);

	const analizeForm = () => {
		if (nameRef.current.value.length > 0 &&
			lastNameRef.current.value.length > 0 &&
			professionRef.current.value.length > 0 &&
			imageRef.current.files!.length > 0
		) {
			setIsValidForm(true);
		} else {
			setIsValidForm(false);
		}
	}

	return <div className="card-instructor-course-manager course-card-manager" key={"card-instructor-" + props.index}>
		{
			props.noControls ?
				<></>
				:
				<div className="top-right">
					<i className="bi bi-x" onClick={() => { props.onRemove(props.instructor as Instructor) }}></i>
				</div>
		}

		{
			props.onEdit ?
				<div className="top-right">
					<i className="bi bi-pencil" onClick={() => { props.onEdit!(props.instructor as Instructor) }}></i>
				</div>
				:
				<></>
		}

		<div className="card-info-instructor">
			<img src={restorePublicPath()! + "instructors/" + props.instructor.name?.concat("-" + props.instructor.last_name).toLowerCase().replaceAll(" ", "-").concat(".jpeg")} onError={(ev: any) => {
				ev.target.src = "/assets/img/default_image.png";
			}} alt="Instructor" />
			<div>
				<span>{props.instructor.name}{" "}{props.instructor.last_name}</span>
				<p className="m-0">{props.instructor.profession}</p>
			</div>
		</div>
		<Collapse isOpen={isEdit}>
			<Input defaultValue={props.instructor.name} placeholder="Nombre" innerRef={nameRef} onInput={analizeForm} />
			<Input defaultValue={props.instructor.last_name} placeholder="Apellidos" innerRef={lastNameRef} onInput={analizeForm} />
			<Input defaultValue={props.instructor.profession} placeholder="Profesion" innerRef={professionRef} onInput={analizeForm} />
			<Input accept=".jpg, .jpeg, .png" placeholder="Fotografía" type="file" innerRef={imageRef} onChange={analizeForm} />
		</Collapse>
		{isEdit ?
			<Button className="mt-2" border disabled={!isValidForm} onClick={() => { }}>
				{"GUARDAR"}
			</Button>
			:
			<>
			</>}

	</div>
}

export default InstructorCard;