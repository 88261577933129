import { useEffect, useRef, useState } from "react";
import "../styles/components/ModalRegister.scss";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "./Button";
import Input from "./Input";
import post from "../functions/post";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import "react-datepicker/dist/react-datepicker.css";
import InputLocation from "./InputLocation";

import toast from 'react-hot-toast';
import Loader from "./Loader";
import { Organization, Person, ResponseCreateAccount } from "../functions/types";
import { setItem } from "../functions/useStorage";
import RadioButton from "./RadioButton";
import { Link } from "react-router-dom";
import { routes } from "../functions/routes";


let ModalRegister = (props: { open: boolean, onClose: () => any, onSuccessRegister: (user: Person | Organization) => any}) => {
  registerLocale("es", es);
  const [isPersonForm, setIsPersonForm] = useState(true);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [passwordEquals, setPasswordEquals] = useState(false);

  const [isValidPersonForm, setIsValidPersonForm] = useState(false);
  const [isValidOrgForm, setIsValidOrgForm] = useState(false);

  const [isValidPersonEmail, setIsValidPersonEmail] = useState(false);
  const [isValidOrgEmail, setIsValidOrgEmail] = useState(false);

  const [isSendingForm, setIsSendingForm] = useState(false);

  //Person Refs
  const nombrePersona = useRef({} as HTMLInputElement);
  const personNameRef = useRef({} as HTMLInputElement);
  const personEmailRef = useRef({} as HTMLInputElement);
  const personCellphoneRef = useRef({} as HTMLInputElement);
  const generoRef = useRef({} as HTMLSelectElement);
  const profesionRef = useRef({} as HTMLInputElement);
  const locationRef = useRef({} as HTMLInputElement);

  //Empresa Ref
  const nameRef = useRef({} as HTMLInputElement);
  const principalActivityRef = useRef({} as HTMLInputElement);
  const secondaryActivityRef = useRef({} as HTMLInputElement);
  const empresaLocationRef = useRef({} as HTMLInputElement);
  const websiteRef = useRef({} as HTMLInputElement);
  const logoRef = useRef({} as HTMLInputElement);
  const contactPersonNameRef = useRef({} as HTMLInputElement);
  const contactPersonLastNameRef = useRef({} as HTMLInputElement);
  const cellphoneRef = useRef({} as HTMLInputElement);
  const emailRef = useRef({} as HTMLInputElement);
  const positionRef = useRef({} as HTMLInputElement);

  //Contrasena
  const passRef = useRef({} as HTMLInputElement);

  //Policy
  const policyRef = useRef({} as HTMLInputElement);

  //Button
  const buttonSendRef = useRef({} as HTMLButtonElement);

  useEffect(() => {
    if (isPersonForm) {
      try {
        isValidPersonFormFun()
      } catch (e) { }
    } else {
      try {
        isValidOrgFormFun()
      } catch (e) { }
    }
  }, [passwordEquals, isValidOrgEmail, isValidPersonEmail, isPersonForm]);

  const createOrganizationAccount = () => {
    setIsSendingForm(true);

    let form = new FormData();

    form.append("name", nameRef.current.value);
    form.append("location", empresaLocationRef.current.value);
    form.append("principalActivity", principalActivityRef.current.value);
    form.append("secondaryActivity", secondaryActivityRef.current.value);

    if (logoRef.current.files!.length > 0) {
      form.append("logo", logoRef.current.files![0]);
    }

    form.append("website", websiteRef.current.value);
    form.append("contactPersonName", contactPersonNameRef.current.value);
    form.append("contactPersonLastName", contactPersonLastNameRef.current.value);
    form.append("cellphone", cellphoneRef.current.value);
    form.append("email", emailRef.current.value);
    form.append("position", positionRef.current.value);

    form.append("pass", passRef.current.value);

    form.append("key", "create-account")
    form.append("type", "organization");

    post("account.php", form, {}).then(r=>{
      let response = r as ResponseCreateAccount;
      
      if(response.RESULT){
        setIsSendingForm(false);
        toast.success("Tu cuenta ha sido creada satisfactoriamente");
        setItem("USER", (typeof response.USER == "string"? response.USER : JSON.stringify(response.USER)));
        setItem("TOKEN", response.TOKEN);

        props.onSuccessRegister(typeof response.USER == "string"? JSON.parse(response.USER) : response.USER);
      }else{
        setIsSendingForm(false);
        toast.error(response.EXCEPTION_MESSAGE);
      }
    }).catch(r=>{
      setIsSendingForm(false);
      toast.error("Ha ocurrido un error, porfavor intenta de nuevo mas tarde");
    })
  }

  const createPersonAccount = () => {
    setIsSendingForm(true);

    let form = new FormData();

    form.append("name", nombrePersona.current.value);
    form.append("lastName", personNameRef.current.value);
    form.append("email", personEmailRef.current.value);
    form.append("cellphone", personCellphoneRef.current.value);
    form.append("birthday", dateSelected.toISOString().slice(0, 19).replace('T', ' '));
    form.append("gender", generoRef.current.value);
    form.append("profession", profesionRef.current.value);
    form.append("location", locationRef.current.value);

    form.append("pass", passRef.current.value);

    form.append("key", "create-account")
    form.append("type", "person");

    post("account.php", form, {}).then(r=>{
      let response = r as ResponseCreateAccount;
      
      if(response.RESULT){
        setIsSendingForm(false);
        toast.success("Tu cuenta ha sido creada satisfactoriamente");
        setItem("USER", (typeof response.USER == "string"? response.USER : JSON.stringify(response.USER)));
        setItem("TOKEN", response.TOKEN);

        props.onSuccessRegister(typeof response.USER == "string"? JSON.parse(response.USER) : response.USER);
      }else{
        setIsSendingForm(false);
        toast.error(response.EXCEPTION_MESSAGE);
      }
    }).catch(r=>{
      setIsSendingForm(false);
      toast.error("Ha ocurrido un error, porfavor intenta de nuevo mas tarde");
    })
  }

  const isValidPersonFormFun = () => {
    try {
      if (nombrePersona.current.value.length > 0 &&
        personNameRef.current.value.length > 0 &&
        personEmailRef.current.value.length > 0 &&
        personCellphoneRef.current.value.length > 0 &&
        generoRef.current.value.length > 0 &&
        locationRef.current.value.length > 0 &&
        passRef.current.value.length > 0 
        && passwordEquals 
        && isValidPersonEmail
        && policyRef.current.checked) {
        setIsValidPersonForm(true);
      } else {
        setIsValidPersonForm(false);
      }
    } catch (e) {}
  }

  const isValidOrgFormFun = () => {
    try {
      if (nameRef.current.value.length > 0 &&
        empresaLocationRef.current.value.length > 0 &&
        principalActivityRef.current.value.length > 0 &&
        contactPersonNameRef.current.value.length > 0 &&
        contactPersonLastNameRef.current.value.length > 0 &&
        cellphoneRef.current.value.length > 0 &&
        positionRef.current.value.length > 0 &&
        passRef.current.value.length > 0 
        && passwordEquals 
        && isValidOrgEmail
        && policyRef.current.checked) {
        setIsValidOrgForm(true);
      } else {
        setIsValidOrgForm(false);
      }
    } catch (e) {  }
  }

  const controlFileLogo = (ev: any) => {
    if(ev.target.files.length > 0){
      if(ev.target.files[0].size >= 4194304){
        toast.error("La imagen no puede pesar más de 4MB");
        ev.target.value = null;
      }
    }
  }

  return <div className="modal-body">
    <Modal centered isOpen={props.open}>

      <ModalHeader onClick={() => { }}>

        <div className="login">
          <span>REGISTRARSE</span>
        </div>

        <div className="pointer close-button" onClick={() => {
          props.onClose();
        }}>
          <i className="bi bi-x"></i>
        </div>

      </ModalHeader>

      <ModalBody>
        {
          isPersonForm ? <>

            <Input onInput={isValidPersonFormFun} required hasBorder innerRef={nombrePersona} placeholder="Nombres" type="text" />
            <Input onInput={isValidPersonFormFun} required hasBorder innerRef={personNameRef} placeholder="Apellidos" type="text" />
            <Input onInput={isValidPersonFormFun} required hasBorder innerRef={personEmailRef} placeholder="Correo" type="email" name="email" isAValidEmail={(email: string, valid: boolean) => {
              setIsValidPersonEmail(valid)
            }} />
            <Input onInput={isValidPersonFormFun} required hasBorder innerRef={personCellphoneRef} placeholder="Número Celular" type="number" />

            <span><small>Fecha de Nacimiento <small className='text-danger'> *</small></small> </span>
            <DatePicker dateFormat={"dd/MM/yyyy"} locale={es} selected={dateSelected} onChange={(date: Date) => setDateSelected(date)} />

            <span><small>Género <small className='text-danger'> *</small></small> </span>
            <select name="" defaultValue={"Masculino"} ref={generoRef}>
              <option value="M">Masculino</option>
              <option value="F">Femenino</option>
              <option value="O">Otro</option>
            </select>

            <Input onInput={isValidPersonFormFun} hasBorder innerRef={profesionRef} placeholder="Profesión" type="text" />
            <InputLocation onInput={isValidPersonFormFun} required innerRef={locationRef} hasBorder />

          </> : <>

            <Input onInput={isValidOrgFormFun} required innerRef={nameRef} hasBorder placeholder="Nombre de la Empresa" type="text" />
            <Input onInput={isValidOrgFormFun} required innerRef={principalActivityRef} hasBorder placeholder="Actividad Principal" type="text" />
            <Input onInput={isValidOrgFormFun} innerRef={secondaryActivityRef} hasBorder placeholder="Actividad Secundaria" type="text" />
            <InputLocation onInput={isValidOrgFormFun} required innerRef={empresaLocationRef} hasBorder />

            <Input onInput={isValidOrgFormFun} innerRef={websiteRef} hasBorder placeholder="Página web" type="text" />

            <span><small>Logo <small className='text-danger'> *</small></small> </span>
            <input ref={logoRef} type="file" accept=".png, .jpg, .jpeg" onChange={controlFileLogo} />

            <Input onInput={isValidOrgFormFun} required innerRef={contactPersonNameRef} hasBorder placeholder="Nombres personal de Contacto" type="text" />
            <Input onInput={isValidOrgFormFun} required innerRef={contactPersonLastNameRef} hasBorder placeholder="Apellidos personal de Contacto" type="text" />
            <Input onInput={isValidOrgFormFun} required innerRef={cellphoneRef} hasBorder placeholder="Número Celular" type="number" />
            <Input onInput={isValidOrgFormFun} required innerRef={emailRef} hasBorder placeholder="Correo" type="email" name="email" isAValidEmail={(email: string, valid: boolean) => {
              setIsValidOrgEmail(valid)
            }} />
            <Input onInput={isValidOrgFormFun} required innerRef={positionRef} hasBorder placeholder="Cargo" type="text" />

          </>
        }

        <Input onInput={isPersonForm ? isValidPersonFormFun : isValidOrgFormFun} hasBorder required innerRef={passRef} placeholder="Contraseña" type="password" name="password" />
        <Input hasBorder placeholder="Repetir contraseña" title={passwordEquals ? "" : " · Las contraseñas no coinciden"} type="password" onInput={(ev: any) => {
          if (ev.target.value != passRef.current.value) {
            setPasswordEquals(false);
          } else {
            setPasswordEquals(true);
          }
        }} />

        <p onClick={() => {
          setIsPersonForm(!isPersonForm)
        }}
        className="mb-3"
        ><a href="#">{isPersonForm ? "Crear cuenta como organización" : "Crear cuenta personal"}</a></p>

        <RadioButton text={<p>Acepto la <Link to={routes.policy}>política de uso, privacidad y cookies</Link></p>} innerRef={policyRef} onChange={isPersonForm ? isValidPersonFormFun : isValidOrgFormFun}/>

        <Button innerRef={buttonSendRef} className="register" border disabled={!isSendingForm? (isPersonForm? !isValidPersonForm : !isValidOrgForm) : true} onClick={(isPersonForm ? createPersonAccount : createOrganizationAccount)}>{(isSendingForm? <Loader /> : "CREAR CUENTA")}</Button>

      </ModalBody>

      <ModalFooter>
        <br />
      </ModalFooter>

    </Modal>
  </div>
}
export default ModalRegister;