import "../styles/components/ModalLogin.scss";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "./Button";
import Input from "./Input";
import { useRef, useState } from "react";
import post, { madePost } from "../functions/post";
import { Organization, Person, ResponseGeneral, ResponseLogin } from "../functions/types";

import toast from 'react-hot-toast';
import Loader from "./Loader";
import { setItem } from "../functions/useStorage";
import { errorMessagePost } from "../functions/variables";

let ModalLogin = (props: {
  open: boolean,
  onClose: () => any,
  onRegister: () => any,
  onSuccesLogin: (user: Person | Organization) => any
}) => {

  const [isValidForm, setIsValidForm] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [forgotPassword, setForgotPassword] = useState({open: false, validForm: false});

  const correoRef = useRef({} as HTMLInputElement);
  const passRef = useRef({} as HTMLInputElement);

  const analizeForm = () => {
    if (correoRef.current.value.length > 0 && passRef.current.value.length > 0) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
  }

  const sendForm = () => {
    let form = new FormData();

    form.append("key", "login-account");
    form.append("email", correoRef.current.value);
    form.append("pass", passRef.current.value);

    setIsSendingForm(true);

    post("account.php", form, {}).then(r => {
      let response = r as ResponseLogin;

      setIsSendingForm(false);

      if (response.RESULT) {
        toast.success("Has iniciado sesion correctamente");
        props.onSuccesLogin(response.USER);

        setItem("USER", (typeof response.USER == "string" ? response.USER : JSON.stringify(response.USER)));
        setItem("TOKEN", response.TOKEN);
      } else {
        toast.error(response.EXCEPTION_MESSAGE);
      }
    }).catch(err => {
      setIsSendingForm(false);
      toast.error("Ha ocurrido un error, porfavor intentalo nuevamente más tarde");
    })
  }

  const alterPass = () => {
    madePost(
      {
        key: "recovery-pass",
        email: correoRef.current.value
      },
      "account.php"
    ).then(r=>{
      let res = r as ResponseGeneral;

      if(res.RESULT){
        toast.success("Se te ha enviado un correo con instrucciones para recuperar tu cuenta, revisa tambien tu bandeja de spam");
        setForgotPassword({open: false, validForm: false})
      }else{
        toast.error("El correo proporcionado no tiene una cuenta activa, revisalo e intenta nuevamente");
      }
    }).catch(er=>{
      toast.error(errorMessagePost);
    })
  }

  return <div className="modal-body">
    <Modal centered isOpen={props.open}>

      {
        !forgotPassword.open ?
          <>
            <ModalHeader onClick={() => { }}>

              <div className="login">
                <span>INICIAR SESIÓN</span>
              </div>

              <div className="close-button" onClick={() => {
                props.onClose();
              }}>
                <i className="bi bi-x"></i>
              </div>

            </ModalHeader>

            <ModalBody>
              <Input hasBorder onInput={analizeForm} innerRef={correoRef} placeholder="Correo o celular" id="" type="text" name="email" />
              <Input hasBorder onInput={analizeForm} innerRef={passRef} placeholder="Contraseña" id="pass" type="password" name="password" />
              <p><a href="#" onClick={()=>setForgotPassword({open: true, validForm: false})}> Olvidaste tu Contraseña</a></p>

              <Button className="login" solid disabled={isSendingForm ? true : !isValidForm} onClick={sendForm} >{isSendingForm ? <Loader /> : "INGRESAR"}</Button>
              <Button className="register" border={true} onClick={() => {
                props.onRegister();
              }} >CREAR CUENTA</Button>

            </ModalBody>
          </>
          :
          <>
            <ModalHeader onClick={() => { }}>

              <div className="login">
                <span>RECUPERAR CUENTA</span>
              </div>

              <div className="close-button" onClick={() => {
                props.onClose();
              }}>
                <i className="bi bi-x"></i>
              </div>

            </ModalHeader>

            <ModalBody>
              <Input hasBorder innerRef={correoRef} placeholder="Correo electrónico de la cuenta" id="" type="email" isAValidEmail={(email, valid)=>{
                  setForgotPassword({open: true, validForm: valid});
              }} />
              <p><a href="#" onClick={()=>setForgotPassword({open: false, validForm: false})}> Iniciar sesión</a></p>

              <Button className="register" disabled={!forgotPassword.validForm} border={true} onClick={() => {
                  alterPass();
              }} >RECUPERAR CONTRASEÑA</Button>

            </ModalBody>
          </>
      }

    </Modal>
  </div>
}
export default ModalLogin;

