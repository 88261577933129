import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../functions/routes";

import "../styles/components/Header.scss";

import "bootstrap/dist/js/bootstrap.min.js"
import Button from "./Button";
import ModalLogin from "./ModalLogin";
import ModalRegister from "./ModalRegister";
import { isUserLogged } from "../functions/user";
import { isset } from "../functions/functions";
import { Organization, Person } from "../functions/types";
import ModalComplete from "./ModalComplete";

let Header = (
    props: {
        isOpen?: boolean,
        onClose?: () => any,
        updateInfo?: boolean
    }
) => {
    const [openLogin, setOpenLogin] = useState(props.isOpen ? props.isOpen : false)
    const [openRegister, setOpenRegister] = useState(false);
    const [isCompleteInfo, setIsCompleteInfo] = useState({ open: false, ucode: -1, email: "", action: "" as "edit" | "complete" });

    const [isLogged, setIsLogged] = useState(isset(isUserLogged()) ? true : false);
    const [user, setUser] = useState(isset(isUserLogged()) ? isUserLogged() : null);

    const navigate = useNavigate();

    let actualPath = window.location.pathname;

    let collapseRef = useRef({} as HTMLDivElement);

    useEffect(() => {
        if (isLogged && isset(user)) {
            if (user!.name == "N/N" || props.updateInfo) {
                if (isCompleteInfo.ucode == -1 || isCompleteInfo.email == "") {
                    setIsCompleteInfo({ open: true, ucode: ((user as any).user_code), email: user!.email, action: (props.updateInfo ? "edit" : "complete") })
                } else {
                    setIsCompleteInfo({ open: true, ucode: isCompleteInfo.ucode, email: isCompleteInfo.email, action: (props.updateInfo ? "edit" : "complete") })
                }
            }
        }
    }, [isLogged, props.updateInfo])

    useLayoutEffect(() => {
        if (props.isOpen) {
            setOpenLogin(props.isOpen)
        }
    }, [props])

    useEffect(() => {

    }, [user])

    try {
        return <header className="header">
            <div>
                <a href={routes.home} className="logo-left">
                    <img src="/assets/img/CreamosJuntosLogo.png" alt="Fundación Creamos Juntos Logotipo" />
                </a>

                <div className="d-flex flex-row align-items-center">
                    <div className="menu-header-desktop">
                        <ul >

                            <li className={actualPath === routes.home ? "active" : ""}>
                                <Link to={routes.home}>Inicio</Link>
                                <div></div>
                            </li>
                            <li className={actualPath === routes.courses ? "active" : ""}>
                                <Link to={routes.courses}>Cursos</Link>
                                <div></div>
                            </li>
                            <li className={actualPath === routes.us ? "active" : ""}>
                                <Link to={routes.us}>Nosotros</Link>
                                <div></div>
                            </li>
                            <li className={actualPath === routes.contacts ? "active" : ""}>
                                <Link to={routes.contacts}>Contactos</Link>
                                <div></div>
                            </li>

                        </ul>
                    </div>


                    <div className="login-area">
                        {!isLogged ?
                            <>
                                <Button onClick={() => {
                                    setOpenLogin(true)
                                }} border={false}>
                                    INICIAR SESIÓN
                                </Button>
                                <Button onClick={() => {
                                    setOpenRegister(true)
                                }} solid>
                                    REGISTRATE
                                </Button>
                            </>
                            :
                            <>
                                <Button id="ButtonProfile" solid
                                    className="logged-char"
                                    tooltip="Tu perfil"
                                    onClick={() => {
                                        navigate(routes.profile);
                                    }}>{isset(user) ? user!.name.charAt(0) : "P"}

                                </Button>
                            </>
                        }
                    </div>
                    <button type="button" data-bs-toggle="collapse" data-bs-target="#menu-header" aria-expanded="true" className="btn btn-collapse">
                        <i className="bi bi-list"></i>
                    </button>
                </div>


            </div>

            <div className="menu-header collapse" id="menu-header" ref={collapseRef}>
                <ul >

                    <li className={actualPath === routes.home ? "active" : ""}>
                        <Link to={routes.home}>Inicio</Link>
                        <div></div>
                    </li>
                    <li className={actualPath === routes.courses ? "active" : ""}>
                        <Link to={routes.courses}>Cursos</Link>
                        <div></div>
                    </li>
                    <li className={actualPath === routes.us ? "active" : ""}>
                        <Link to={routes.us}>Nosotros</Link>
                        <div></div>
                    </li>
                    <li className={actualPath === routes.contacts ? "active" : ""}>
                        <Link to={routes.contacts}>Contactos</Link>
                        <div></div>
                    </li>

                </ul>
                <ModalLogin onRegister={() => {
                    setOpenLogin(false)
                    setOpenRegister(true)
                }} onClose={() => {
                    setOpenLogin(false);
                    (document.querySelector('body') as HTMLBodyElement).style.overflow = "auto";
                    (document.querySelector('body') as HTMLBodyElement).style.padding = "0";

                    if (props.onClose) {
                        props.onClose();
                    }
                }} onSuccesLogin={(user: Person | Organization) => {
                    setOpenLogin(false);
                    setIsLogged(true);
                    setUser(user);

                    if (user.name == "N/N") {
                        setIsCompleteInfo({ open: true, ucode: (user as any).user_code, email: user.email, action: "complete" });
                    }

                    (document.querySelector('body') as HTMLBodyElement).style.overflow = "auto";
                    (document.querySelector('body') as HTMLBodyElement).style.padding = "0";
                }}
                    open={openLogin}
                />

                <ModalRegister open={openRegister} onClose={() => {
                    setOpenRegister(false);

                    (document.querySelector('body') as HTMLBodyElement).style.overflow = "auto";
                    (document.querySelector('body') as HTMLBodyElement).style.padding = "0";
                }} onSuccessRegister={(user: Person | Organization) => {
                    setOpenRegister(false);
                    setIsLogged(true);
                    setUser(user);

                    (document.querySelector('body') as HTMLBodyElement).style.overflow = "auto";
                    (document.querySelector('body') as HTMLBodyElement).style.padding = "0";
                }} />

                <ModalComplete action={isCompleteInfo.action}
                    user={{ email: isCompleteInfo.email, ucode: isCompleteInfo.ucode, all: { user: user as Person } }}
                    open={isCompleteInfo.open}
                    onComplete={(user: Person) => {
                        (document.querySelector('body') as HTMLBodyElement).style.overflow = "auto";
                        (document.querySelector('body') as HTMLBodyElement).style.padding = "0";

                        setIsCompleteInfo({ email: isCompleteInfo.email, ucode: isCompleteInfo.ucode, open: false, action: "" as any })
                    }} 
                    onCancel={() => {
                        setIsCompleteInfo({ email: isCompleteInfo.email, ucode: isCompleteInfo.ucode, open: false, action: "" as any })
                    }} />

            </div>
        </header >
    } catch (err) {
        setUser(null);

        return <></>
    }
}
export default Header;