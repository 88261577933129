import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import Header from "../components/Header"
import "../styles/components/AboutUs.scss"

let AboutUs = () => {

    return <>
        <Header />
        <Helmet>
            <title>Acerca de nosotros | Fundación Creamos Juntos</title>
            <meta charSet="utf-8" />
            <link rel="canonical" href={window.location.href} />
            <meta name="description" content="Acerca de nosotros, quienes somos, misión y visión de Fundación Creamos Juntos Bolivia" ></meta>
        </Helmet>
        <div id="aboutus" />
        <section className="content-about-us contrast">
            <h5 className="title-section">Sobre Nosotros</h5>

            <div className="side-to-side">
                <article>
                    <p>
                        La Fundación Creamos Juntos, es una organización sin fines de lucro, legalmente establecida,
                        enfocada en la educación. Con un objetivo principal de recrear la manera de generar
                        conciencia y motivación para poder fortalecer la educación
                    </p>
                </article>

                <article>
                    <picture>
                        <img src="../../assets/img/CreamosJuntosLogo.png" className="fit-contain" />
                    </picture>
                </article>
            </div>
        </section>
        <br />



        <section>
            <div className="side-to-side">
                <article id="mision">
                    <h5 className="title-section">Misión</h5>
                    <p>Educar, concientizar y motivar a nuestra sociedad, fortaleciendo y desarrollando
                        habilidades de empatía con la educación a distancia</p>
                </article>

                <article id="vision">
                    <h5 className="title-section">Visión</h5>
                    <p>
                        Promover capacitaciones a nuestra sociedad, que generen concientización sobre nuestra
                        relación con el medio ambiente, las nuevas tecnologías y el empoderamiento personal. Promover
                        actividades y trabajar para el desarrollo de agregar valor a la sociedad
                    </p>
                </article>
            </div>

        </section>

        <Footer />
    </>
}
export default AboutUs