import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomSwiper from "../components/CustomSwiper";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NewsLetter from "../components/NewsLetter";
import { isset } from "../functions/functions";
import { getTo } from "../functions/post";
import { routes } from "../functions/routes";
import { youtubeAPIItemResponse, youtubeAPIResponse } from "../functions/types";

import "../styles/pages/Home.scss";
import { getItem, setItem } from "../functions/useStorage";
import { Helmet } from "react-helmet";

import Card from "../components/Card";
import LiteYouTubeEmbed from "react-lite-youtube-embed";

let Home = () => {

    let navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname === routes.root) {
            navigate(routes.home);
        }
    }, [])

    let SupSection = () => {
        return <nav id="sup-section-home" className="sup-section-home">
            <picture>
                <img src="/assets/img/bg-image-home.jpg" alt="" />
            </picture>

            <div className="sup-section-text">
                <h3>FUNDACIÓN</h3>
                <h1>CREAMOS JUNTOS</h1>
                {
                    /*
                        <span>y el proyecto</span>
                <h5>ReCrea</h5>
                    */
                }

                <p>
                    Recreando tu manera de aprender, buscamos generar conciencia y motivación para poder fortalecer la educación
                </p>
            </div>
        </nav>
    }

    const SkillsSupHome = () => {
        return <section className="recrea-skills">
            <div>
                <div>
                    <picture>
                        <img src="/assets/img/education.png" alt="" />
                    </picture>

                    <span>EDUCACIÓN</span>

                    <p>
                        La Fundación tiene por objeto principal promover, fomentar y fortalecer la cultura de la educación dentro de nuestra sociedad, desrrollando habilidades, destrezas y competencias en la educación en línea
                    </p>
                </div>

                <div className="in-middle-skills"></div>

                <div>
                    <picture>
                        <img src="/assets/img/tree.png" alt="" />
                    </picture>

                    <span /*---browser acerca de nosotros---*/ id="acerca-de-nosotros-inicio">MEDIO AMBIENTE</span>

                    <p>
                        Con una visión de impulsar y capacitar a la población a poner en práctica la separación de residuos desde casa, comenzando por la educación, sensibilización para generar hábitos dentro de nuestra sociedad
                    </p>
                </div>
            </div>
        </section>
    }

    const AboutUs = () => {
        return <section id="about-us-home" className="about-us-home">
            <h5 className="title-section" >ACERCA DE NOSOTROS</h5>

            <div className="side-to-side">
                <article>
                    <p /*---browser proyectos---*/ id="proyectos-inicio">
                        Somos una organización enfocada en la acción educativa y medioambiental, de joven a joven, rescatando la propuesta de la educación popular que, recupera, respeta, critica, autocrítica y reconoce el aporte de saberes de los y las jóvenes. Pretendemos desarrollar habilidades, destrezas y competencias de los y las jóvenes en base a la experiencia, al conocimiento y la propuesta.
                        Esto para fortalecer el proceso de responsabilidad familiar, laboral, política y social. Motivando de esta manera la participación activa de la vida social, y rescatar la actoría social de los y las jóvenes.
                    </p>
                </article>
                <article>
                    <picture>
                        <img src="/assets/img/child-learning.png" alt="" />
                    </picture>
                </article>
            </div>
        </section>
    }

    const YoutubeVideosFromReCrea = () => {
        const API_key_yt = "AIzaSyBRX4KELvkJA-_uEFg9nzsQkaRSDfQ6AHY";
        const ReCrea_channel_id = "UCZhxp1V_RvN-aR9E8ViiDEA";
        const API_yt_allvideos = `https://www.googleapis.com/youtube/v3/search?key=${API_key_yt}&channelId=${ReCrea_channel_id}&part=snippet,id&order=date&maxResults=20`;

        const localStorageName = "youtube-videos-recrea";

        const [videos, setVideos] = useState([] as youtubeAPIItemResponse[])

        useEffect(() => {
            let storageItems = getItem(localStorageName);
            if (isset(storageItems)) {
                setVideos(JSON.parse(storageItems!))
            } else {
                getTo(API_yt_allvideos).then((r: unknown) => {
                    const youtubeResponse = r as youtubeAPIResponse;
                    setVideos(youtubeResponse.items);

                    setItem(localStorageName, JSON.stringify(youtubeResponse.items));
                })
            }
        }, [])

        return <div className="videos-home subsection">
            <CustomSwiper id="home" title="VIDEOS" elements={videos.length > 0 ?
                videos.filter(el => isset(el.id.videoId)).map((element: youtubeAPIItemResponse, index: number) => {

                    return <Card
                        head={
                            <>
                                <LiteYouTubeEmbed
                                    key={"video-recrea-" + index}
                                    id={element.id.videoId}
                                    title={element.snippet.title}
                                    iframeClass="iframe"
                                    params=""
                                    />
                                {/*
                            <iframe key={"video-recrea-" + index} src={"https://www.youtube.com/embed/" + element.id.videoId} title={element.snippet.title} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            */}
                            </>

                        }
                        author={{ logo: "/assets/img/ReCreaLogo.png", name: "ReCrea Bolivia" }}
                        title={element.snippet.title}
                        type="VIDEO"
                        mini
                    />
                })
                :
                [<></>]} />
        </div>
    }

    const Proyects = () => {

        return <section id="proyects-home" className="proyects-home contrast">
            <h5 className="title-section">PROYECTOS</h5>

            <div className="side-to-side center align-items-start mb-4">
                <article>
                    <p /*---browser libros---*/ id="libros-inicio">
                        <span><a href="https://recreabolivia.org">Re.Crea</a></span><br />
                        Nace a partir de la situación actual que afronta la población en general con una tasa de separación
                        de residuos muy baja, es así que la visión es impulsar y capacitar a la población a poner en práctica
                        la separación de residuos, comenzando por la educación y sensibilización para generar un cambio
                        de hábitos y una transformación en la sociedad desde los más pequeños en la casa
                    </p>
                </article>
                {
                    /*
                    
                    <article className="d-flex  flex-row justify-content-center justify-content-md-start">
                    <picture className="fit-contain w-75 d-flex flex-row justify-content-center justify-content-md-start">
                        <source srcSet="/assets/img/ReCreaLogo.png" />
                        <img src="/assets/img/ReCreaLogo.png" alt="" />
                    </picture>
                </article>

                    */
                }
            </div>

            <div className="side-to-side center align-items-start mb-6">
                <article>
                    <p /*---browser libros---*/ id="libros-inicio">
                        <span>Crecemos Juntos</span><br />
                        Actualmente contamos con una apertura y entusiasmo por la educación en línea, por lo tanto se
                        debe fortalecer el ingreso a la digitalización a través de capacitaciones con herramientas prácticas,
                        versátiles que apunten a un empoderamiento y crecimiento dentro de cada hogar
                    </p>
                </article>
            </div>

            <div className="subsection">
                <h6 className="title-subsection">NUESTROS LIBROS EDUCATIVOS</h6>
                <article>
                    <div className="books-home ">
                        <Card
                            head={<iframe src="https://drive.google.com/file/d/1mqwM8KcsfTyc-Y-OXux-jhkQgMNaUIyi/preview" width="640" height="480" allow="autoplay"></iframe>}
                            author={{ logo: "/assets/img/ReCreaLogo.png", name: "ReCrea Bolivia" }}
                            title="Reciclando desde casa"
                            description="Manual de educación ambiental sobre reciclaje para niñas y niños"
                            type="Libro"

                            center

                            style={{ marginRight: ".5rem" }}
                        />

                        <Card
                            head={<iframe src="https://drive.google.com/file/d/1UGh5lmFgp7KdC3z4x6psp9k_gfCxyhSy/preview" width="640" height="480" allow="autoplay"></iframe>}
                            author={{ logo: "/assets/img/ReCreaLogo.png", name: "ReCrea Bolivia" }}
                            title="Bioseguridad para personas que recolectan residuos"
                            description="Guía para las medidas de bioseguridad y prevención de riesgos para personas que recolectan residuos"
                            type="Libro"

                            center

                            style={{ marginLeft: ".5rem" }}
                        />
                    </div>
                    <div  /*---browser videos---*/ id="videos-inicio" />

                </article>
            </div>
            <div id="videos-inicio"></div>
            <YoutubeVideosFromReCrea />
        </section>
    }

    return <div className="home-page">
        <Header />

        <Helmet>
            <title>Inicio | Fundación Creamos Juntos</title>
            <meta charSet="utf-8" />
            <link rel="canonical" href={window.location.href} />
            <meta name="description" content={"Conoce más acerca de nosotros, nuestros cursos, proyectos, videos y más sobre la Fundación Creamos Juntos"} ></meta>
        </Helmet>

        <SupSection />

        <SkillsSupHome />

        {/* <AboutUs /> */}

        <Proyects />

        <NewsLetter />

        <Footer />
    </div>
}
export default Home;