import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Collapse } from "reactstrap";
import Button from "../../components/Button";
import Input from "../../components/Input";
import InstructorCard from "../../components/InstructorCard";
import Loader from "../../components/Loader";
import { cloneObject } from "../../functions/functions";
import { madePost } from "../../functions/post";
import { Instructor, ResponseGeneral } from "../../functions/types";
import { getLoginToken, isUserLogged, restorePublicPath } from "../../functions/user";
import { onCatch } from "../../functions/variables";

const Instructors = (props: {
    create: boolean
}) => {

    const user = isUserLogged();

    const [availableInstructors, setAvailableInstructors] = useState({ instructors: [] as Instructor[], isLoaded: false })
    const [isEditing, setIsEditing] = useState({ isOpen: props.create ? true : false, action: (props.create ? "create" : "edit") as "create" | "edit", instructor: {} as Instructor });
    const [isValidForm, setIsValidForm] = useState(false);

    const nameRef = useRef({} as HTMLInputElement);
    const lastNameRef = useRef({} as HTMLInputElement);
    const professionRef = useRef({} as HTMLInputElement);
    const descriptionRef = useRef({} as HTMLInputElement);
    const fileRef = useRef({} as HTMLInputElement);

    useEffect(() => {
        if (!availableInstructors.isLoaded) {
            madePost(
                {
                    key: "get-organization-instructors",
                    user: user?.email,
                    token: getLoginToken()
                },
                "manage.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    setAvailableInstructors({ instructors: res.VALUE, isLoaded: true });
                } else {
                    toast.error(res.EXCEPTION_MESSAGE);
                }
            }).catch(onCatch)
        }
    }, [availableInstructors])

    useEffect(() => {
        analizeForm();

        setIsEditing({ isOpen: props.create, action: props.create ? "create" : "edit", instructor: {} as Instructor })
    }, [props])

    const onSave = () => {
        setIsValidForm(false);

        toast.loading(isEditing.action === "create"? "Se está creando el instructor" : "Se está editando el instructor")

        madePost(
            {
                key: "manage-instructor",
                type: isEditing.action,
                instructor_code: isEditing.action === "create" ? 0 : isEditing.instructor.instructor_code,

                email: user?.email,
                token: getLoginToken(),

                name: nameRef.current.value,
                last_name: lastNameRef.current.value,
                profession: professionRef.current.value,
                description: descriptionRef.current.value,

                image: fileRef.current.files![0]
            },
            "manage.php"
        ).then(r => {
            let res = r as ResponseGeneral;
            
            toast.dismiss()

            if (res.RESULT) {
                let newInstructor = {} as Instructor;

                newInstructor.name = nameRef.current.value;
                newInstructor.last_name = lastNameRef.current.value;
                newInstructor.description = descriptionRef.current.value;
                newInstructor.profession = professionRef.current.value;
                newInstructor.image = restorePublicPath()! + "instructors/" + nameRef.current.value.concat("-" + lastNameRef.current.value).toLowerCase().replaceAll(" ", "-").concat(".jpeg")

                if(isEditing.action === "create"){
                    setAvailableInstructors({ instructors: [...availableInstructors.instructors, newInstructor], isLoaded: availableInstructors.isLoaded });
                }else{
                    let cloned: Instructor[] = cloneObject(availableInstructors.instructors);
                    let index = cloned.findIndex((ins: Instructor)=>{
                        return ins.instructor_code === isEditing.instructor.instructor_code;
                    });
                    cloned[index] = newInstructor;

                    setAvailableInstructors({ instructors: cloned, isLoaded: availableInstructors.isLoaded });
                }

                setIsEditing({ isOpen: false, action: isEditing.action, instructor: {} as Instructor })
                toast.success("Instructor "+(isEditing.action == "edit"? "editado" : "creado")+" correctamente");
            } else {
                toast.error(res.EXCEPTION_MESSAGE)
            }
        }).catch(onCatch)
    }

    const analizeForm = () => {
        try {
            if (
                nameRef.current.value.length > 0 &&
                lastNameRef.current.value.length > 0 &&
                professionRef.current.value.length > 0 &&
                descriptionRef.current.value.length > 0 &&
                fileRef.current.files!.length > 0
            ) {
                setIsValidForm(true);
            } else {
                setIsValidForm(false);
            }
        } catch (ex) {

        }
    }

    return <div className="instructors-page">
        <div className="instructors-list">
            {
                availableInstructors.isLoaded ?
                    availableInstructors.instructors.map((instructor: Instructor, index: number) => {
                        return <InstructorCard index={index} instructor={instructor}
                            onAdd={() => { }}
                            onRemove={() => { }}
                            onEdit={(instructor: Instructor)=>{
                                setIsEditing({isOpen: true, action: "edit", instructor: instructor});
                            }}
                            noControls
                        />
                    })
                    :
                    <Loader />
            }
        </div>

        <Collapse isOpen={isEditing.isOpen}>
            <div className="form form-instructor">
                <div className="form-header">
                    <h5>Crea un instructor</h5>
                    <p>El instructor que crees estará disponible cuando lo asignes a un curso nuevo</p>
                </div>

                <Input placeholder="Nombre" innerRef={nameRef}
                    onInput={analizeForm}
                    defaultValue={isEditing.action == "edit" ? isEditing.instructor.name : ""} />
                <Input placeholder="Apellidos" innerRef={lastNameRef}
                    onInput={analizeForm}
                    defaultValue={isEditing.action == "edit" ? isEditing.instructor.last_name : ""} />
                <Input placeholder="Profesion" innerRef={professionRef}
                    onInput={analizeForm}
                    defaultValue={isEditing.action == "edit" ? isEditing.instructor.profession : ""} />
                <Input placeholder="Descripción breve" innerRef={descriptionRef}
                    onInput={analizeForm}
                    defaultValue={isEditing.action == "edit" ? isEditing.instructor.description : ""}
                    type="textarea"
                />

                <Input placeholder="Fotografía" innerRef={fileRef}
                    onChange={analizeForm} type="file" accept=".png, .jpg, .jpeg"
                />

                <div className="form-footer">
                    <Button border className="mx-1"
                        onClick={() => {
                            setIsEditing({ isOpen: false, action: isEditing.action, instructor: isEditing.instructor })
                        }}>Cancelar</Button>
                    <Button solid disabled={!isValidForm} onClick={onSave}>{isEditing.action == "create" ? "Crear" : "Actualizar"}</Button>
                </div>
            </div>
        </Collapse>
    </div>
}
export default Instructors;