import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { isset } from "../../functions/functions";
import { madePost } from "../../functions/post";
import { Organization, Person, ResponseGeneral } from "../../functions/types";
import { getLoginToken } from "../../functions/user";
import { getItem, setItem } from "../../functions/useStorage";
import { errorMessagePost, homePublic } from "../../functions/variables";


import "../../styles/pages/Profile.scss";

const Info = (props: { user: Person & Organization, updateInfo: () => any }) => {
    let fecha = new Date(props.user.birthday)

    const [actualImage, setActualmage] = useState(
        (
            isset(props.user.organization_code) ?
                (homePublic + "organizations/" + props.user.name.toLowerCase().replaceAll(" ", "") + "/" + props.user.name.toLowerCase().replaceAll(" ", "") + ".jpeg")
                :
                (homePublic + "profiles/" + (props.user.name + "-" + props.user.last_name + "-" + props.user.user_code).toLowerCase().replaceAll(" ", "-") + ".jpeg")
        ) as any);

    const [modal, setModal] = useState({ open: false, action: "report" });
    const [isValidForm, setIsValidForm] = useState(false);

    const imageRef = useRef({} as HTMLInputElement);
    const titRef = useRef({} as HTMLInputElement);
    const descRef = useRef({} as HTMLTextAreaElement);

    const changeProfileImage = (ev: any) => {
        if (ev.target.files.length > 0) {

            if (ev.target.files[0].size <= 4194304) {
                let code = isset(props.user.organization_code) ? props.user.organization_code : props.user.user_code;
                toast.promise(
                    madePost(
                        {
                            key: "update-profile-image",
                            new_image: ev.target.files[0],
                            user_code: code,
                            type: isset(props.user.organization_code) ? "organization" : "person",
                            token: getLoginToken()
                        },
                        "media/image.php"
                    )
                    , {
                        loading: "Cambiando tu fotografia de perfil",
                        success: (r: unknown) => {
                            let res = r as ResponseGeneral;

                            if (res.RESULT) {
                                try {
                                    var reader = new FileReader();
                                    reader.onload = function (event) {
                                        setActualmage(event.target!.result);
                                    };
                                    reader.readAsDataURL(ev.target.files[0]);
                                } catch (err) {
                                    console.log("Error reading image");
                                }
                                return <>Perfil actualizado</>;
                            } else {

                                throw new Error("Perfil no actualizado, prueba a iniciar sesion nuevamente");
                                return <>Perfil no Actualizado, prueba a iniciar sesion nuevamente</>
                            }
                            return "";
                        },
                        error: (r: unknown) => {
                            let res = r as any;
                            return <>Ha ocurrido un error, porfavor inicia sesión nuevamente o intenta con otra imagen</>;
                        },
                    })
            } else {
                toast.error("La imagen no puede pesar más de 4MB");
            }

        } else {
            toast.error("No has seleccionado ninguna imagen");
        }
    }

    const analizeForm = () => {
        if (titRef.current.value.length > 0 &&
            descRef.current.value.length > 0) {
            setIsValidForm(true);
        } else {
            setIsValidForm(false);
        }
    }

    const sendReport = () => {
        madePost(
            {
                key: "report-problem",
                email: props.user.email,
                title: titRef.current.value,
                description: descRef.current.value
            },
            "common.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast.success("Gracias por reportarnos la situción, nos comunicaremos contigo a la prontitud");
                setModal({ open: false, action: "" });
            } else {
                toast.error(errorMessagePost)
            }
        }).catch(err => {
            toast.error(errorMessagePost)
        })
    }

    return <>
        {/**    CODIGO HTML DE PERFIL AQUI - TU INFORMACION  */}
        <div className="profile-content our-info">
            <img className="img-fondo" src="https://img.freepik.com/foto-gratis/fondo-acuarela-pintada-mano-forma-cielo-nubes_24972-1095.jpg" alt="" />
            <div className="information">
                <div className="photo-information-person">
                    <div id="photo-pencil">
                        <img className="photo-person fit-cover" src={actualImage} onError={(ev: any) => {
                            ev.target.src = "/assets/img/default_image.png";
                        }} alt="" />
                        <div className="pencil-photo-profile">
                            <input type="file" id="input-change-photo" accept=".png, .jpg, .jpeg" onChange={changeProfileImage} ref={imageRef} />
                            <i className="bi bi-pencil pencil"></i>
                        </div>
                    </div>
                    <div className="information-button w-100 d-flex flex-row justify-content-between" id="information-button">
                        <div className="information-person">
                            <span>{props.user.name} {props.user.last_name}</span>
                            <span id="profession">{isset(props.user.organization_code) ?
                                props.user.principal_activity
                                :
                                props.user.profession}</span>
                            <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M24 33.1q5.2-4.2 7.85-8.075Q34.5 21.15 34.5 17.8q0-2.95-1.075-5t-2.65-3.35Q29.2 8.15 27.35 7.575 25.5 7 24 7t-3.35.575q-1.85.575-3.425 1.875-1.575 1.3-2.65 3.35t-1.075 5q0 3.35 2.65 7.225Q18.8 28.9 24 33.1Zm0 3.8q-6.85-5.15-10.175-9.85-3.325-4.7-3.325-9.25 0-3.4 1.225-5.975Q12.95 9.25 14.9 7.5q1.95-1.75 4.35-2.625Q21.65 4 24 4t4.75.875q2.4.875 4.35 2.625 1.95 1.75 3.175 4.325Q37.5 14.4 37.5 17.8q0 4.55-3.325 9.25T24 36.9ZM24 21q1.45 0 2.475-1.025Q27.5 18.95 27.5 17.5q0-1.45-1.025-2.475Q25.45 14 24 14q-1.45 0-2.475 1.025Q20.5 16.05 20.5 17.5q0 1.45 1.025 2.475Q22.55 21 24 21ZM10.5 44v-3h27v3ZM24 17.8Z" /></svg>
                                {props.user.location}</span>
                        </div>
                        <div className="d-flex flex-column align-items-stretch">
                            <Button border onClick={() => setModal({ open: true, action: "report" })} style={{ fontSize: ".8rem" }}>Reportar Problema</Button>
                            {
                                props.user.organization_code ?
                                    <></>
                                    :
                                    <Button border onClick={props.updateInfo} style={{ fontSize: ".8rem" }}>Editar información</Button>
                            }
                        </div>
                    </div>
                </div>
                <hr />

                {
                    isset(props.user.organization_code) ?
                        <div className="profile-information">
                            <p>INFORMACIÓN DE PERSONAL DE CONTACTO</p>
                            <div className="information-contact-person">
                                <div>
                                    <span>Celular:</span>
                                    <p><a href="#">{props.user.cellphone}</a></p>
                                </div>
                                <div>
                                    <span>Correo Electronico:</span>
                                    <p><a id="email-person" href="#" onMouseLeave={(ev: any) => {
                                        ev.target.innerText = props.user.email.slice(0, 20).concat(props.user.email.length > 20 ? "..." : "");
                                    }} onMouseEnter={(ev: any) => {
                                        ev.target.innerText = props.user.email;
                                        //window.screen.availWidth
                                    }}>
                                        {
                                            window.screen.availWidth > 600 ?
                                                props.user.email
                                                :
                                                window.screen.availWidth > 400 ?
                                                    props.user.email.slice(0, 20).concat(props.user.email.length > 20 ? "..." : "")
                                                    :
                                                    props.user.email.slice(0, 10).concat(props.user.email.length > 10 ? "..." : "")
                                        }
                                    </a></p>
                                </div>
                                <div>
                                    <span>Ubicación:</span>
                                    <p>{props.user.location}</p>
                                </div>
                            </div>
                            <p>INFORMACIÓN DE LA ORGANIZACIÓN</p>
                            <div className="information-contact-person">
                                <div>
                                    <span>Organización:</span>
                                    <p>{props.user.name}</p>
                                </div>
                                <div>
                                    <span>Actividad Principal:</span>
                                    <p>{props.user.principal_activity}</p>
                                </div>
                                <div>
                                    <span>Página Web:</span>
                                    <p>{props.user.website}</p>
                                </div>
                            </div>
                        </div>

                        :
                        <div className="profile-information">
                            <p>INFORMACIÓN CONTACTO</p>
                            <div className="information-contact-person">
                                <div>
                                    <span>Celular:</span>
                                    <p><a href="#">{props.user.cellphone}</a></p>
                                </div>
                                <div>
                                    <span>Correo Electronico:</span>
                                    <p><a id="email-person" href="#" onMouseLeave={(ev: any) => {
                                        ev.target.innerText = props.user.email.slice(0, 20).concat(props.user.email.length > 20 ? "..." : "");
                                    }} onMouseEnter={(ev: any) => {
                                        ev.target.innerText = props.user.email;
                                        //window.screen.availWidth
                                    }}>
                                        {
                                            window.screen.availWidth > 600 ?
                                                props.user.email
                                                :
                                                window.screen.availWidth > 400 ?
                                                    props.user.email.slice(0, 20).concat(props.user.email.length > 20 ? "..." : "")
                                                    :
                                                    props.user.email.slice(0, 10).concat(props.user.email.length > 10 ? "..." : "")
                                        }
                                    </a></p>
                                </div>
                                <div>
                                    <span>Ubicación:</span>
                                    <p>{props.user.location}</p>
                                </div>
                            </div>
                            <p>INFORMACIÓN PERSONAL</p>
                            <div className="information-contact-person">
                                <div>
                                    <span>Nombre:</span>
                                    <p>{props.user.name}</p>
                                </div>
                                <div>
                                    <span>Apellido:</span>
                                    <p>{props.user.last_name}</p>
                                </div>
                                <div>
                                    <span>Nacimiento:</span>
                                    <p>{fecha.toLocaleDateString("es")}</p>
                                </div>
                                <div>
                                    <span>Género:</span>
                                    <p>{
                                        props.user.sex == "M" ?
                                            "Masculino"
                                            :
                                            (props.user.sex == "F" ?
                                                "Femenino"
                                                :
                                                "Otro"
                                            )
                                    }</p>
                                </div>
                                <div>
                                    <span>Profesión:</span>
                                    <p>{props.user.profession}</p>
                                </div>
                                <div className="about-information-person">
                                    <span>Acerca de tí:</span>
                                    <Input id="input-about" hasBorder type="textarea" placeholder="" defaultValue={props.user.about}
                                        onBlur={(ev: any) => {
                                            if (ev.target.value.length > 0) {
                                                madePost(
                                                    {
                                                        key: "update-about-me",
                                                        token: getLoginToken(),
                                                        text: ev.target.value
                                                    },
                                                    "account.php"
                                                ).then(r => {
                                                    let res = r as ResponseGeneral;

                                                    if (res.RESULT) {
                                                        toast.success("Se ha actualizado tu descripción");

                                                        let prev = getItem("USER");
                                                        let prevUser: Person = {} as Person;
                                                        try {
                                                            prevUser = JSON.parse(prev as string);
                                                            prevUser["about"] = ev.target.value;
                                                        } catch (err) { }
                                                        setItem("USER", JSON.stringify(prevUser));
                                                    } else {
                                                        toast.error(errorMessagePost)
                                                    }
                                                }).catch(err => {
                                                    toast.error(errorMessagePost)
                                                })
                                            }
                                        }} />

                                </div>
                            </div>
                        </div>
                }

            </div>
        </div>

        <Modal centered isOpen={modal.open}>
            <ModalHeader onClick={() => { }}>

                <div className="login" >
                    <span>Reportar un problema</span>
                </div>

                <div className="close-button" onClick={() => {
                    setModal({ open: false, action: "" });
                }}>
                    <i className="bi bi-x"></i>
                </div>

            </ModalHeader>

            <ModalBody>

                <Input hasBorder onInput={analizeForm} innerRef={titRef} placeholder="Titulo del problema" id="" type="text" />
                <Input hasBorder onInput={analizeForm} innerRef={descRef} placeholder="Describelo a continuación" id="pass" type="textarea" className="mr-0 text-area-report" />

                <Button className="register mt-3" disabled={!isValidForm} border={true} onClick={sendReport} >Reportar</Button>

            </ModalBody>
        </Modal>
    </>
}

export default Info;