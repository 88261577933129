import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Collapse } from "reactstrap";
import Button from "../components/Button";
import Card, { CardSeeMore } from "../components/Card";
import CustomSwiper from "../components/CustomSwiper";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Input from "../components/Input";
import Loader from "../components/Loader";
import { generateUrlCourse } from "../functions/functions";
import post, { madePost } from "../functions/post";
import { AvailableCategories, Course, Organization, ResponseContact, ResponseGeneral } from "../functions/types";
import { categorieImages } from "../functions/variables";

import "../styles/pages/Courses.scss";
import "../styles/pages/Home.scss";

const Courses = () => {

    const [obtainedAllCourses, setObtainedAllCourses] = useState([] as Course[]);

    const navigate = useNavigate();

    useLayoutEffect(() => {
        madePost(
            {
                key: "get-all-courses"
            },
            "courses.php"
        ).then(r => {
            let res = r as ResponseGeneral;
            if (res.RESULT) {
                setObtainedAllCourses(res.VALUE)
            } else {
                toast.error("Ha ocurrido un error al obtener los cursos, porfavor intenta nuevamente más tarde");
            }

        }).catch(err => {
            toast.error("Ha ocurrido un error al obtener los cursos, porfavor intenta nuevamente más tarde");
        })
    }, [])

    const SupSection = () => {
        return <section className="sup-section-courses">
            <picture>
                <img src="/assets/img/bg-image-home.jpg" alt="" className="fit-cover" />
            </picture>
            <div className="sup-section-text">
                <h3><h1><b>APRENDE</b></h1> nuevas habilidades de una forma <h1><b>DIFERENTE</b></h1></h3>

                <p>Aprende a tu propio ritmo y diviértete mientras lo haces!</p>

                <Button solid onClick={() => {

                }}>Comenzar aprendiendo</Button>
            </div>
        </section>
    }

    const AllCourses = () => {
        const [limitCards, setLimitCards] = useState(5);
        const [limitCategories, setLimitCategories] = useState(4);

        const [categories, setCategories] = useState([] as { categorie: string, categorieText: string, courses: Course[] }[]);
        const [categoriesDefault, setcategoriesDefault] = useState([] as { categorie: string, categorieText: string, courses: Course[] }[]);
        const [categorieCards, setCategorieCards] = useState([] as { categorieText: string, categorieKey: string, cards: JSX.Element[] }[]);

        let analizeCategories = () => {
            let organized = obtainedAllCourses.reduce(function (r, a) {
                r[a.category] = r[a.category] || [];
                r[a.category].push(a);
                return r;
            }, Object.create(null));

            let result: { categorie: string, categorieText: string, courses: Course[] }[] = [];

            Object.keys(organized).map((key: string, index: number) => {
                try {
                    result.push({ categorie: key, categorieText: categorieImages[key as string].categorieText, courses: organized[key as string] });
                } catch (err) {
                    result.push({ categorie: key, categorieText: "Otros", courses: organized[key as string] });
                }
            });

            setCategories(result);
            setcategoriesDefault(result)
        }

        let madeCardsByCategories = () => {
            let categorieCardsResult: { categorieText: string, categorieKey: string, cards: JSX.Element[] }[] = [];

            categories.map((categorie: { categorie: string, categorieText: string, courses: Course[] }, index: number) => {
                let cards: JSX.Element[] = [];
                categorie.courses.map((course: Course, indx: number) => {
                    let courseOrganization: Organization = course.organization as Organization;

                    if (typeof course.organization === "string") {
                        try {
                            courseOrganization = JSON.parse(course.organization);
                        } catch (err) { };
                    }

                    cards.push(<Card
                        head={<img src={course.cover_image} className="fit-cover" alt="Curso" />}
                        author={{
                            logo: courseOrganization.logo!,
                            name: courseOrganization.name
                        }}
                        key={"card-cat:" + categorie.categorie + ";" + indx}
                        title={course.title.length > 70 ? course.title.slice(0, 70).concat("...") : course.title}
                        description={course.description.slice(0, 30).concat(course.description.length > 30 ? "..." : "")}
                        type={course.type === "course" ? "Curso" : "Capacitación"}
                        onClick={() => {
                            //Change to course

                            navigate(
                                generateUrlCourse(course),
                                { state: { course: course } }
                            );
                        }}
                        mini
                    />);
                });

                categorieCardsResult.push({ categorieText: categorie.categorieText, categorieKey: categorie.categorie, cards: cards });
            });

            categorieCardsResult = categorieCardsResult.sort((a: { categorieText: string, categorieKey: string, cards: JSX.Element[] }, b: { categorieText: string, categorieKey: string, cards: JSX.Element[] }) => {
                if (a.cards.length > b.cards.length) {
                    return -1;
                }

                if (a.cards.length < b.cards.length) {
                    return 1;
                }

                return 0;
            });

            setCategorieCards(categorieCardsResult);
        }

        useLayoutEffect(() => {
            analizeCategories();
        }, [])

        useEffect(() => {
            if (categories.length > 0) {
                madeCardsByCategories();
            }
        }, [categories])

        //<CustomSwiper id="courses-most-populars" title="Los cursos más populares" elements={cards} />
        return <><section className="contrast">
            <div className="title-with-button align-items-end">
                <div>
                    <h5 id="nuestros-cursos" className="title-section left my-0">TODOS NUESTROS CURSOS</h5>
                </div>
                <div>
                    <Input type="search" placeholder={"Buscar"} innerPlaceholder="Buscar por nombre u organización" hasBorder icon={<i className="bi bi-search"></i>} iconSlot="end"
                        onChange={(ev: any) => {
                            if (ev.target.value.length > 0) {
                                let cleaned: {
                                    categorie: string;
                                    categorieText: string;
                                    courses: Course[];
                                }[] | undefined = categoriesDefault.map((e: { categorie: string, categorieText: string, courses: Course[] }) => {
                                    let cs: Course[] = e.courses.filter((c: Course) => {
                                        return c.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                            .includes(ev.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
                                    })

                                    return { categorie: e.categorie, categorieText: e.categorieText, courses: cs };
                                })

                                if (cleaned) {
                                    setCategories(cleaned)
                                }
                            } else {
                                setCategories(categoriesDefault)
                            }
                        }} />
                </div>
            </div>

            {//All regular categories
                categorieCards.length > 0 ?
                    <>
                        {
                            categorieCards.slice(0, limitCategories).map((categorie: { categorieText: string, categorieKey: string, cards: JSX.Element[] }, index: number) => {
                                return <CustomSwiper
                                    id={"courses-technology-" + categorie.categorieKey}
                                    title={categorie.categorieText}
                                    elements={categorie.cards.slice(0, limitCards)}
                                    className="mt-4"
                                    seeMoreElement={categorie.cards.length >= limitCards ?
                                        <CardSeeMore title="Ver más" description="Continua explorando entre nuestros cursos" onClick={() => {
                                            setLimitCards(limitCards + 4);
                                        }} /> : undefined}
                                    onSeeMore={() => {
                                        setLimitCards(limitCards + 10)
                                    }} />
                            })
                        }
                        {
                            categorieCards.length >= limitCategories ?
                                <Button border onClick={() => {
                                    setLimitCategories(limitCategories + 4);
                                }}>Ver más</Button>
                                :
                                <></>
                        }
                    </>
                    :
                    <Loader text="Cargando todos los cursos" />
            }

        </section>

            {
                categorieCards.length > 0 ?
                    <CategoriesComunity />
                    :
                    <></>
            }
        </>
    }

    const CategoriesComunity = () => {
        const [availableCategorie, setAvailableCategory] = useState({} as { key: AvailableCategories, courses: Course[] });
        const [categorieSelected, setCategorieSelected] = useState({ key: "tech", courses: [] } as { key: AvailableCategories, courses: any[] });

        let analizeCategories = () => {
            let organized = obtainedAllCourses.reduce(function (r, a) {
                r[a.category] = r[a.category] || [];
                r[a.category].push(a);
                return r;
            }, Object.create(null));

            setAvailableCategory(organized);
        }

        useLayoutEffect(() => {
            analizeCategories();
        }, [])

        return <>
            <section className="categories-courses-section">
                <h5 className="title-section left my-3" /*---browser negocios y alianzas----*/ id="negocios-cursos">EXPLORA ENTRE NUESTROS CURSOS</h5>
                <div className="categorie-cards">
                    {Object.keys(availableCategorie).map((key: string) => {
                        let name: { categorieText: string, bgImage: string } = { categorieText: "", bgImage: "" };
                        try {
                            name = categorieImages[key as string];
                        } catch (err) {
                            return <></>;
                        }
                        return <div className="categorie" onClick={() => {
                            let cards: JSX.Element[] = [];

                            obtainedAllCourses.filter(course => course.category == key).map((course: Course) => {
                                let org: any = {} as any;
                                try {
                                    org = JSON.parse(course.organization as unknown as string);
                                } catch (err) {
                                    org.logo = "/assets/img/default_image.png";
                                    org.name = "";
                                }
                                cards.push(<Card
                                    head={<img src={course.cover_image} className="fit-cover" alt="Tarjeta de categoria" />}
                                    author={{
                                        logo: org.logo,
                                        name: org.name
                                    }}
                                    title={course.title.length > 70 ? course.title.slice(0, 70).concat("...") : course.title}
                                    type={course.type === "course" ? "Curso" : "Capacitación"}
                                    mini
                                    onClick={() => {
                                        //Change to course

                                        navigate(
                                            generateUrlCourse(course),
                                            { state: { course: course } }
                                        );
                                    }}
                                />);
                            })

                            setCategorieSelected({ key: key, courses: cards });

                        }}>
                            <img src={name.bgImage} className="fit-cover" alt="Imagen de negocios y alianzas" />
                            <span>{name.categorieText}</span>
                        </div>
                    })}
                </div>

                {categorieSelected.courses.length > 0 ?
                    <CustomSwiper id="categorie-selected" title={categorieImages[categorieSelected.key as string].categorieText} elements={categorieSelected.courses} />
                    :
                    <></>
                }

            </section>
        </>
    }

    const Bussiness = () => {
        const [isForm, setIsForm] = useState({ open: false, action: "enterprise" });
        const [isSendingForm, setIsSendingForm] = useState(false);
        const [isValidForm, setIsValidForm] = useState(false);

        const emailRef = useRef({} as HTMLInputElement);

        const sendForm = () => {
            setIsSendingForm(true);

            let form = new FormData();

            form.append("key", "business-contact");

            form.append("email", emailRef.current.value);

            post("contact.php", form, {}).then(r => {
                setIsSendingForm(false);
                setIsValidForm(false);
                setIsForm({ open: false, action: "enterprise" });

                let response = r as ResponseContact;

                if (response.RESULT) {
                    toast.success("Su mensaje ha sido enviado con éxito. ¡Nos pondremos en contacto contigo muy pronto!");
                } else {
                    toast.error("Ha ocurrido un error, porfavor intentalo nuevamente más tarde ");
                }
            }).catch(r => {
                setIsSendingForm(false);
                setIsValidForm(false);
                setIsForm({ open: false, action: "enterprise" });

                toast.error("Ha ocurrido un error, porfavor intentalo nuevamente más tarde ");
            })

        }

        return <div className="contrast business-courses">
            <div className="business-btns">
                <section className="pb-5">
                    <h5 className="title-section left my-3">NEGOCIOS Y ALIANZAS</h5>
                    <p className="description-section">Si necesitas capacitar a tu personal tenemos la solución, ofrecemos cursos y capacitaciones de alta calidad, también te ofrecemos la posibilidad de presentar tus cursos mediante nuestra plataforma.</p>

                    {isForm.open ?

                        <></>
                        :
                        <div>
                            <Button border radiusMin onClick={() => {
                                setIsForm({ open: true, action: "enterprise" });
                            }}>
                                Academia Creamos Juntos para empresas
                            </Button>
                            <Button border radiusMin onClick={() => {
                                setIsForm({ open: true, action: "equipment" });
                            }}>
                                Academia Creamos Juntos para equipos
                            </Button>
                        </div>

                    }
                </section>
                <img src="/assets/img/business-hand.png" alt="" />
            </div>

            <Collapse isOpen={isForm.open} className="form-academy-business">
                <section className="pt-0 pb-5">
                    <h5 className="title-section m-0">INGRESA TU CORREO</h5>
                    <Input hasBorder innerRef={emailRef}
                        placeholder="Correo electrónico" id="email-form-business"
                        type="email"
                        isAValidEmail={(value: string, valid: boolean) => {
                            setIsValidForm(valid);
                        }}
                        className="mb-2"
                    />
                    <Button solid
                        onClick={sendForm}
                        style={{ width: "100%" }}
                        disabled={isSendingForm ? true : (isValidForm ? false : true)}>{isSendingForm ? <Loader /> : "ENVIAR"}</Button>
                </section>
            </Collapse>
        </div>
    }

    return <div className="courses-page">
        <Header />

        <Helmet>
            <title>Todos los cursos | Fundación Creamos Juntos</title>
            <meta charSet="utf-8" />
            <link rel="canonical" href={window.location.href} />
            <meta name="description" content={"Participa en nuestros curso gratuitos de Fundación Creamos Juntos Bolivia, donde puedes aprender muchas habilidades"} ></meta>
        </Helmet>

        <SupSection />

        <AllCourses />

        <Bussiness />

        <Footer />
    </div>
}

export default Courses;