import Button from "./Button";
import Input from "./Input";

import { useRef, useState } from "react";

import "../styles/components/NewsLetter.scss";
import post from "../functions/post";

export type newsletterAPIResponse = {
    result: boolean
}

const NewsLetter = () => {
    const [emailValue, setEmailValue] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const contentNewsLetterRef = useRef(new Object as HTMLDivElement);
    const titleNewsLetterRef = useRef(new Object as HTMLDivElement);

    const sendFormNewsletter = () => {
        let form = new FormData();

        form.append("key", "newsletter-submitted");
        form.append("email", emailValue);

        setButtonDisabled(true);

        post("newsletter.php", form, {}).then((r => {
            let response = r as newsletterAPIResponse;

            contentNewsLetterRef.current.style.display = "none";
            let divChild = titleNewsLetterRef.current.children[0] as HTMLDivElement;
            divChild.style.gridColumnStart = "1";
            divChild.style.gridColumnEnd = "4";

            (divChild.children[1] as HTMLParagraphElement).style.display = "none";

            if (response) {
                (divChild.children[0] as HTMLElement).innerText = "¡GRACIAS POR REGISTRARTE EN NUESTRO NEWSLETTER!";
            } else {
                (divChild.children[0] as HTMLElement).innerText = "HA OCURRIDO UN ERROR, PORFAVOR INTENTA MÁS TARDE";
            }
        })).catch((err) => {
            contentNewsLetterRef.current.style.display = "none";
            let divChild = titleNewsLetterRef.current.children[0] as HTMLDivElement;
            divChild.style.gridColumnStart = "1";
            divChild.style.gridColumnEnd = "4";

            (divChild.children[1] as HTMLParagraphElement).style.display = "none";

            (divChild.children[0] as HTMLElement).innerText = "HA OCURRIDO UN ERROR, PORFAVOR INTENTA MÁS TARDE";
        })
    }

    return <section className="newsletter-section" id="newsletter">
        <div>
            <div className="title-newsletter" ref={titleNewsLetterRef}>
                <div className="newsletter-content">
                    <h5>REGISTRATE A NUESTRO NEWSLETTER</h5>
                    <p>Y recibe información sobre nuevos cursos y promociones completamente gratis</p>
                </div>
            </div>

            <div className="content-newsletter" ref={contentNewsLetterRef}>
                <div className="picture-newsletter">
                    <picture>
                        <img src="/assets/img/gmail.png" alt="" />
                    </picture>
                </div>

                <div className="newsletter-content">
                    <Input hasBorder
                        id="email-newsletter"
                        type="email"
                        placeholder="Ingresa tu correo electrónico"
                        isAValidEmail={(value: string, isValid: boolean) => {
                            if (isValid) {
                                setButtonDisabled(false);
                                setEmailValue(value);
                            } else {
                                setButtonDisabled(true);
                            }

                        }} />
                    <Button solid
                        disabled={buttonDisabled}
                        onClick={sendFormNewsletter}>
                        Enviar
                    </Button>
                </div>
            </div>
        </div>
    </section>
}

export default NewsLetter;