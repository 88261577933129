import { useRef, useState } from "react"
import { Helmet } from "react-helmet"
import toast from "react-hot-toast"
import Button from "../components/Button"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Input from "../components/Input"
import { madePost } from "../functions/post"
import { ResponseGeneral } from "../functions/types"
import { errorMessagePost } from "../functions/variables"
import "../styles/components/ContactUs.scss"

let ContactUs = () => {

    const [isValidForm, setIsValidForm] = useState(false);

    const nameRef = useRef({} as HTMLInputElement);
    const lastNameRef = useRef({} as HTMLInputElement);
    const emailRef = useRef({} as HTMLInputElement);
    const cellphoneRef = useRef({} as HTMLInputElement);
    const messageRef = useRef({} as HTMLInputElement);

    const analizeForm = () => {
        if(nameRef.current.value.length > 0 &&
            
            emailRef.current.value.length > 0 &&
            
            messageRef.current.value.length > 0){
                setIsValidForm(true);
            }else{
                setIsValidForm(false);
            }
    }

    return <>
        <Header />
        <Helmet>
            <title>Contáctanos | Fundación Creamos Juntos</title>
            <meta charSet="utf-8" />
            <link rel="canonical" href={window.location.href} />
            <meta name="description" content="Contactanos Fundación Creamos Juntos Bolivia mediante correo o el formulario de contacto, tambieén por nuestras redes Facebook, Instagram, Whatsapp, etc " ></meta>
        </Helmet>
        <div className="contactus" />

        <section className="content-about-us contrast">
            <h5 className="title-section">Contactanos</h5>

            <div className="contactus-box">
                <div className="contact-information">

                    <div className="contacts">
                        <h6>Información de Contactos</h6>
                        <div>
                            <i className="bi bi-phone-fill"></i>
                            <small>+591 78800360</small>
                        </div>
                        <div>
                            <i className="bi bi-envelope-fill"></i>
                            <small>soporte@creamosjuntos.org</small>
                        </div>

                        <div className="social-networks">
                            <a href="https://www.facebook.com/ReCreaOrg"><i className="bi bi-facebook"></i></a>
                            <a href="https://www.instagram.com/recreaorg/?hl=es"><i className="bi bi-instagram"></i></a>
                            <a href="https://www.tiktok.com/@recreaorg?lang"><i className="bi bi-tiktok"></i></a>
                            <a href="https://www.youtube.com/channel/UCZhxp1V_RvN-aR9E8ViiDEA"><i className="bi bi-youtube"></i></a>
                            <a href="https://twitter.com/ReCreaOrg"><i className="bi bi-twitter"></i></a>
                            <a href="https://api.whatsapp.com/send?phone=59178800360"><i className="bi bi-whatsapp"></i></a>
                        </div>
                    </div>
                    {/*<small>Siguenos en nuestras redes</small>*/}
                </div>
                <div className="message-contact-us">
                    <div className="input-data">
                        <Input required hasBorder placeholder="Nombres" type="text" className="mb-2" innerRef={nameRef} onInput={analizeForm} />
                        <Input hasBorder placeholder="Apellidos" type="text" className="mb-2" innerRef={lastNameRef} onInput={analizeForm} />
                        <Input required hasBorder placeholder="Correo" type="email" className="mb-2" innerRef={emailRef} onInput={analizeForm}
                            isAValidEmail={(email: string, valid: boolean) => {
                                setIsValidPersonEmail(valid)
                            }} />

                        <Input hasBorder placeholder="Número Celular" type="number" className="mb-2" innerRef={cellphoneRef} onInput={analizeForm} />
                    </div>

                    <div id="input-message">
                        <small>Mensaje</small>
                        <Input hasBorder required type="textarea" placeholder="" className="mb-2" innerRef={messageRef} onInput={analizeForm} />
                    </div>

                    <Button border disabled={!isValidForm} onClick={() => {
                        madePost(
                            {
                                key: "contact",
                                email: emailRef.current.value,
                                name: nameRef.current.value + " " + lastNameRef.current.value,
                                cellphone: cellphoneRef.current.value,
                                message: messageRef.current.value
                            },
                            "contact.php"
                        ).then(r => {
                            let res = r as ResponseGeneral;

                            if (res.RESULT) {
                                toast.success("Gracias, nos contactaremos contigo prontamente");

                                emailRef.current.value = "";
                                nameRef.current.value = "";
                                lastNameRef.current.value = "";
                                cellphoneRef.current.value = "";
                                messageRef.current.value = "";
                            } else {
                                toast.error(errorMessagePost);
                            }
                        }).catch(err => {
                            toast.error(errorMessagePost);
                        })
                    }}>
                        Enviar
                    </Button>

                </div>
            </div>
        </section>
        <Footer />
    </>
}
export default ContactUs

function setIsValidPersonEmail(valid: boolean) {
    throw new Error("Function not implemented.")
}
