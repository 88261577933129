import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Label } from "reactstrap";
import { madePost } from "../functions/post";
import { Course, Organization, Person, ResponseGeneral } from "../functions/types";
import { errorMessagePost } from "../functions/variables";
import Card from "./Card";
import CustomSwiper from "./CustomSwiper";
import Loader from "./Loader";

import "../styles/components/PersonCourses.scss";
import { useNavigate } from "react-router-dom";
import { generateUrlCourse } from "../functions/functions";

const PersonCourses = (props: {
    user: Person
}) => {

    const [courses, setCourses] = useState({ value: [] as Course[], isLoaded: false });

    const navigate = useNavigate();

    useEffect(() => {
        if (!courses.isLoaded) {
            madePost(
                {
                    key: "get-courses-by-user",
                    user_code: props.user.user_code
                },
                "courses.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    setCourses({ value: res.VALUE, isLoaded: true });
                } else {
                    toast.error(res.EXCEPTION_MESSAGE)
                }
            }).catch(err => {
                toast.error(errorMessagePost);
            })
        }
    }, [])

    return <div className="person-courses">
        <section className="mini-spacing">
            <h5 className="title-section left mb-1">CURSOS</h5>
            <p className="description-section mb-4">Aqui puedes acceder a todos tus cursos donde te encuentras inscrito</p>

        {
            courses.isLoaded ?
                (courses.value.length === 0 ?
                    <Label>Aún no te has inscrito a un curso</Label>
                    :
                    <CustomSwiper 
                    id="user-courses"
                    title="CURSOS DISPONIBLES ACTUALMENTE"
                    elements={
                        courses.value.map((course: Course, index: number) => {
                            let organization = course.organization;

                            if(typeof organization == "string"){
                                try{
                                    organization = JSON.parse(organization);
                                }catch(err){}
                            }

                            return <Card 
                                head={<img className="fit-cover" src={course.cover_image} alt="Card of course"/>}
                                author={{
                                    name: (organization as Organization).name!,
                                    logo: (organization as Organization).logo!
                                }}
                                title={course.title} 
                                description={course.description.slice(0, 60) + (course.description.length > 60? "..." : "")}
                                type={course.type === "course"? "Curso" : "Capacitación"}
                                mini
                                key={"course-card-user-"+index}
                                onClick={()=>{
                                    navigate(
                                        generateUrlCourse(course),
                                        {state: {course: course}}
                                    )
                                }}
                            />
                        })
                    }
                    />
                )
                :
                <Loader />
        }
        </section>
    </div>
}
export default PersonCourses;