import { useEffect, useState } from 'react';
import { ProSidebar } from 'react-pro-sidebar';
import ReactTooltip from 'react-tooltip';
import { Collapse } from 'reactstrap';
import { isset } from '../functions/functions';
import { tablet } from '../functions/variables';

import "../styles/components/Sidebar.scss";

export type SidebarElements = {
    subtitle: string,
    subtitleKey: string,
    darken?: boolean,
    subtitleIcon?: string,
    onSubtitleClick: (subtitleKey: string, index: number) => any,
    isComplete?: boolean
}

export type SidebarElementsTitles = {
    title: string,
    titleKey: string,
    icon: any,
    onTitleClick: (titleKey: string, index: number) => any,
    elements?: SidebarElements[],
    default?: boolean,
    open?: boolean,
    isComplete?: boolean
};

export let ContainerWithSidebar = (props: {
    Sidebar: any,
    children: any
}) => {
    return <div className="container-with-sidebar">
        {props.Sidebar}
        {props.children}
    </div>
}

const Sidebar = (props: {
    title: string,
    header?: any,
    values: SidebarElementsTitles[]
}) => {
    const [isSideCollapse, setIsSideCollapse] = useState(window.screen.availWidth > tablet ? false : true);
    const [titleOpen, setTitleOpen] = useState({ open: false, titleKey: "" });
    const [isShowTooltip, setIsShowTooltip] = useState(true);

    useEffect(() => {
        let opens: SidebarElementsTitles[] = [];
        props.values.map((element: SidebarElementsTitles) => {
            if (element.open) {
                opens.push(element)
            }
        });

        if (opens.length > 0 && !isSideCollapse) {
            setTitleOpen({ open: true, titleKey: opens[0].titleKey });
        }
    }, [props.values]);

    return <ProSidebar collapsed={isSideCollapse} className="sidebase-profile-component"
        onMouseLeave={() => {
            setIsShowTooltip(false);
            setTimeout(() => {
                setIsShowTooltip(true);
            }, 50)
        }}>

        {isShowTooltip && <ReactTooltip
            id="tooltip-sidebar"
            place="right"
            type="dark"
            effect="solid"
        />}
        <div className='title-sidebar'>
            <h6>{props.title}</h6>
            <i className="bi bi-list" onClick={() => {
                if (!isSideCollapse) {
                    setTitleOpen({ open: false, titleKey: "" });
                }
                setIsSideCollapse(!isSideCollapse);
            }}></i>
        </div>

        {
            props.header ?
                <div className='header-sidebar'>
                    {props.header}
                </div>
                :
                <></>
        }

        {props.values.map((element: SidebarElementsTitles, index: number) => {
            return <div
                className={'menu-element '}
                key={"menu-element-" + index}>

                {isSideCollapse ?
                    <div className={"menu-title-icon pointer ".concat(element.title.toLowerCase().replaceAll(" ", "-"))}
                        onClick={() => {
                            setTitleOpen({ open: (titleOpen.titleKey !== element.titleKey ? true : !titleOpen.open), titleKey: element.titleKey });
                            element.onTitleClick(element.titleKey, index);

                            if (isset(element.elements)) {
                                setIsSideCollapse(false);
                            }
                        }}
                        onMouseEnter={() => {
                            setIsShowTooltip(true);
                        }}
                        onMouseLeave={() => {
                            setIsShowTooltip(false);
                            setTimeout(() => {
                                setIsShowTooltip(true);
                            }, 50)
                        }}
                        data-for="tooltip-sidebar"
                        data-tip={element.title}
                    >
                        {element.icon}

                    </div>
                    :
                    <div
                        className={"menu-title pointer " + (titleOpen.titleKey == element.titleKey ? "menu-selected" : (titleOpen.titleKey == "" ? (element.default ? "menu-selected" : "") : ""))}
                        onClick={() => {
                            setTitleOpen({ open: (titleOpen.titleKey !== element.titleKey ? true : !titleOpen.open), titleKey: element.titleKey });
                            element.onTitleClick(element.titleKey, index);

                            if (isset(element.elements)) {
                                setIsSideCollapse(false);
                            }
                        }}

                    >
                        <span>{element.title}</span>
                        {isset(element.elements) ? (element.titleKey !== titleOpen.titleKey ? <i className="bi bi-chevron-down"></i> : <i className={titleOpen.open ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>) : <></>}
                    </div>
                }
                {isset(element.elements) ?
                    <Collapse isOpen={titleOpen.titleKey === element.titleKey ? titleOpen.open : false} className="collapse-subelements">
                        {element.elements!.map((subelement: SidebarElements, subindex: number) => {
                            
                            return <div className={subelement.darken ? "darken-sidebar-element submenu-element" : "submenu-element pointer"} key={"submenu-element-" + subindex} onClick={() => {
                                subelement.onSubtitleClick(subelement.subtitleKey.replaceAll("?", "%3F"), subindex)
                            }}>
                                <span>{subelement.subtitle}</span>
                                {subelement.subtitleIcon ?
                                    <div className='d-flex flex-row align-items-center'>
                                        { subelement.isComplete?
                                            <i className='bi bi-check' style={{color: "green", marginRight: ".25rem"}}></i>
                                            :
                                            <></>
                                        }
                                        <i className={"bi bi-" + subelement.subtitleIcon} style={subelement.isComplete? {color: "green"} : {}}></i>
                                        
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                        })}
                    </Collapse>
                    :
                    <></>
                }
            </div>
        })}
    </ProSidebar>
}
export default Sidebar;