
import Footer from '../components/Footer';
import Header from '../components/Header';
import { isUserLogged } from '../functions/user';
import Sidebar, { ContainerWithSidebar } from '../components/Sidebar';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import "../styles/pages/Profile.scss";
import Info from './subpages/Info';
import { useEffect, useState } from 'react';
import AccountSecurityProfile from './subpages/AccountSecurityProfile';
import { isset } from '../functions/functions';
import { Organization, Person } from '../functions/types';
import PersonCourses from '../components/PersonCourses';
import Policy from '../components/Policy';
import { useLocation, useNavigate } from 'react-router-dom';
import CourseManagment from './subpages/CreateCourseManagment';
import { routes } from '../functions/routes';
import Statistics from './subpages/Statistics';

const Profile = () => {
    let location: {
        state: {
            section: string,
            id: string
        },
        pathname: string,
        hash: string
    } = useLocation() as any;

    //Info de usuario
    const user: Person & Organization | null = isUserLogged() as Person & Organization | null;

    const [tabIndex, setTabIndex] = useState((location.hash.length > 0 ? (
        location.hash == "#privacy" ?
            (
                user?.organization_code ?
                    4
                    :
                    3
            )
            :
            0
    ) : 0));

    const navigate = useNavigate();

    const [updateInfo, setUpdateInfo] = useState<true | undefined>(undefined);

    if (user === null) {
        navigate(routes.home);
    }

    //URLS
    //Icon map https://fonts.google.com/icons?icon.query=maps

    useEffect(() => {
        if (location.state !== null) {
            switch (location.state.section) {
                case "information": setTabIndex(0); break;
                case "courses": setTabIndex(1); break;
                case "security": setTabIndex(2); break;
                case "privacy": setTabIndex(3); setTimeout(() => window.location.href = location.state.id, 200); break;
                default: setTabIndex(0); break;
            }
        }
    }, [location])

    const onSelectTitle = (titleKey: string, index: number) => {
        setTabIndex(index);
    }
    const onSelectSubtitle = (subtitleKey: string, index: number) => {
    }

    const PersonSidebar = [
        {
            title: "Tu información",
            titleKey: "tu-info",
            icon: <i className="bi bi-info-square"></i>,
            onTitleClick: onSelectTitle,
            default: true
        },
        {
            title: "Tus cursos",
            titleKey: "tus-cursos",
            icon: <i className="bi bi-journal-album"></i>,
            onTitleClick: onSelectTitle
        },

        {
            title: "Seguridad de la cuenta y sesión",
            titleKey: "change-pass",
            icon: <i className="bi bi-file-lock"></i>,
            onTitleClick: onSelectTitle,
        },
        {
            title: "Privacidad",
            titleKey: "privacity",
            icon: <i className="bi bi-shield-exclamation"></i>,
            onTitleClick: onSelectTitle
        },
    ]

    const OrganizationSidebar = [
        {
            title: "Tu información",
            titleKey: "tu-info",
            icon: <i className="bi bi-info-square"></i>,
            onTitleClick: onSelectTitle,
            default: true
        },
        {
            title: "Tus cursos",
            titleKey: "tus-cursos",
            icon: <i className="bi bi-journal-album"></i>,
            onTitleClick: onSelectTitle
        },
        {
            title: "Estadisticas",
            titleKey: "estadisticas",
            icon: <i className="bi bi-graph-up"></i>,
            onTitleClick: onSelectTitle
        },
        {
            title: "Seguridad de la cuenta y sesión",
            titleKey: "change-pass",
            icon: <i className="bi bi-file-lock"></i>,
            onTitleClick: onSelectTitle,
        },
        {
            title: "Privacidad",
            titleKey: "privacity",
            icon: <i className="bi bi-shield-exclamation"></i>,
            onTitleClick: onSelectTitle
        },
    ]

    return <div className='profile-page'>
        <Header updateInfo={updateInfo} />
        <ContainerWithSidebar Sidebar={<Sidebar title='Perfil'
            values={
                user?.organization_code ? OrganizationSidebar : ((user?.admin_of != null) ? [...PersonSidebar, {
                    title: "Estadisticas",
                    titleKey: "estadisticas",
                    icon: <i className="bi bi-graph-up"></i>,
                    onTitleClick: onSelectTitle
                }] : PersonSidebar)
            } />}>

            <Tabs selectedIndex={tabIndex} onSelect={() => { }} >
                <TabList>
                    <Tab></Tab>
                    <Tab></Tab>
                    <Tab></Tab>
                    {
                        user?.organization_code ?
                            <Tab></Tab>
                            :
                            <></>
                    }
                    <Tab></Tab>
                </TabList>
                <TabPanel>
                    {<Info user={user as Person & Organization}
                        updateInfo={() => {
                            setUpdateInfo(true);
                        }} />}
                </TabPanel>
                <TabPanel>
                    {
                        isset(user?.organization_code) ?
                            <CourseManagment />
                            :
                            <PersonCourses user={user as Person} />
                    }
                </TabPanel>
                {
                    user?.organization_code ?
                        <TabPanel>
                            <Statistics user={user as Organization} />
                        </TabPanel>
                        :
                        <></>
                }
                <TabPanel>
                    <AccountSecurityProfile />
                </TabPanel>
                <TabPanel>
                    <Policy />
                </TabPanel>

                {
                    (user?.admin_of != null) &&
                    <TabPanel>
                        <Statistics user={user as Organization} />
                    </TabPanel>
                }
            </Tabs>

        </ContainerWithSidebar>

        <Footer />
    </div>
}

export default Profile;