import "../styles/components/Quizz.scss";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Modal } from "reactstrap";
import Button from "./Button";

import { ReactFormBuilder, ReactFormGenerator } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css"
import Loader from "./Loader";
import { madePost } from "../functions/post";
import { Quizz as QuizzType, ResponseGeneral } from "../functions/types";
import toast from "react-hot-toast";
import { onCatch } from "../functions/variables";


const QuizExample = (props: {
    data: any,
    onSolve: (res: any) => any
}) => {
    const [modalVisibility, setModalVisibility] = useState(false);

    return (
        <div className="quizz-component">
            <>
                <div style={{ display: "flex", flexFlow: "nowrap row", justifyContent: "flex-end" }}>
                    <Button border onClick={() => setModalVisibility(true)}>Resolver</Button>
                </div>
                <Modal
                    title="Previsualizacion y completado"
                    isOpen={modalVisibility}
                    onCancel={() => setModalVisibility(false)}
                    className="p-0 modal-preview-quizz"
                    centered
                >
                    <div className="p-4">
                        <div className="modal-head-icon-x">
                            <h5 className="">
                                Completar con las respuestas correctas
                            </h5>

                            <i className="bi bi-x" onClick={() => { setModalVisibility(false) }}></i>
                        </div>

                        <ReactFormGenerator
                            data={props.data}
                            form_action="#"
                            form_method="GET"
                            onSubmit={(ev: any) => {
                                // eslint-disable-next-line no-restricted-globals
                                let c = confirm("Está seguro de utilizar esas respuestas para calificar los cuestionarios de los estudiantes?");
                                if (c) {
                                    setModalVisibility(false);
                                    props.onSolve(ev)
                                } else {

                                }
                            }}
                        />
                        <small><b>NOTA:</b> Los usuarios lo verán de la misma forma y deberán tener las mismas respuestas que tú llenes ahora</small>
                        <small><b>NOTA:</b> Para que una pregunta abierta acepte cualquier texto como correcto, utiliza <b>*</b> como respuesta</small>
                    </div>
                </Modal>
            </>
        </div>
    );
}


const Quizz = (props: {
    quizzId: number | null,
    quizz: QuizzType | null,
    adminQuizz: (form: any, solved: any, isEdit: boolean, quizzId: number | null, valid: boolean) => any
}) => {
    /*
    lang
    {
      "display-label": "Mostrar texto",
      "choose-file": "Seleccionar archivo",
      "choose-file-type": "Seleccionar tipo de archivo",
      "select-file-type": "Seleccionar tipo de archivo",
      "text-to-display": "Texto a mostrar",
      "link-to": "Link a",
      "center": "Centro",
      "width": "Ancho",
      "height": "Alto",
      "required": "Requerido",
      "read-only": "Solamente lectura",
      "default-to-today": "Hoy por defecto",
      "show-time-select": "Mostrar tiempo seleccionado",
      "show-time-select-only": "Mostrar tiempo seleccionado solamente",
      "show-time-input": "Mostrar entrada de tiempo",
      "display-horizontal": "Mostrar horizontal",
      "variable-key": "Clave de variable",
      "variable-key-desc": "Esto dará al elemento una clave que se puede utilizar para reemplazar el contenido con un valor en tiempo de ejecución",
      "print-options": "Mostrar opciones",
      "page-break-before-elements": "Salto de página antes del elemento",
      "alternate-signature-page": "Página de suplentes/firmas",
      "display-on-alternate-signature-page": "Visualización en la página alternativa/firma",
      "step": "Paso",
      "min": "Min",
      "max": "Max",
      "default-selected": "Seleccionado por defecto",
      "text-style": "Estilo de textp",
      "bold": "Bold",
      "italic": "Italic",
      "description": "Descripción",
      "correct-answer" : "Respuesta correcta",
      "populate-options-from-api" : "Rellenar opciones desde la API",
      "populate" : "Rellenar",
      "pdf" : "PDF",
      "word" : "Word",
      "excel" : "Excel",
      "ppt" : "PPT",
    
      "options" : "Opciones",
      "value" : "Valor",
      "correct" : "Correcto",
    
      "dismiss" : "Descartar",
    
      "place-holder-option-1" : "Opción 1",
      "place-holder-option-2" : "Opción 2",
      "place-holder-option-3" : "Opción 3",
      "place-holder-tag-1" : "Opción 1",
      "place-holder-tag-2" : "Opción 2",
      "place-holder-tag-3" : "Opción 3",
    
      "toolbox" : "Herramientas",
      "header-text" : "Cabecera",
      "label" : "Etiqueta",
      "paragraph" : "Parrafo",
      "line-break" : "Linea",
      "dropdown" : "Desplegable",
      "tags": "Tags",
      "checkboxes" : "Checkboxes",
      "multiple-choice":"Selección múltiple",
      "text-input":"Entrada de texto",
      "email-input":"Correo",
      "phone-input":"Número de celular",
      "number-input":"Entrada de números",
      "multi-line-input":"Entrada multilinea",
      "two-columns-row":"2 columnas",
      "three-columns-row":"3 columnas",
      "four-columns-row":"4 columnas",
      "five-columns-row":"5 columnas",
      "six-columns-row":"6 columnas",
      "image":"Imagen",
      "rating":"Calificación",
      "date":"Fecha",
      "signature":"Firma",
      "website":"Website",
      "file-attachment":"Archivo adjunto",
      "range":"Rango",
      "camera":"Cámara",
      "file-upload":"Subida de archivo",
      "place-holder-text": "Texto ...",
      "place-holder-label": "Etiqueta ...",
      "place-holder-website-link": "Sitio web ...",
      "place-holder-file-name": "Nombre de archivo...",
      "place-holder-email": "Correo",
      "place-holder-phone-number": "Número de celular",
      "easy": "Fácil",
      "difficult": "Dificultad",
      "drop-zone": "Arrastrar aquí",
    
      "message.is-required": "es requerido",
      "message.was-answered-incorrectly": "fue contestado incorrectamente",
      "message.was-not-registered": "no estaba registrado",
      "message.invalid-email": "El campo de correo electrónico requiere una dirección de correo electrónico válida",
      "message.invalid-phone-number": "El campo Número de teléfono requiere un número de teléfono válido"
    
    }
    */
    const [formData, setFormData] = useState({ data: (props.quizz ? props.quizz!.quizz : {} as any) });
    const [formDataValid, setFormDataValid] = useState({ data: (props.quizz ? props.quizz!.quizz_solved : {} as any), confirmed: false });

    const [isObtaining, setIsObtaining] = useState(false);

    const [container, setContainer] = useState({ element: {} as HTMLDivElement, is: false });

    useLayoutEffect(() => {
        setCont()
    }, [])

    useLayoutEffect(() => {
        setCont()
    }, [props])

    useEffect(() => {
        if (container.is) {
            try {
                container.element.addEventListener("click", () => {
                    try {
                        let svg = container.element.getElementsByClassName("edit-form")[0].getElementsByClassName("clearfix")[0].children;

                        if (svg.length > 0) {
                            (svg[1] as any).onclick = closeSide;
                        }

                    } catch (err) { }
                })
            } catch (err) { }
        }
    }, [container])

    useEffect(() => {
        props.adminQuizz(formData.data, formDataValid.data, (props.quizzId ? true : false), props.quizzId, formDataValid.confirmed);
    }, [formDataValid])

    useEffect(() => {
        //setIsEdit(props.quizzId ? false : true);

        if (props.quizzId) {
            //no edit
        }
        console.debug(props)
    }, [props])

    const closeSide = () => {
        //Set close on svg
        container.element.className = "col-md-9 react-form-builder-preview float-left";
    }

    const getQuizz = () => {
        //getting when is not loaded and is not obatining anything
        //getting form data
        setIsObtaining(true);

        madePost(
            {
                key: "get-quizz",
                quizz_id: props.quizzId
            },
            "manage.php"
        ).then(r => {
            try {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    let form: QuizzType = res.VALUE;
                    setFormData({ data: form.quizz });
                    setFormDataValid({ data: form.quizz_solved, confirmed: false });


                } else {
                    toast.error("El cuestionario no se ha encontrado");
                }

                setIsObtaining(false);
            } catch (err) {
                console.debug("ERR: ");
                console.debug(err)
            }
        }).catch(err => {
            console.debug(err)
            onCatch(err)
        })
    }


    const setCont = () => {
        let el = document.getElementsByClassName("react-form-builder-preview");

        setTimeout(() => {

            if (el.length > 0) {
                setContainer({ element: el[0] as HTMLDivElement, is: true })
            } else {
                setTimeout(() => {

                    if (el.length > 0) {
                        setContainer({ element: el[0] as HTMLDivElement, is: true })
                    } else {
                        setTimeout(() => {
                            setContainer({ element: el[0] as HTMLDivElement, is: true })
                        }, 150)
                    }
                }, 100)
            }
        }, 50)
    }

    const qRef = useRef({} as any);

    return <div className="quizz-component">
        {
            <>
                <ReactFormBuilder
                    onPost={(ev: any) => {
                        setFormData({ data: ev.task_data });
                    }}

                    ref={qRef}
                />

                {
                    Object.keys(formData.data).length > 0 && <QuizExample data={formData.data}
                        onSolve={(form: any) => {
                            setFormDataValid({ data: form, confirmed: true });
                            //setIsEdit(false);
                        }} />
                }

            </>
        }
    </div>
}

export default Quizz;