import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Sidebar, { ContainerWithSidebar } from "../components/Sidebar";
import { madePost } from "../functions/post";
import { routes } from "../functions/routes";
import { Course, LectureLesson, Module, Organization, Person, ResponseGeneral, VideoLesson } from "../functions/types";
import { isUserLogged } from "../functions/user";
import { errorMessagePost, onCatch, videoStreamURL } from "../functions/variables";

import { Player } from 'video-react';
import "video-react/dist/video-react.css";

import "../styles/pages/Lesson.scss";
import LessonPDF from "./subpages/LessonPDF";
import { Helmet } from "react-helmet";
import { ReactFormGenerator } from "react-form-builder2";
import { Collapse } from "reactstrap";

const Lesson = () => {
    let { actualCourse, lesson_type, lesson_id } = useParams();
    let location: {
        state: {
            module: Module,
            modules: Module[],
            course: Course,
            user: Person & Organization
        },
        pathname: string
    } = useLocation() as any;

    const navigate = useNavigate();

    const [modules, setModules] = useState({ values: [] as Module[], isLoaded: false });
    const [course, setCourse] = useState({ value: {} as Course, isLoaded: false });
    const [lessons, setLessons] = useState({ value: [] as { id: number, type: "video" | "lecture" | "quizz", title: string, module_code: number }[], isLoaded: false })

    const [actualLesson, setActualLesson] = useState({ type: "" as "lecture" | "video" | "quizz", lesson: {} as LectureLesson & VideoLesson | any, isLoaded: false })
    const [actualModule, setActualModule] = useState({ value: {} as Module, isLoaded: false });

    const [temary, setTemary] = useState([] as { subtitle: any, subtitleKey: any, onSubtitleClick: any, darken?: any, code?: number, type?: "video" | "lecture" | "quizz", isComplete?: boolean }[]);//subtitle, subtitleKey, onSubtitleClick, darken
    const [userActivity, setUserActivity] = useState({ values: [] as { code: number, type: "video" | "lecture" | "quizz" }[], isLoaded: false, rendered: false });

    const contentRef = useRef(new Object as HTMLDivElement);

    let user: (Person & Organization) | null = isUserLogged() as (Person & Organization) | null;

    useEffect(() => {
        setActualLesson({ type: "" as "lecture" | "video", lesson: {} as LectureLesson & VideoLesson, isLoaded: false });
    }, [location])

    useEffect(() => {
        if (actualLesson.isLoaded && modules.isLoaded) {
            modules.values.map((mod: Module) => {
                if (actualLesson.lesson.module_code == mod.module_code) {
                    setActualModule({ value: mod, isLoaded: true });
                }
            })
        }

        if (actualLesson.isLoaded) {
            if (actualLesson.lesson.active == 0) {
                try {
                    if (analizeLessons().nextCode !== -1) {
                        onNextLesson()
                    } else if (analizeLessons().prevCode !== -1) {
                        onPrevLesson()
                    }
                } catch (err) { }
            }
        }
    }, [actualLesson, modules]);

    useEffect(() => {
        if (location.state == null) {
            let us = isUserLogged() as Person | Organization | null;

            if (us === null) {
                toast.error("Para acceder a este curso debes iniciar sesion e incribirte primero");
                navigate(routes.courses);
            } else {
                madePost(
                    {
                        key: "get-lesson",
                        lesson_type: lesson_type,
                        lesson_id: lesson_id,
                        course_code: actualCourse!.split("-")[actualCourse!.split("-").length - 1],
                        user_code: user!.organization_code ? user!.organization_code : user!.user_code,
                        user_type: user!.organization_code ? "organization" : "person"
                    },
                    "courses.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        setModules({ values: res.VALUE.MODULES, isLoaded: true });
                        setCourse({ value: res.VALUE.COURSE, isLoaded: true });
                        setLessons({ value: res.VALUE.LESSONS, isLoaded: true })
                        setActualLesson({ type: lesson_type as "lecture" | "video", lesson: res.VALUE.LESSON, isLoaded: true });

                    } else {
                        toast.error(res.EXCEPTION_MESSAGE)
                    }
                }).catch(err => {
                    toast.error(errorMessagePost);
                })
            }

        } else {
            setModules({ values: location.state.modules, isLoaded: true });
            setActualModule({ value: location.state.module, isLoaded: true });
            setCourse({ value: location.state.course, isLoaded: true });

            if (user === null) {
                toast.error("Para acceder a este curso debes iniciar sesion e incribirte primero");
                navigate(routes.courses);
            } else {
                madePost(
                    {
                        key: "get-lesson-only",
                        lesson_type: lesson_type,
                        lesson_id: lesson_id,
                        course_code: actualCourse!.split("-")[actualCourse!.split("-").length - 1],
                        user_code: user.organization_code ? user.organization_code : user.user_code,
                        user_type: user.organization_code ? "organization" : "person"
                    },
                    "courses.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        setLessons({ value: res.VALUE.LESSONS, isLoaded: true })
                        setActualLesson({ type: lesson_type as "lecture" | "video", lesson: res.VALUE.LESSON, isLoaded: true });

                    } else {
                        toast.error(res.EXCEPTION_MESSAGE)
                    }
                }).catch(err => {
                    toast.error(errorMessagePost);
                })
            }
        }

        onObtainStatusLessons();
    }, [location])

    useEffect(() => {
        if (actualLesson.isLoaded) {
            generateTemary();
        }
    }, [actualLesson, userActivity])

    useEffect(() => {
        if (temary.length > 0 && !userActivity.isLoaded) {
            onObtainStatusLessons();
        }
    }, [temary])

    /*
    useEffect(() => {
        if (userActivity.isLoaded && userActivity.values.length > 0) {
            let cp: any[] = [];

            JSON.parse(JSON.stringify(temary)).map((e: { subtitle: any, subtitleKey: any, onSubtitleClick: any, darken?: any, code?: number, type?: "video" | "lecture", isComplete?: boolean }) => {
                let ree = e;
                if (userActivity.values.findIndex((ev) => { return ev.code == e.code }) !== -1) {
                    ree["isComplete"] = true;
                }

                cp.push(ree);
            })

            setTemary(cp);
            setUserActivity({ values: [], isLoaded: true });
            console.debug(cp)
        }
    }, [userActivity, temary])
    */

    const onObtainStatusLessons = () => {
        try {
            madePost(
                {
                    key: "get-user-lesson-activity",
                    user: user?.organization_code ? user.organization_code : user?.user_code,
                    course: window.location.pathname.split("/")[2].split("-").pop()
                },
                "courses.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    //Rechage lessons
                    setUserActivity({ values: res.VALUE, isLoaded: true, rendered: false })
                }
            })
        } catch (err) { }
    }


    const onTitleClick = (titleKey: any, index: number) => {

    }

    const onSubtitleClick = (subtitleKey: string, index: number) => {
        let subtitleSplitted = subtitleKey.split("-");
        let findedModule = actualModule.value;

        try {
            modules.values.find(mod => mod.module_code! == parseInt(subtitleSplitted[2]));
        } catch (err) { }

        navigate((routes.lesson + "/" + actualCourse + "/" + subtitleSplitted[0] + "/" + subtitleSplitted[1]).replaceAll("?", "%3F"),
            {
                state: {
                    module: findedModule,
                    modules: modules.values,
                    course: course.value,
                    user: user
                }
            });
    }
    const analizeLessons = () => {
        let response = {
            nextCode: -1,
            nextType: "",
            prevCode: -1,
            prevType: ""
        };
        if (modules.isLoaded) {
            let lesns: { code: number, type: "lecture" | "video" }[] = [];

            try {
                modules.values.map((module: Module) => {
                    let lessonsParsed: string[] = JSON.parse(module.lessons as unknown as string);

                    lessonsParsed.map((values: string) => {
                        let elem: { code: number, type: "lecture" | "video" } = {} as any;
                        try{
                            elem = JSON.parse(values);;
                        }catch(err){
                            elem = values as any;
                        }

                        if (!lesns.find((el: any) => { return el.code === elem.code && el.type === elem.type })) {
                            try{
                                lesns.push(JSON.parse(values))
                            }catch(err){
                                lesns.push(values as any)
                            }
                        }
                    })
                })

                let findedIndex = lesns.findIndex((el: { code: number, type: string }) => {
                    return el.code === parseInt(lesson_id!) && el.type === lesson_type;
                });

                if (findedIndex >= lesns.length - 1) {
                    //El ultimo

                } else {
                    response.nextCode = lesns[findedIndex + 1].code;
                    response.nextType = lesns[findedIndex + 1].type;
                }

                if (findedIndex === 0) {
                    //El primero
                } else {
                    response.prevCode = lesns[(findedIndex - 1)].code;
                    response.prevType = lesns[(findedIndex - 1)].type;
                }
            } catch (e) {
                console.debug(e)
            }
        }console.debug(response)
        return response;
    }
    const analizeModules = () => {
        let response = {
            nextCode: -1,
            nextModule: {} as Module,
            prevCode: -1,
            prevModule: {} as Module
        };
        if (modules.isLoaded && actualModule.isLoaded) {
            let actualIndex = modules.values.findIndex((mod: Module, index: number) => {
                if (actualModule.value.module_code === mod.module_code) {
                    return true;
                }
                return false;
            })

            if (actualIndex !== -1) {
                if (actualIndex < modules.values.length - 1) {
                    response.nextCode = modules.values[actualIndex + 1].module_code as number;
                    response.nextModule = modules.values[actualIndex + 1] as Module;
                }

                if (actualIndex > 0) {
                    response.prevCode = modules.values[actualIndex - 1].module_code as number;
                    response.prevModule = modules.values[actualIndex - 1] as Module;
                }

            }
        }
        return response;
    }

    const onNextLesson = () => {
        let next = analizeLessons();
        if (next.nextCode !== -1) {
            navigate((routes.lesson + "/" + actualCourse + "/" + next.nextType + "/" + next.nextCode).replaceAll("?", "%3F"),
                {
                    state: {
                        module: actualModule.value,
                        modules: modules.values,
                        course: course.value,
                        user: user
                    }
                });
        } else {
            let mods = analizeModules();

            if (mods.nextCode !== -1) {
                //Next module
                let nextLessons: { code: number, type: "lecture" | "video" }[] | undefined = mods.nextModule.lessons;
                if (typeof nextLessons === "string" && nextLessons) {
                    try {
                        nextLessons = JSON.parse(nextLessons)
                    } catch (err) { }
                }
                if (nextLessons!.length > 0) {
                    navigate((routes.lesson + "/" + actualCourse + "/" + nextLessons![0].type + "/" + nextLessons![0].code).replaceAll("?", "%3F"),
                        {
                            state: {
                                module: mods.nextModule,
                                modules: modules.values,
                                course: course.value,
                                user: user
                            }
                        });
                } else {
                    toast.error("Estas en la última clase ");
                }
            } else {
                toast.error("Estas en la última clase ");
            }

        }
    }
    const onPrevLesson = () => {
        let next = analizeLessons();

        if (next.prevCode !== -1) {
            navigate((routes.lesson + "/" + actualCourse + "/" + next.prevType + "/" + next.prevCode).replaceAll("?", "%3F"),
                {
                    state: {
                        module: actualModule.value,
                        modules: modules.values,
                        course: course.value,
                        user: user
                    }
                });
        } else {
            let mods = analizeModules();

            if (mods.prevCode !== -1) {
                //Next module
                let prevLessons: { code: number, type: "lecture" | "video" }[] | undefined = mods.prevModule.lessons;
                if (typeof prevLessons === "string" && prevLessons) {
                    try {
                        prevLessons = JSON.parse(prevLessons)
                    } catch (err) { }
                }
                if (prevLessons!.length > 0) {
                    navigate((routes.lesson + "/" + actualCourse + "/" + prevLessons![0].type + "/" + prevLessons![0].code).replaceAll("?", "%3F"),
                        {
                            state: {
                                module: mods.prevModule,
                                modules: modules.values,
                                course: course.value,
                                user: user
                            }
                        });
                } else {
                    toast.error("Estas en la primera clase ");
                }
            } else {
                toast.error("Estas en la primera clase ");
            }

        }
    }
    const generateTemary = (): void => {
        let temary: any[] = [];

        if (modules.isLoaded && lessons.isLoaded) {
            modules.values.map((module: Module, index: number) => {
                try {

                    temary.push({
                        subtitle: "Módulo: " + module.module_title,
                        subtitleKey: module.module_title.toLowerCase().replaceAll(" ", "-"),
                        onSubtitleClick: () => { },
                        darken: true
                    });



                    let ls: string[] /* { code: number, type: "lecture" | "video" }[] | undefined */ = JSON.parse(module.lessons! as unknown as string);

                    ls!.map((value: string) => {

                        let info: { code: number, type: "lecture" | "video" | "quizz" };
                        try {
                            info = JSON.parse(value);
                        } catch (err) {
                            info = value as any;
                        }

                        let finded = lessons.value.find((lesson: { id: number, type: "video" | "lecture" | "quizz", title: string, module_code: number }) => {
                            // eslint-disable-next-line eqeqeq
                            return info.code == lesson.id && info.type === lesson.type;
                        });

                        if (finded &&
                            !temary.find((e: any) => {
                                return e.subtitleKey === (info.type + "-" + info.code + "-" + finded!.module_code)
                            })) {
                            if (userActivity.isLoaded) {

                                if (userActivity.values.findIndex((ev) => { return ev.code == finded?.id }) === -1) {
                                    temary.push(
                                        {
                                            subtitle: finded.title,
                                            subtitleKey: info.type + "-" + info.code + "-" + finded.module_code,
                                            subtitleIcon: (finded.type === "lecture" ? "book" : finded.type === "video" ? "play-fill" : "patch-question"),
                                            onSubtitleClick: onSubtitleClick,
                                            type: finded.type,
                                            code: finded.id,
                                            isComplete: false
                                        }
                                    )
                                } else {
                                    temary.push(
                                        {
                                            subtitle: finded.title,
                                            subtitleKey: info.type + "-" + info.code + "-" + finded.module_code,
                                            subtitleIcon: (finded.type === "lecture" ? "book" : finded.type === "video" ? "play-fill" : "patch-question"),
                                            onSubtitleClick: onSubtitleClick,
                                            type: finded.type,
                                            code: finded.id,
                                            isComplete: true
                                        }
                                    )
                                }


                            } else {
                                temary.push(
                                    {
                                        subtitle: finded.title,
                                        subtitleKey: info.type + "-" + info.code + "-" + finded.module_code,
                                        subtitleIcon: finded.type === "lecture" ? "book" : "play-fill",
                                        onSubtitleClick: onSubtitleClick,
                                        type: finded.type,
                                        code: finded.id,
                                        isComplete: false
                                    }
                                )
                            }
                        }
                    })

                } catch (err) {
                    console.debug(err)
                    temary.push({
                        subtitle: "Módulo: " + module.module_title,
                        subtitleKey: module.module_title.toLowerCase().replaceAll(" ", "-"),
                        onSubtitleClick: () => { },
                        darken: true
                    });

                    lessons.value.map((lesson: { id: number, type: "video" | "lecture" | "quizz", title: string, module_code: number }, index: number) => {
                        if (module.module_code === lesson.module_code) {
                            temary.push(
                                {
                                    subtitle: lesson.title,
                                    subtitleKey: lesson.type + "-" + lesson.id + "-" + lesson.module_code,
                                    onSubtitleClick: onSubtitleClick
                                }
                            )
                        }
                    })
                }

            })
        }
        setTemary(temary);
    }

    const PrincipalPanelCourse = () => {
        //On Quizz

        const [isQuizzCompleted, setIsQuizzCompleted] = useState((actualLesson.lesson.corrects != null || actualLesson.lesson.corrects != undefined) ? true : false);
        const [isSending, setIsSending] = useState(false);
        const [formValues, setFormValues] = useState(isQuizzCompleted ? {
            corrects: actualLesson.lesson.corrects,
            incorrects: actualLesson.lesson.incorrects
        }
            :
            {
                corrects: 0,
                incorrects: 0
            });
        const [literallyResponses, setLiterallyResponses] = useState(isQuizzCompleted ?
            JSON.parse(actualLesson.lesson.responses_literal)
            :
            {
                user: [],
                corrects: []
            }
        )
        const [time, setTime] = useState("");

        useEffect(() => {
            if (actualModule.isLoaded) {
                try {
                    let t = actualModule.value.total_time.split("-");
                    setTime((t[0] == "00" || t[0] == "0" ? "" : t[0] + " horas, ") + (t[1] == "00" || t[1] == "0" ? "" : t[1] + " minutos"))
                } catch (err) {
                    setTime("")
                }
            }
        }, [actualModule])

        let areEqual = (array1: any, array2: any): boolean => {
            if (Object.prototype.toString.call(array1) === "[object Array]") {
                //is array can use every()
                if (array1.length === array2.length) {
                    return array1.every((element: any, index: any) => {
                        if (typeof element == "object") {
                            return areEqual(element, array2[index]);
                        } else {
                            if (element === array2[index]) {
                                return true;
                            }
                        }


                        return false;
                    });
                }
            } else {
                return areEqual(Object.values(array1), Object.values(array2));
            }

            return false;
        }

        const analizeUserResponses = (ev: { custom_name: string, value: any }[]) => {
            let quizzFrom = JSON.parse(actualLesson.lesson.quizz);
            let validAnswers: { custom_name: string, value: any }[] = JSON.parse(actualLesson.lesson.quizz_solved);
            let corrects = 0;
            let quantityAnswers = validAnswers.length;

            //SEND
            //analice validity
            validAnswers.map((e: { custom_name: string, value: any }) => {
                let finded = ev.find((r: { custom_name: string, value: any }) => {
                    return r.custom_name == e.custom_name;
                });
                //finded is user value

                if (finded) {
                    //if is finded user in db of posibily values
                    if (e.value == "*") {
                        //if is * anithing is valid like correct
                        corrects++;
                    } else {
                        if (typeof finded.value == "object") {
                            if (areEqual(finded.value, e.value)) {
                                corrects++;
                            }
                        } else {
                            if (finded.value == e.value) {
                                corrects++;
                            }
                        }
                    }
                }
            })

            //generate literraly rsponsenss
            let literally: string[][] = [];
            let correctLiterally: string[][] = [];
            let responses = ev;

            quizzFrom.map((e: any) => {
                let keys = Object.keys(e);
                if (keys.includes("field_name")) {
                    let userSel: any = responses.find((res: any) => {
                        return res.name == e.field_name;
                    }) //selected by user
                    let correctResponse: any = validAnswers.find((res: any) => {
                        return res.name == e.field_name;
                    }) //correct

                    if (userSel) {
                        //Find text of user selected
                        //name of label
                        let nm = "";

                        if (keys.includes("content")) {
                            nm = e["content"];
                        } else if (keys.includes("label")) {
                            nm = e["label"];
                        } else if (keys.includes("placeholder")) {
                            nm = e["placeholder"];
                        }

                        //Select by user
                        let res = "";

                        //correct
                        let corr = "";

                        if (typeof userSel.value == "string") {
                            //
                            res = userSel.value;
                            corr = correctResponse.value;
                        } else if (typeof userSel.value == "object") {
                            if (userSel.value.length > 0) {
                                //select something
                                if (typeof userSel.value[0] == "string") {
                                    userSel.value.map((rr: string) => {

                                        if (keys.includes("options") &&
                                            (e.element == "Checkboxes" || e.element == "RadioButtons")) {
                                            //if is an checkbox search definetly label selected
                                            let fn: { key: string, text: string, value: string } = e.options.find((rrr: any) => {
                                                return rrr.key == rr;
                                            });

                                            //after finded in options now control is is enconutered
                                            if (fn) {
                                                res += fn["text"] + ", ";
                                            }
                                        } else {
                                            res += rr + ", ";
                                        }

                                    })
                                } else {
                                    userSel.value.map((rr: any) => {
                                        let ks = Object.keys(rr);

                                        if (ks.includes("label")) {
                                            res += rr["label"] + ", ";
                                        } else {
                                            res += JSON.stringify(rr) + ", "
                                        }
                                    })
                                }
                            } else {
                                //select nothing
                                res = "";
                            }

                            //correct search

                            if (correctResponse.value.length > 0) {
                                //select something
                                if (typeof correctResponse.value[0] == "string") {
                                    correctResponse.value.map((rr: string) => {

                                        if (keys.includes("options") &&
                                            (e.element == "Checkboxes" || e.element == "RadioButtons")) {
                                            //if is an checkbox search definetly label selected
                                            let fn: { key: string, text: string, value: string } = e.options.find((rrr: any) => {
                                                return rrr.key == rr;
                                            });

                                            //after finded in options now control is is enconutered
                                            if (fn) {
                                                corr += fn["text"] + ", ";
                                            }
                                        } else {
                                            corr += rr + ", ";
                                        }

                                    })
                                } else {
                                    correctResponse.value.map((rr: any) => {
                                        let ks = Object.keys(rr);

                                        if (ks.includes("label")) {
                                            corr += rr["label"] + ", ";
                                        } else {
                                            corr += JSON.stringify(rr) + ", "
                                        }
                                    })
                                }
                            } else {
                                //select nothing
                                corr = "";
                            }
                        } else {
                            res = userSel.value;
                            corr = correctResponse.value;
                        }

                        //Find correct values

                        literally.push([nm, res]);

                        correctLiterally.push([nm, corr])
                    }

                }
            })


            setIsSending(true);
            setIsQuizzCompleted(true);
            toast.loading("El cuestionario se está enviando");
            madePost(
                {
                    key: "quizz-rate",
                    quizz_id: actualLesson.lesson.quizz_id,
                    user_code: user?.organization_code ? user?.organization_code : user?.user_code,
                    user_type: user?.organization_code ? "organization" : "person",
                    responses: JSON.stringify(ev),
                    responses_literal: JSON.stringify({
                        user: literally,
                        corrects: correctLiterally
                    }),
                    corrects: corrects,
                    incorrects: quantityAnswers - corrects
                },
                "courses.php"
            ).then(r => {
                toast.dismiss();
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    setFormValues({ corrects: corrects, incorrects: (quantityAnswers - corrects) });
                    setLiterallyResponses({
                        user: literally,
                        corrects: correctLiterally
                    });
                } else {
                    if (res.EXCEPTION_MESSAGE == "23000") {
                        toast.error("Este cuestionario solo se puede enviar una vez");
                    } else {
                        toast.error("Ha ocurrido un error enviando el formulario, porfavor intenta nuevamente más tarde");
                    }
                    setIsQuizzCompleted(false)
                }
                setIsSending(false);
            }).catch(err => {
                toast.dismiss()
                onCatch(err);

                setIsQuizzCompleted(false);
                setIsSending(false);
            })

        }

        const CorrectValuesQuizz = () => {
            const [comp, setComp] = useState([<></>] as any);

            const [isOpen, setIsOpen] = useState(false);

            useEffect(() => {
                let el: any = [];
                literallyResponses.user.map((e: string[], index: number) => {
                    let fin = literallyResponses.corrects[index];

                    el.push(<div>
                        <span><b>{index + 1}{") "}</b> {fin[0]}</span>
                        <small>Tu respuesta: <b>{e[1]}</b>
                            {e[1] == fin[1] ?
                                <i color="green" className="bi bi-check-lg"></i>
                                :
                                <>
                                    <i color="red" className="bi bi-x-lg"></i>
                                </>
                            }
                        </small>

                        {e[1] != fin[1] ? <small>Respuesta correcta: <b>{fin[1]}</b></small> : <></>}
                    </div>)

                })

                setComp(el)

            }, [])

            return <div className="correct-responses-quizz-aftersend">

                <span className="see-results"
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}>
                    {isOpen ? "Ocultar " : "Ver "} respuestas <i className={"bi bi-chevron-" + (isOpen ? "up" : "down")}></i>
                </span>

                <Collapse isOpen={isOpen} className="">
                    <div className="results-quizz">
                        {
                            comp.map((el: any) => {
                                return el;
                            })
                        }
                    </div>
                </Collapse>
            </div>
        }

        return <section className="panel-lesson mini-spacing pt-0" style={{ paddingTop: "0" }}>
            {actualLesson.lesson.active == 1 ?
                actualLesson.type === "video" ?

                    <>
                        <Player
                            playsInline
                            poster=""
                            src={videoStreamURL + "module=" + actualLesson.lesson.module_code + "&video_id=" + actualLesson.lesson.video_lesson_code}
                        />

                        <h6 style={{ marginTop: ".5rem" }}>{actualLesson.lesson.title}</h6>
                        <div dangerouslySetInnerHTML={{ __html: actualLesson.lesson.description }}></div>
                    </>
                    :
                    actualLesson.type === "lecture" ?
                        <LessonPDF actualLesson={actualLesson} contentRef={contentRef} />
                        :
                        <>
                            <h6 style={{ marginTop: ".5rem" }}>{actualLesson.lesson.title}</h6>
                            <div dangerouslySetInnerHTML={{ __html: actualLesson.lesson.description }}></div>

                            {isQuizzCompleted ?
                                <>
                                    {
                                        isSending ?
                                            <label htmlFor="">Enviando cuestionario</label>
                                            :
                                            <div className="quizz-page-content-lesson">
                                                <label htmlFor="" className="quizz-completed-label">Cuestionario completado</label>

                                                <div className="quizz-results-lesson">
                                                    <small color="green">Correctas
                                                        <div>
                                                            <small>{formValues.corrects}</small>
                                                            <small>{parseInt(formValues.corrects.toString()) + parseInt(formValues.incorrects.toString())}</small>
                                                        </div>
                                                    </small>
                                                    <small color="red">Incorrectas
                                                        <div>
                                                            <small>{formValues.incorrects}</small>
                                                            <small>{parseInt(formValues.incorrects.toString()) + parseInt(formValues.corrects.toString())}</small>
                                                        </div>
                                                    </small>
                                                </div>

                                                <CorrectValuesQuizz />
                                            </div>
                                    }
                                </>
                                :
                                <>
                                    <ReactFormGenerator
                                        data={JSON.parse(actualLesson.lesson.quizz)}
                                        form_action="#"
                                        form_method="GET"
                                        onSubmit={(ev: { custom_name: string, value: any }[]) => {
                                            // eslint-disable-next-line no-restricted-globals
                                            let c = confirm("Está seguro de utilizar esas respuestas? No podrás editarla");
                                            if (c) {
                                                if (!isSending) {
                                                    analizeUserResponses(ev);
                                                }
                                            }
                                        }}
                                    />

                                </>

                            }

                        </>

                :
                <label>Esta lección no está habilitada momentaneamente</label>
            }

            <div className="controls-lesson-prev-next">
                <Button border radiusMin
                    onClick={onPrevLesson}
                    disabled={analizeLessons().prevCode == -1}
                >
                    <i className="bi bi-chevron-left"></i>
                    ANTERIOR
                </Button>
                <Button border radiusMin
                    onClick={onNextLesson}
                    disabled={analizeLessons().nextCode == -1}
                >
                    SIGUIENTE
                    <i className="bi bi-chevron-right"></i>

                </Button>
            </div>

            <div className="actual-module-lesson">
                <h6>
                    {
                        actualModule.isLoaded ?
                            <>
                                {actualModule.value.module_title}

                                <div className="module-tim">
                                    <i className="bi bi-clock"></i>
                                    <span>{time}</span>
                                </div>
                            </>
                            :
                            ""
                    }
                </h6>

                <p>
                    {actualModule.value.module_description}
                </p>
            </div>
        </section>
    }

    const SidebarHeader = () => {
        return <div className="sidebar-header">
            <img src={course.value.cover_image} alt={course.value.title} />
            <h5>{course.value.title}</h5>
        </div>
    }

    return <div className="lesson-page">

        <Header />
        {
            actualLesson.isLoaded && course.isLoaded && modules.isLoaded ?
                <>
                    <Helmet>
                        <title>{actualLesson.lesson.title} | Curso {course.value.title} | Fundación Creamos Juntos</title>
                        <meta charSet="utf-8" />
                        <link rel="canonical" href={window.location.href} />
                        <meta name="description" content={actualLesson.lesson.title + "| Curso de " + course.value.title + " del Módulo " + actualModule.value.module_title + " | Fundación Creamos Juntos"} ></meta>
                    </Helmet>
                    <ContainerWithSidebar Sidebar={<Sidebar title={""}
                        header={<SidebarHeader />}
                        values={[
                            {
                                title: "Temario",
                                titleKey: "temary",
                                icon: <i className="bi bi-book"></i>,
                                onTitleClick: onTitleClick,
                                elements: temary,
                                open: true,
                                default: true
                            },

                        ]} />}>

                        <PrincipalPanelCourse />

                    </ContainerWithSidebar>
                </>

                :
                <Loader className="loader-tall" text="Cargando lección" />
        }

        <Footer />
    </div>
}

export default Lesson;