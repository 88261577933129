import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../functions/routes";

import "../styles/components/Footer.scss";
const browser = [{
    titulo: "Acerca de Nosotros",
    descripcion: "acerca de nosotros en inicio",
    clave: "acerca nosotros somos quienes recrea re-crea",
    url: "https://creamosjuntos.org/inicio#acerca-de-nosotros-inicio"
}, {
    titulo: "Proyectos",
    descripcion: "proyectos en inicio",
    clave: "proyectos apoyo recrea re-crea",
    url: "https://creamosjuntos.org/inicio#proyectos-inicio"
}, {
    titulo: "Libros",
    descripcion: "libros de recrea en inicio",
    clave: "libros lectura contenido",
    url: "https://creamosjuntos.org/inicio#libros-inicio"
}, {

    titulo: "Videos",
    descripcion: "videos de recrea en inicio",
    clave: "videos audiovisual recrea re-crea audiovisual youtube ",
    url: "https://creamosjuntos.org/inicio#videos-inicio"
}, {
    titulo: "Nuestros Cursos",
    descripcion: "todos nuestros cursos",
    clave: "cursos aprendizaje categorias explorar recrea re-crea capacitaciones talleres",
    url: "https://creamosjuntos.org/cursos#nuestros-cursos"
}, {
    titulo: "Negocios y Alianzas",
    descripcion: "negocios y alianzas cursos",
    clave: "negocios alianzas recrea re-crea",
    url: "https://creamosjuntos.org/cursos#negocios-cursos"
}, {
    titulo: "Sobre Nosotros",
    descripcion: "sobre nosotros re crea",
    clave: "sobre nosotros recrea re-crea",
    url: "https://creamosjuntos.org/nosotros#aboutus"
}, {
    titulo: "Contactos",
    descripcion: "contactos re crea",
    clave: "contactanos contactos recrea re-crea",
    url: "https://creamosjuntos.org/contactos#contactus"
},
{
    titulo: "Inicio",
    descripcion: "",
    clave: "inicio home re crea recrea",
    url: "https://creamosjuntos.org"
},
{
    titulo: "Página en Facebook",
    descripcion: "",
    clave: "facebook meta redes sociales",
    url: "https://www.facebook.com/ReCreaOrg"
},
{
    titulo: "Instagram",
    descripcion: "",
    clave: "instagram redes sociales",
    url: "https://www.instagram.com/recreaorg/?hl=es"
},

{
    titulo: "TikTok",
    descripcion: "",
    clave: "tiktok redes videos videos",
    url: "https://www.tiktok.com/@recreaorg?lang"
},

{
    titulo: "Youtube",
    descripcion: "",
    clave: "videos youtube redes sociales",
    url: "https://www.youtube.com/channel/UCZhxp1V_RvN-aR9E8ViiDEA"
},
{
    titulo: "Twitter",
    descripcion: "",
    clave: "tuiter twiter twitter redes sociales",
    url: "https://twitter.com/ReCreaOrg"
},
{
    titulo: "WhatsApp",
    descripcion: "",
    clave: "whasap whatsapp watsapp wasap mensajes comunicacion",
    url: "https://api.whatsapp.com/send?phone=59178800360"
},
{
    titulo: "Soporte",
    descripcion: "",
    clave: "atencion cliente soporte correo error",
    url: "mailto:soporte@creamosjuntos.org"
},/*
{
    titulo: "",
    descripcion: "",
    clave: "",
    url: ""
},
*/
]
let Footer = () => {

    const [verMas, setVerMas] = useState(false)
    const [browserFinded, setBrowserFinded] = useState([])

    const navigate = useNavigate();

    const onSearch = (ev: any) => {
        if (ev.target.value.length > 0) {
            let finded: any = [];
            browser.map((el: { titulo: string, descripcion: string, clave: string, url: string }) => {
                if (el.clave.toLowerCase().includes(ev.target.value.toLowerCase())) {
                    finded.push(el)
                }
            })
            setBrowserFinded(finded);
        } else {
            setBrowserFinded([]);
        }
    }

    return <footer>
        <section className="pt-5 pb-1">
            <div className="footer-top">
                <div className="text-left-footer mb-3">
                    <div className="text-fundacion-footer pb-3">
                        <span>Fundación Creamos Juntos</span>
                        <p className="text-footer">
                            Recreando tu manera de aprender, buscamos generar consciencia y motivación para poder
                            fortalecer la educación
                        </p>
                        {
                            /**
                            
    <p className="text-footer"> Somos una organización enfocada en la acción educativa y medioambiental,
                            de joven a joven, rescatando la propuesta de la educación popular que,
                            recupera, respeta, critica, autocrítica y reconoce el aporte de saberes de los y
                            las jóvenes.</p>
                        {verMas ?
                            <p>
                                Pretendemos desarrollar habilidades, destrezas y competencias de
                                los y las jóvenes en base a la experiencia, al conocimiento y la propuesta.
                                Esto para fortalecer el proceso de responsabilidad familiar, laboral, política y
                                social. Motivando de esta manera la participación activa de la vida social, y
                                rescatar la actoría social de los y las jóvenes.
                            </p> :
                            <></>}
                        {verMas ?
                            <></> :
                            <p className="ver-mas" onClick={() => {
                                setVerMas(!verMas);
                            }}>Ver Mas</p>}
    
                             */
                        }
                    </div>

                    <div className="text-conoce-footer">
                        <p>Conoce acerca de <Link to={routes.us}>Nosotros</Link></p>
                        <p>Conoce nuestra <Link to={routes.policy}>Política de Privacidad</Link></p>

                        <div className="social-networks-footer">
                            <a href="https://www.facebook.com/ReCreaOrg"><i className="bi bi-facebook"></i></a>
                            <a href="https://www.instagram.com/recreaorg/?hl=es"><i className="bi bi-instagram"></i></a>
                            <a href="https://www.tiktok.com/@recreaorg?lang"><i className="bi bi-tiktok"></i></a>
                            <a href="https://www.youtube.com/channel/UCZhxp1V_RvN-aR9E8ViiDEA"><i className="bi bi-youtube"></i></a>
                            <a href="https://twitter.com/ReCreaOrg"><i className="bi bi-twitter"></i></a>
                            <a href="https://api.whatsapp.com/send?phone=59178800360"><i className="bi bi-whatsapp"></i></a>
                        </div>
                    </div>

                </div>

                <div className="text-right-footer">
                    <div className="search-footer">
                        <input onBlur={(ev: any) => {
                            //setBrowserFinded([])
                            //ev.target.value = ""
                        }} onInput={onSearch} type="search" placeholder="Buscar el la página" />
                        {
                            browserFinded.length > 0 ?
                                <ul>
                                    {
                                        browserFinded.map((el: any, index: number) => {
                                            return <li className="my-1"><a href={el.url}><div>{el.titulo}</div></a></li>
                                        })

                                    }
                                </ul>
                                :
                                <></>
                        }
                    </div>
                </div>
            </div>

            <hr style={{ margin: "0 !important", width: "100%" }} />

            <div className="footer-footer" >
                <span>Todos los derechos reservados <b>Fundación Creamos Juntos 2022</b></span>
            </div>
        </section>
    </footer>
}
export default Footer;
