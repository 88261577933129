import "../styles/components/Loader.scss";

const Loader = (props: {
    className?: string,
    text?: string
}) => {
    return <div className={"loader-component " + props.className}>
        <div className={"book"}>
            <div className="inner">
                <div className="left"></div>
                <div className="middle"></div>
                <div className="right"></div>
            </div>
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
        {
            props.text ?
                <span className="loader-text">{props.text}</span>
                :
                <></>
        }
    </div>
}

export default Loader;