import toast from "react-hot-toast";
import { routes } from "./routes";
import { Course, CourseInterface, Instructor, InstructorInterface, Module, ModuleInterface, Organization, Person } from "./types";
import { getItem, setItem } from "./useStorage";
import { lastLessonStorage } from "./variables";

import xlsx, { IJsonSheet, ISettings } from "json-as-xlsx";

export let isset = (element: any): boolean => {
    if (typeof element === undefined || element === null || element === undefined) {
        return false;
    }
    return true;
}

export let cloneObject = (element: any) => {
    return JSON.parse(JSON.stringify(element))
}

export let getValueOfKey = (key: string, on: any[]) => {
    let res: any[] = [];
    on.map((element: any) => {
        res.push(element[key]);
    });

    return res;
}
export let restoreValueOfKey = (key: string, on: any[], of: any[]) => {
    let res: any[] = [];
    cloneObject(on).map((element: any, index: number) => {
        element[key] = of[index];
        res.push(element);
    });

    return res;
}
export let collectInstructorCodes = (instructors: Instructor[]) => {
    let codes: number[] = [];

    try {
        instructors.map((ins: Instructor) => {
            if (isset(ins.instructor_code)) {
                codes.push(ins.instructor_code!);
            }
        })
    } catch (er) { }

    return codes;
}

export let groupCourse = (course: (Course & Instructor & Module)[]): any => {
    let res: any = course.reduce((rv: any, x: any) => {
        (rv[x["course_code"]] = rv[x["course_code"]] || []).push(x);
        return rv;
    }, {});

    let courses: Course[] = [] as Course[];

    try {
        Object.keys(res).map((firstKey: string, index: number) => {
            let el = res[firstKey];

            let course: Course = {} as Course;
            let instructors: Instructor[] = [] as Instructor[];
            let modules: Module[] = [] as Module[];

            Object.keys(CourseInterface).map((key: string) => {
                course[key as keyof Course] = el[0][key];
            })

            try {
                let organizedByInstructor = el.reduce((rv: any, x: any) => {
                    (rv[x["instructor_code"]] = rv[x["instructor_code"]] || []).push(x);
                    return rv;
                }, {});

                Object.keys(organizedByInstructor).map((keyIns: string) => {
                    if (keyIns !== "null") {
                        let cleanedInstructor: Instructor = {} as Instructor;
                        //Removing other attributes of instructor
                        Object.keys(organizedByInstructor[keyIns][0]).map((keyNewIns: string) => {
                            if (Object.keys(InstructorInterface).includes(keyNewIns)) {
                                (cleanedInstructor as any)[keyNewIns] = organizedByInstructor[keyIns][0][keyNewIns];
                            }
                        })

                        instructors.push(cleanedInstructor as Instructor);
                    }
                })

                let organizedByModules = el.reduce((rv: any, x: any) => {
                    (rv[x["module_code"]] = rv[x["module_code"]] || []).push(x);
                    return rv;
                }, {});

                Object.keys(organizedByModules).map((keyMod: string) => {
                    if (keyMod !== "null") {

                        let cleanedModule: Module = {} as Module;
                        //Removing other attributes of module
                        Object.keys(organizedByModules[keyMod][0]).map((keyNewMod: string) => {
                            if (Object.keys(ModuleInterface).includes(keyNewMod)) {
                                (cleanedModule as any)[keyNewMod] = organizedByModules[keyMod][0][keyNewMod];
                            }
                        })

                        modules.push(cleanedModule as Module);
                    }
                })

            } catch (err) {
                console.log(err)
                toast.error("Ha ocurrido un error organizando tu información, porfavor contacta con soporte")
            }

            course["instructors"] = instructors;
            course["modules"] = modules;
            course["index"] = index;

            courses.push(course)
        })
    } catch (err) {
        console.log(err)
    }

    return courses;
}

export const generateUrlCourse = (course: Course): string => {
    let courseOrganization: Organization = course.organization as Organization;

    if (typeof course.organization === "string") {
        try {
            courseOrganization = JSON.parse(course.organization);
        } catch (err) { };
    }
    return (routes.course + "/" +
        courseOrganization.name.toLowerCase().replaceAll(" ", "") + "-" + course.organization_code + "/" +
        course.title.toLowerCase().replaceAll(" ", "") + "-" + course.course_code?.toString()).replaceAll("?", "%3F")
}

export const generateUrlModule = (to: { code: number, type: "lecture" | "video" } | null, course: Course, module: Module): string | null => {
    console.debug(to, course, module)
    let url: string | null = "";
    /*if (to) {
        url = routes.lesson + "/" + course.title + "-" + course.course_code + "/" + to!.type + "/" + to!.code;
    } else {*/
    try {
        let lesson: { code: number, type: string } = JSON.parse((module.lessons! as unknown as string))[0];

        if (typeof lesson == "string") {
            lesson = JSON.parse(lesson as unknown as any);
        }

        url = routes.lesson + "/" + course.title + "-" + course.course_code + "/" + lesson.type + "/" + lesson.code;
    } catch (er) {
        try {
            let lesson: { code: number, type: string } = JSON.parse(JSON.parse(module.lessons! as unknown as string)[0]);

            url = routes.lesson + "/" + course.title + "-" + course.course_code + "/" + lesson.type + "/" + lesson.code;
        } catch (errr) {
            url = null;

            toast.error("Ha ocurrido un error accediendo al curso, porfavor contacta con soporte")
        }
    }
    //}

    if(url){
        return url.replaceAll("?", "%3F");
    }

    return url;
}

export const getLastLessonCached = (course_code: number): { code: number, type: "lecture" | "video" } | null => {
    try {
        let lastlesson = getItem(lastLessonStorage);

        let res: { code: number, type: "lecture" | "video" } = { code: -1, type: "lecture" }

        if (lastlesson !== null) {
            let lastParsed = JSON.parse(lastlesson);

            let finded = lastParsed.find((less: { code: number, type: "lecture" | "video", course_code: number }) => {
                return less.course_code === course_code;
            })

            if (finded) {
                res.code = finded.code;
                res.type = finded.type;

                return res;
            } else {
                return null;
            }
        } else {
            return null;
        }
    } catch (e) {
        console.debug("ERR: " + e);
        return null;
    }

}

export const setLastLessonCached = (lesson: { code: number, type: "lecture" | "video", course_code: number }) => {
    let saved = getItem(lastLessonStorage)!;
    let savedParsed: { code: number, type: "lecture" | "video", course_code: number }[];

    if (saved) {
        savedParsed = JSON.parse(saved);
    } else {
        savedParsed = [];
    }

    let findedIndex = savedParsed.findIndex((el: { code: number, type: "lecture" | "video", course_code: number }) => {
        return el.course_code === lesson.course_code;
    })

    if (findedIndex !== 1) {
        savedParsed[findedIndex] = { code: lesson.code, type: lesson.type, course_code: lesson.course_code };
    } else {
        savedParsed.push({ code: lesson.code, type: lesson.type, course_code: lesson.course_code })
    }

    setItem(lastLessonStorage, JSON.stringify(savedParsed));
}

export const excelDownloadUsersActivity = (persons: Person[], courses: Course[], selected_course: number) => {
    let actualCourse: Course | undefined = courses.find((c: Course) => { return c.course_code == selected_course });

    let content: any[] = [];

    persons.map((person: Person & any, index: number) => {
        person.nro = index + 1;
        person.activity = "";

        content.push(person);

        person.lessonsPure!.map((lesson: any) => {
            lesson.lesson_type = (lesson.lesson_type == "video" ? "Video" : "Lectura");

            try {
                let stats: {} = JSON.parse(lesson.statistics);
                let nroViews = 0;

                Object.values(stats).map((v: any) => {
                    v.map((usr: number) => {
                        if (usr == person.user_code) {
                            nroViews++;
                        }
                    })
                })

                lesson.viewed = (nroViews > 0 ? "Si" : "No");
                lesson.nro_views = nroViews;
            } catch (err) {
                lesson.viewed = "No"
                lesson.nro_views = 0;
            }

            content.push(lesson);
        })
    })

    let data: IJsonSheet[] = [{
        sheet: actualCourse ? actualCourse.title : "Curso",
        columns: [
            {
                label: "Nro", value: "nro"
            },
            {
                label: "Código de usuario", value: "user_code"
            },
            {
                label: "Nombre", value: "name"
            },
            {
                label: "Apellidos", value: "last_name"
            },
            {
                label: "Celular", value: "cellphone"
            },
            {
                label: "Correo", value: "email"
            },
            {
                label: "Ubicación", value: "location"
            },
            {
                label: "Género", value: "sex"
            },
            {
                label: "Cumpleaños", value: "birthday"
            },
            {
                label: " > Actividad > ", value: "activity"
            },
            {
                label: "Módulo", value: "module_title"
            },
            {
                label: "Título de lección", value: "title"
            },
            {
                label: "Tipo de lección", value: "lesson_type"
            },
            {
                label: "Visto", value: "viewed"
            },
            {
                label: "Veces visto", value: "nro_views"
            },
        ],
        content: content
    }];

    let settings: ISettings = {
        fileName: actualCourse ? ("Actividad de los usuario en " + actualCourse.title) : "Curso - Creamos Juntos Cursos", // Name of the resulting spreadsheet
        extraLength: 2, // A bigger number means that columns will be wider
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    }

    xlsx(data, settings)
}