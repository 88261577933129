import { useEffect, useRef, useState } from "react";
import "../styles/components/ModalRegister.scss";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "./Button";
import Input from "./Input";
import post, { madePost } from "../functions/post";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import "react-datepicker/dist/react-datepicker.css";
import InputLocation from "./InputLocation";

import toast from 'react-hot-toast';
import Loader from "./Loader";
import { Organization, Person, ResponseCreateAccount } from "../functions/types";
import { setItem } from "../functions/useStorage";
import RadioButton from "./RadioButton";
import { Link } from "react-router-dom";
import { routes } from "../functions/routes";

const ModalComplete = (props: {
    user: { email: string, ucode: any, all: { user: Person } }
    open: boolean,
    action: "complete" | "edit",
    onComplete: (p: Person) => any,
    onCancel: () => any
}) => {
    //Person Refs
    const nombrePersona = useRef({} as HTMLInputElement);
    const personNameRef = useRef({} as HTMLInputElement);
    const personCellphoneRef = useRef({} as HTMLInputElement);
    const generoRef = useRef({} as HTMLSelectElement);
    const profesionRef = useRef({} as HTMLInputElement);
    const locationRef = useRef({} as HTMLInputElement);

    //Button
    const buttonSendRef = useRef({} as HTMLButtonElement);

    //Policy
    const policyRef = useRef({} as HTMLInputElement);

    const [isSendingForm, setIsSendingForm] = useState(false);
    const [dateSelected, setDateSelected] = useState(new Date());
    const [isValidPersonForm, setIsValidPersonForm] = useState(false);

    const createPersonAccount = () => {
        setIsSendingForm(true);

        madePost(
            {
                name: nombrePersona.current.value,
                lastName: personNameRef.current.value,
                cell: personCellphoneRef.current.value,
                birthday: dateSelected.toISOString().slice(0, 19).replace('T', ' '),
                genre: generoRef.current.value,
                profesion: profesionRef.current.value,
                location: locationRef.current.value,
                ucode: props.user.ucode,
                email: props.user.email,
                key: "update-info-form"
            },
            "account.php"
        ).then(r => {
            let response = r as ResponseCreateAccount;

            if (response.RESULT) {
                setIsSendingForm(false);
                if(props.action == "edit"){
                    toast.success("Has actualizado tu información");
                }else{
                    toast.success("Tu cuenta ha sido creada satisfactoriamente");
                }
                let uss: Person = (typeof response.USER == "string" ? JSON.parse(response.USER) : (response.USER));
                uss.email = props.user.email;
                uss.user_code = props.user.ucode;

                setItem("USER", JSON.stringify(uss));
                setItem("TOKEN", response.TOKEN);

                props.onComplete(typeof response.USER == "string" ? JSON.parse(response.USER) : response.USER);
            } else {
                setIsSendingForm(false);
                toast.error(response.EXCEPTION_MESSAGE);
            }
        }).catch(r => {
            setIsSendingForm(false);
            toast.error("Ha ocurrido un error, porfavor intenta de nuevo más tarde");
        })
    }

    const isValidPersonFormFun = () => {
        try {
            if (nombrePersona.current.value.length > 0 &&
                personNameRef.current.value.length > 0 &&
                personCellphoneRef.current.value.length > 0 &&
                generoRef.current.value.length > 0 &&
                locationRef.current.value.length > 0 &&
                policyRef.current.checked) {
                setIsValidPersonForm(true);
            } else {
                setIsValidPersonForm(false);
            }
        } catch (e) { }
    }

    return <div className="modal-body">
        <Modal centered isOpen={props.open} style={{ position: "relative" }}>

            <ModalHeader onClick={() => { }}>

                <div className="login" >
                    <span>Completa tu información</span>

                    {
                        props.action == "edit" ?
                            <i className="bi bi-x" style={{ position: "absolute", top: ".5rem", right: ".5rem", cursor: "pointer" }} onClick={() => {
                                if (props.onCancel) {
                                    props.onCancel()
                                }
                            }}></i>
                            :
                            <></>
                    }
                </div>

            </ModalHeader>

            <ModalBody>
                <Input onInput={isValidPersonFormFun} disabled={props.action == "edit" ? true : false} defaultValue={props.action == "edit" ? props.user.all.user.name : undefined} required hasBorder innerRef={nombrePersona} placeholder="Nombres" type="text" />
                <Input onInput={isValidPersonFormFun} disabled={props.action == "edit" ? true : false} defaultValue={props.action == "edit" ? props.user.all.user.last_name : undefined} required hasBorder innerRef={personNameRef} placeholder="Apellidos" type="text" />

                <Input onInput={isValidPersonFormFun} required hasBorder innerRef={personCellphoneRef} disabled={props.action == "edit" ? true : false} defaultValue={props.action == "edit" ? props.user.all.user.cellphone : undefined} placeholder="Número Celular" type="number" />

                <span><small>Fecha de Nacimiento <small className='text-danger'> *</small></small> </span>
                <DatePicker dateFormat={"dd/MM/yyyy"} locale={es} selected={dateSelected} onChange={(date: Date) => setDateSelected(date)} />

                <span><small>Género <small className='text-danger'> *</small></small> </span>
                <select name="" defaultValue={"Masculino"} ref={generoRef}>
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                    <option value="O">Otro</option>
                </select>

                <Input onInput={isValidPersonFormFun} hasBorder innerRef={profesionRef} placeholder="Profesión" type="text" />
                <InputLocation onInput={isValidPersonFormFun} required innerRef={locationRef} hasBorder />

                <RadioButton text={<p>Acepto la <Link to={routes.policy}>política de uso, privacidad y cookies</Link></p>} innerRef={policyRef} onChange={isValidPersonFormFun} />

                <div style={{ display: "flex", flexFlow: "nowrap row", gap: "1rem" }}>
                    {
                        props.action == "edit" ?
                            <Button border onClick={() => {
                                if (props.onCancel) {
                                    props.onCancel()
                                }
                            }}>CANCELAR</Button>
                            :
                            <></>
                    }
                    <Button innerRef={buttonSendRef} className="register" border disabled={!isSendingForm ? (!isValidPersonForm) : true} onClick={(createPersonAccount)}>{(isSendingForm ? <Loader /> : (props.action == "edit" ? "EDITAR" : "ENVIAR"))}</Button>
                </div>

            </ModalBody>

            <ModalFooter>
                <br />
            </ModalFooter>

        </Modal>
    </div>
}
export default ModalComplete;