import PlacesAutocomplete from 'react-places-autocomplete';

import { useState } from "react";
import "../styles/components/Input.scss";
import "../styles/components/InputLocation.scss"
import { isset } from '../functions/functions';

const InputLocation = (props: {
    hasBorder?: boolean,
    id?: string,
    name?: string,
    className?: string,
    styleClass?: string,
    ref?: any,
    innerRef?: any,
    required?: boolean,
    onFocus?: (ev: any) => any,
    onInput?: (ev: any) => any,
    onChange?: (ev: any) => any,
    onBlur?: (ev: any) => any,
    onClick?: (ev: any) => any,
}) => {

    const [value, setValue] = useState("");

    return <div className="input-component">
        <span>
            <small>Ubicación {isset(props.required) ? <small className='text-danger'> *</small> : ""}</small>
        </span>

        <div>
            <input id={props.id}
                className={(props.className ? props.className : "").concat(props.hasBorder ? (props.hasBorder ? " border-input" : "") : "")}
                onFocus={props.onFocus}
                onClick={props.onClick}
                ref={props.innerRef}
                onInput={(ev: any) => {
                    if (props.onInput) {
                        props.onInput(ev);
                    }
                }}
                onChange={(val: any) => {
                    setValue(val.target.value);
                }}
            />
            {
                /*
                
                <PlacesAutocomplete value={value} onChange={(val: string) => {
                setValue(val);
            }}>

                {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
                    <div className="autocomplete-root w-100">
                        <input id={props.id}
                            className={(props.className ? props.className : "").concat(props.hasBorder ? (props.hasBorder ? " border-input" : "") : "")}
                            onFocus={props.onFocus}
                            onClick={props.onClick}
                            ref={props.innerRef}
                            onInput={(ev: any) => {
                                if (props.onInput) {
                                    props.onInput(ev);
                                }
                            }}
                            {...getInputProps()} />
                        {value.length > 0 ?
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Cargando...</div>}
                                {suggestions.map((suggestion) => (
                                    <div {...getSuggestionItemProps(suggestion)}>
                                        <span>{suggestion.description}</span>
                                    </div>
                                ))}
                            </div>
                            :
                            <></>}
                    </div>
                )}

            </PlacesAutocomplete>
                
                */
            }

        </div>
    </div>
}

export default InputLocation;