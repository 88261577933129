import { CSSProperties, useState } from "react";
import ReactTooltip from "react-tooltip";
import { isset } from "../functions/functions";

import "../styles/components/Button.scss";

let Button = (props: {
    children: any,
    border?: boolean,
    radiusMin?: boolean,
    solid?: boolean,
    className?: string,
    innerRef?: any,
    disabled?: boolean,
    id?: string,
    style?: CSSProperties,
    tooltip?: string,
    onClick?: (ev: any) => any
}) => {

    const [isShowTooltip, setIsShowTooltip] = useState(true);

    return <>
        <button
            id={props.id}
            style={props.style}
            ref={props.innerRef}
            onClick={isset(props.onClick) ? props.onClick : () => { }}
            disabled={isset(props.disabled) ? props.disabled : false}
            className={
                (isset(props.border) ? (props.border ? "btn-border " : "btn-no-border ") : "")
                    .concat(isset(props.solid) ? (props.solid ? " btn-solid " : "") : "")
                    .concat(isset(props.className) ? (props.className ? props.className + " " : "") : "")
                    .concat(" button-component ")
                    .concat(isset(props.radiusMin) ? (props.radiusMin ? "btn-radius-min " : "") : "")
                    .concat(isset(props.tooltip) ? props.tooltip!?.toLowerCase().replaceAll(" ", "-") : "")
            }
            data-for={isset(props.tooltip) ? "tooltip-button" : ""}
            data-tip={isset(props.tooltip) ? props.tooltip : ""}

            onMouseLeave={() => {
                setIsShowTooltip(false);
                setTimeout(() => {
                    setIsShowTooltip(true);
                }, 50)
            }}
            onMouseEnter={() => {
                setIsShowTooltip(true);
            }}
        >
            {props.children}
        </button>
        {
            isset(props.tooltip) ?
                isShowTooltip && <ReactTooltip
                    id="tooltip-button"
                    place="bottom"
                    type="dark"
                    effect="solid"
                />
                :
                <></>
        }
    </>
}

export default Button;