import { isset } from "./functions";
import { Organization, Person } from "./types"
import { getItem } from "./useStorage"
import { homePublic } from "./variables";

export const isUserLogged = (): Person | Organization | null => {
    let user: Person | Organization | null = {} as Person | Organization;
    let response = getItem("USER");

    try{
        user = JSON.parse(response!);
    }catch(err){
        user = null;
    }

    return user;
}

export const getLoginToken = () : string | null => {
    let response = getItem("TOKEN");

    return response;
}

export const restorePublicPath = (organization_name?: string):string | null => {
    let name: string = organization_name? organization_name : "";
    if(!isset(organization_name)){
        name = (isUserLogged() as Organization).name;
    }
    return homePublic+"organizations/"+(name.toLowerCase().replaceAll(" ", ""))+"/";
}