import { CSSProperties } from "react";
import { isset } from "../functions/functions";

import "../styles/components/Card.scss";

export const CardSeeMore = (props: {
    title: string,
    description: string,
    style?: CSSProperties
    onClick: () => any
}) => {
    return <div className={"card-component pointer-card see-more"} style={props.style}
        onClick={isset(props.onClick) ? props.onClick : () => { }}>

        <div className="icon-see-more">
            <i className="bi bi-arrow-right"></i>

        </div>

        <div className="titles-info-card">
            <span className="title-card">{props.title}</span>
        </div>
    </div>
}

const Card = (props: {
    head: any,
    author: { logo: string, name: string },
    title: string,
    description?: string,
    type?: string,
    center?: boolean,
    mini?: boolean,
    style?: CSSProperties,
    onClick?: () => any
}) => {
    return <div className={"card-component ".concat(isset(props.center) ? "card-center " : "").concat(isset(props.onClick) ? "pointer-card " : "")} style={props.style}
        onClick={isset(props.onClick) ? props.onClick : () => { }}>
        <div className={"head-card ".concat(isset(props.mini) ? "head-mini" : "")}>
            {props.head}
        </div>

        <picture className="author-logo fit-contain">
            <img src={props.author.logo} alt={props.author.name} />
        </picture>

        <div className="titles-info-card">
            <span className="title-card">{props.title}</span>

            <p className="description-card">{props.description}</p>
        </div>

        <small className="author-card">{props.author.name}</small>

        <span className="type-card">{props.type}</span>

    </div>
}

export default Card;