export type route = {
    root: string,
    home: string,
    courses: string,
    course: string,
    us: string,
    contacts: string,
    login: string,
    logout: string,
    logup: string,
    profile: string,
    course_manager: string,
    lesson: string,
    policy: string,
    instructors: string,
    certificates: string
}

export type youtubeAPIItemResponse = {
    kind: string,
    etag: string,
    id: {
        kind: string,
        videoId: string
    },
    snippet: {
        publishedAt: Date,
        channelId: string,
        title: string,
        description: string,
        thumbnails: {
            default: {
                url: string,
                width: number,
                height: number
            },
            medium: {
                url: string,
                width: number,
                height: number
            },
            high: {
                url: string,
                width: number,
                height: number
            }
        },
        channelTitle: string,
        liveBroadCastContent: string,
        publishTime: Date
    }
}

export type youtubeAPIResponse = {
    kind: string,
    etag: string,
    nextPageToken: string,
    regionCode: string,
    pageInfo: {
        totalResults: number,
        resultsPerPage: number
    },
    items: youtubeAPIItemResponse[]
}


/**
 * 
 * LOGIN
 * 
 */

export type Login = {
    code: number,
    email: string,
    password: undefined,
    user_type: "person" | "organization",
    login_token: string
}

/**
 * 
 * USERS
 * 
 */

export type Person = {
    user_code: number,
    name: string,
    last_name: string,
    email: string,
    cellphone: string,
    birthday: Date,
    sex: "M" | "F",
    profession: string,
    location: string,
    about: string | undefined,
    active: 1 | 0,
    share_info_to_work: 1 | 0,
    cv: undefined,
    academy_info: any,
    lessons?: { type: "video" | "lecture", code: number, lesson_title: string }[],
    lessonsPure?: any,

    admin_of?: null | number,
    password?: string
}

export type Organization = {
    organization_code: number,
    name: string,
    location: string,
    principal_activity: string,
    secondary_activity: string | undefined,
    website: string | undefined,
    logo: string | undefined,
    contact_person_name?: string,
    contact_person_last_name?: string,
    cellphone?: string,
    email: string,
    position?: string,
    academy_info?: any,
    public_path: string,
    
    admin_of?: null | number
}

/**
 * 
 * 
 * ACADEMY INFO
 * 
 * 
 */

export type OrganizationAcademyInfo = {
    organization_code: number,
    courses: Course[],
    instructors: Instructor[]
}

export type PersonAcademyInfo = {
    user_code: number,
    courses: Course[]
}

/**
 * 
 * COURSES
 * 
 */

export type Course = {
    organization?: Organization,
    organization_code?: number,
    course_code?: number,
    title: string,
    description: string,
    type: "course" | "train",
    cover_image: any,
    category: string,
    target_audience: string,
    instructors: Instructor[],
    modules?: Module[],
    students?: PersonAcademyInfo[],
    index?: number,
    active?: 1 | 0
}
export const CourseInterface = {
    organization: "Organization",
    organization_code: "number",
    course_code: "number",
    title: "string",
    description: "string",
    type: "string",
    cover_image: "any",
    category: "string",
    target_audience: "string",
    instructors: "Instructor[]",
    modules: "Module[]",
    students: "PersonAcademyInfo[]",
    index: "number"
}

/**
 * 
 * MODULES
 * 
 */

export type Module = {
    organization_code: number,
    course_code: number,
    module_code?: number,
    module_nro: number,
    module_title: string,
    module_description: string,
    lesson_videos?: number[],
    lesson_lectures?: number[],
    lesson_quizzes?: number[],
    lessons?: {
        code: number,
        type: "lecture" | "video"
    }[]
    total_time: string, //In Hours
    actual_lesson?: { lesson_type: "video" | "lecture", lesson_code: number },
    active?: 1 | 0
}
export const ModuleInterface = {
    organization_code: "number",
    course_code: "number",
    module_code: "number",
    module_nro: "number",
    module_title: "string",
    module_description: "string",
    lesson_videos: "VideoLesson[] | number[]",
    lesson_lectures: "LectureLesson[] | number[]",
    total_time: "number" //In Hours
}

/**
 * 
 * VIDEO LESSON
 * 
 */

export type VideoLesson = {
    organization_code: number,
    course_code: number,
    module_code: number,
    video_lesson_code: number,
    title: string,
    description: string,
    video_id: string,
    lesson_time: string, // In Hours
    active?: 1 | 0
}

/**
 * 
 *  LECTURE LESSON
 * 
 */

export type LectureLesson = {
    organization_code: number,
    course_code: number,
    module_code: number,
    lecture_lesson_code: number,
    title: string,
    content: any,
    quizz_id: null | number,
    lesson_time: string, // In Hours,
    active?: 1 | 0
}


export type Quizz = {
    quizz_id: number,
    organization_code: number,
    course_code: number,
    module_code: number,
    title: string,
    description: any,
    quizz: any,
    quizz_solved: any,
    lesson_time: string,
    statistics: any,
    active?: 1 | 0
}

export type QuizzUser = {
    corrects: 3,
    date: string,
    incorrects: number,
    quizz_id: number,
    responses: any,
    responses_literal: any,
    title?: string,
    user_code: number,
    user_quizz_controller: any,
    user_type: "person" | "organization"
}

export type Certificate = {
    ucode: number, 
    ccode: number,
    name: string,
    course: string,
    id: number,
    email: string
}

/**
 * 
 * INSTRUCTOR
 * 
 */

export type Instructor = {
    instructor_code?: number,
    organization_code: number,
    name: string,
    last_name: string,
    profession: string,
    image: any,
    description: string
}

export const InstructorInterface = {
    instructor_code: "number",
    organization_code: "number",
    name: "string",
    last_name: "string",
    profession: "string",
    image: "any"
}

/**
 * 
 * RESPONSE SERVER
 * 
 */

export type ResponseCreateAccount = {
    RESULT: boolean,
    USER: Person | Organization,
    TOKEN: string,
    EXCEPTION: number,
    EXCEPTION_MESSAGE: string
}

export type ResponseCreateCourse = {
    RESULT: Boolean,
    MESSAGE: string,
    NEW_COURSE_CODE: number,
    ALL_COURSES: Course[],
    EXCEPTION: number,
    EXCEPTION_MESSAGE: string
}

export type ResponseCreateModule = {
    RESULT: boolean,
    MESSAGE: string,
    NEW_MODULE_CODE: number,
    EXCEPTION: number,
    EXCEPTION_MESSAGE: string
}

export type ResponseLogin = {
    RESULT: boolean,
    USER: Person | Organization,
    TOKEN: string,
    EXCEPTION: number,
    EXCEPTION_MESSAGE: string
}

export type ResponseContact = {
    RESULT: boolean
}

export type ResponseGeneral = {
    RESULT: boolean,
    VALUE: any,
    EXCEPTION: number,
    EXCEPTION_MESSAGE: string,
    NEW_MODULE_CODE?: any
}

/**
 * 
 * AVAILABLE VALUES
 * 
 * 
*/

export type ResponseAvailableCategories = {
    categoryKey: string,
    categoryText: string
}[];

export type AvailableCategories = "tech"
    | "admin"
    | "eco"
    | ""
    | string

