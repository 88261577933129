import "../styles/components/Input.scss";

const RadioButton = (props: {
    innerRef?: any,
    text?: any,
    onChange?: (ev: any) => any,
}) => {
    return <div className="radiobutton-component">
        <input type="checkbox" onChange={props.onChange} ref={props.innerRef}/>
        {props.text}
    </div>
}

export default RadioButton;